.RemoteModalAdminC {
    width: 100% !important;
    justify-content: space-between !important;
    margin: 8px 6px;
}
.remoteMHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 0px 24px;
}
.remotePaper {
    height: 97px !important;
    margin-top: 16px;
    box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12) !important;
}

.DetailRemotePaper {
    color: #242424;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 600;
}
.subDetailRemotePaper {
    color: #000000;
    font-size: 14px;
    line-height: 1.29;
    font-weight: normal;
    margin-top: 6px;
}

.viewRemoteSpan {
    color: #db233a;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.83;
    float: right;
    padding-right: 24px;
    cursor: pointer;
}
.setscrollRemote {
    padding-right: 11px;
    overflow-y: scroll;
    max-height: 300px;
}

.remoteModalContainer .MuiFormControl-root.MuiTextField-root.SearchBar {
    background-color: #fff !important;
}

.remoteModalContainer .MuiFormControl-root.MuiTextField-root {
    background-color: #ffffff !important;
}

.remoteMHeader button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.commonButtonStyle.MuiButton-containedSecondary {
    width: 174px !important;
}
