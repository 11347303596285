.secrotCardAdmin {
  flex-grow: 0;
    /* margin: 10px 0px; */
    margin-bottom: 10px;
    padding: 24px;
    box-shadow: 3px 20px 52px 0 rgb(0 0 0 / 12%);
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.catActionBtn {
  float: right;
  height: 24px;
  flex-grow: 0;
  
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #e31837;
  cursor: pointer;
}

.delBtn {
  color: #a7a9ac;
}

.catCardTitle {
  height: 24px;
  flex-grow: 0;
  
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
}

.serviceIncluded {
  height: 24px;
  flex-grow: 0;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}
.serviceIncluded .noOfServiceCat {
  font-weight: bold;
}

.catActionBtnContainer {
  display: flex;
  justify-content: flex-end;
}
