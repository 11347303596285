.previewTitle {
    margin: 0 !important;
}

.thumbnailDiv {
    width: 100%;
    margin-top: 10px;
}

.thumbnailImagePreviewArticle {
    height: 100%;
    width: 100%;
}

.videoIcon {
    position: absolute;
    width: 90px;
    height: 90px;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}