.loginContainer {
  
  background-image: url(../../../../Assets//Images//login_bg.webp);
  background-size: cover;
  background-attachment: fixed;
}

.loginTitleText {
  height: 32px;
  
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.78;
  letter-spacing: normal;
  color: #000;
  display: inline-block;
  margin-bottom: 24px;
}

.authPageIcon {
  padding: 48px 0px 8px 0px;
}
::-webkit-input-placeholder {
  
  color: #6d6e71;
}
::-moz-placeholder {
  
  color: #6d6e71;
}
:-ms-input-placeholder {
  
  color: #6d6e71;
}
::placeholder {
  
  color: #6d6e71;
}
.addOrOption {
  font-size: 14px;
  line-height: 1.71;
  color: #000;
  text-align: center;
  margin-bottom: 16px;
  padding: 0px;
  margin-top: 16px !important;
}
.captchaDigit {
  position: absolute !important;
  margin-top: 1.2em;
  color: #000;
  opacity: 0.2;
  font-size: 0.9rem;
  margin-left: -3em;
}

.restEmailSend {
  font-size: 12px;
  line-height: 1.67;
  color: #6d6e71;
  margin: 24px 0px 20px 0px;
  padding: 0px;
}
.lock_line_icon {
  margin-top: 10px !important;
  margin-bottom: 16px;
}
.lock_line_iconImage {
  width: 56px;
  height: 56px;
  object-fit: contain;
}

.fullWidth {
  width: 100% !important;
}

.forgot_password_login_page {
  height: 24px;
  font-size: 12px;
  font-weight: 600 !important;
  line-height: 2;
  color: #e31837;
  text-transform: uppercase;
  text-decoration: none;
}
.signInButton {
  
  color: #fff;
  text-transform: uppercase;
}

.forgotPasswordText {
  height: 24px;
  font-size: 16px;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  color: #000;
  margin: 0px;
  text-align: center;
  margin-bottom: 4px;
}

.timerColorMl {
  margin-left: 5px !important;
  color: #f3901d;
}

.resetLinkText {
  height: 20px;
  font-size: 12px;
  line-height: 1.67 !important;
  color: #6d6e71;
  margin: 0px;
  padding: 0px;
  text-align: center;
  margin-bottom: 32px;
}
.resetLinkScreenText {
  font-size: 12px;
  line-height: 1.67 !important;
  color: #6d6e71;
  margin: 0px;
  text-align: center;
}

.resetLinkTextAlign {
  text-align: center;
}

.backToSignInText {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 32px 0px 0px 0px;
}

.getOtpLink {
  height: 24px;
  font-size: 12px;
  font-weight: 600 !important;
  line-height: 2 !important;
  color: #e31837;
  float: right;
  text-decoration: none;
}

.resendOtp {
  height: 20px;
  font-size: 12px;
  line-height: 1.67 !important;
  text-align: center;
  color: #6d6e71;
}
.resendOtp > p {
  padding: 0;
  margin: 0;
}
.alignOtp {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  color: #e31837;
  margin: 16px 0px 52px 0px;
}
.otpTimer {
  color: #f3901d;
}
.backForgot {
  text-decoration: none;
  font-size: 12px;
  font-weight: 600 !important;
  line-height: 2 !important;
  color: #e31837;
  height: 24px;
}
.subText {
  
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.78;
  color: #000;
  text-align: center;
}

.submitButton {
  font-size: 12px;
  font-weight: 600 !important;
  line-height: 2 !important;
  text-align: left;
  color: #fff;
  background-color: #e31837 !important;
  height: 48px;
}
.remCheck > span {
  height: 24px;
  
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  color: #000;
}

.fontFamily {
  
}

.customTextfield .MuiOutlinedInput-root {
  height: 56px !important;
}

.paperStyle {
  width: 416px;
  height: 662px !important;
  box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-top: 64px;
  margin-bottom: 77px;
}
.getOtpIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  transform: rotate(180deg);
}
.signInArrowIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  transform: rotate(9pdeg);
}
.alignOtpFromOtpScreen {
  margin: 18px 0px 26px 0px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
