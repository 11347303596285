.footer {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0)
    ),
    linear-gradient(to left, #333333, #333333);
  padding: 0em 1em;
  color: #ffffff;
  position: relative;
  bottom: 0px;
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 80px;
}

.Qposition {
  margin-left: 43px;
  display: flex;
}

.Qposition .editIcon 
{height: auto;}
.spanDiv {
  margin-left: 36px;
}

.footerFirstText {
  /* width: 110px; */
  height: 20px;
  flex-grow: 0;
  /* margin: 0 278px 0px 0; */
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.footerSecondText {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.footerMessage {
  /* width: 388px; */

  flex-grow: 0;
  margin: 6px 2px 0px 6px;
  
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.termsAndConditions {
  
  height: 20px;
  flex-grow: 0;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.25px;
  color: #fff;
}
.footerLeft {
  display: flex !important;
}

.lastQuoma {
  position: relative;
  top: -1px;
  height: 35px;
  width: 35px;
}

.firstQuoma {
  /* position: relative;
  right: 6px; */
  width: 35px;
  /* top: -6px; */
  height: 30px;
  margin-top: 3px;
}

.flexContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pt15 {
  padding: 5px 0 0 0;
}

.mb {
  margin-bottom: 15px;
  width: 100%;
}

.footerLink {
  text-decoration: none !important;
  color: #fff !important;
}

.Grid1 {
  text-align: start;
}
.Grid2 {
  text-align: end;
  max-width: 41% !important ;
}
.MuiBottomNavigation-root > button > span > .Mui-selected {
  color: #242424 !important;
  font-weight: 600 !important;
}
@media (min-width: 768px) {
  .footer {
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 768px) {
  .Grid2 {
    text-align: center;
    max-width: 100% !important;
  }
  .footerMessage {
    height: auto;
    font-size: 24px !important;
    font-weight: 300;
    line-height: 1.75;
  }
  .termsAndConditions {
    font-size: 9px;
  }
  .footer {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 48px;
    height: auto;
    padding-bottom: 16px;
  }
  .lastQuoma {
    top: 16px;
  }
  .firstQuoma {
    margin-top: 0;
  }
  .footerSecondText {
    margin-bottom: 82px;
  }
}
@media (max-width: 960px) {
  .footer {
    margin-bottom: 56px;
  }
}

/* @media (min-width: 1900px) {
  .footer {
    position: fixed;
    width: 100%;
    bottom: 0px;
  }
} */
