.EthicsCard {
    width: auto !important;
    /* min-width: 298px !important; */
    height: auto;
    
}
.EthicsCardE {
    width: auto !important;
    min-width: 298px !important;
    max-width: 298px !important;
    height: auto;
    
}

.EthicsImg {
    margin-top: 24px;
}
.EthicsImgSize {
    height: 24px;
    width: 24px;
}
.EthicsTitle {
    font-size: 20px;
    font-weight: 600;
    margin-top: 16px;
}
.EthicsData,
.noTitile,
.EmailTitile {
    font-size: 14px;
    line-height: 1.57;
    margin-top: 8px;
}

.noDataEthics,
.EmailData {
    font-size: 12px;
    font-weight: 600;
    margin-top: 4px;
}
.noDataEthics {
    display: flex;
    justify-content: start;
    align-items: center;
    height: auto;
    
    color: #e31837;
}

/* .ImgP {
    padding-left: 16px;
} */

.cardEthicsFooter {
    font-size: 12px;
    font-weight: 600;
    margin-top: 16px;
    color: #db233a;
    
}
.cardEthicsFooterf {
    font-size: 12px;
    font-weight: 600;
    margin-top: 16px;
    color: #db233a;
    
    text-transform: uppercase;
    text-decoration: none;
}
.EthicsImgHeader,
.EthicsCardHeader {
    height: 200px;
    width: 100%;
}
.EthicsSubTitlte {
    font-size: 24px;
    font-weight: 500;
    
}

.EthicsColor {
    color: #e31837 !important;
}
.summaryDetail1Ethics {
    padding-left: 16px;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.44;
}
.AccordianMessageEthics {
    background-color: #fff;
    margin-bottom: 8px;
}
.EthicsCardHeaderPadding {
    padding-left: 16px;
    padding-right: 8px;
}
.fFSaira {
    
}
.iframeInfra {
    border: 0px !important;
    height: 100% !important;
    width: 100% !important;
}
.headerDiv {
    margin-top: 16px;
    margin-bottom: 40px;
}
.FooterCardDiv {
    margin-bottom: 40px !important;
}
.EthicsHeaderTitle {
    font-size: 32px;
    font-weight: 500;
    line-height: 1.5;
}
.EthicsHeaderData {
    font-size: 14px;
    color: #242424;
    line-height: 1.71;
    margin-top: 8px;
}
.cardEthicsHeaderFooter {
    color: #e31837;
    font-size: 12px;
    font-weight: 600;
    line-height: 2;
    margin-top: 16px;
    cursor: pointer;
}
.EthicsCardHeaderDetail {
    margin-left: 24px;
    margin-top: 24px;
}

.ViewPolicies {
    color: #e31837;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    text-align: end;
    
}

.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -24px; /* gutter size offset */
    width: auto;
}
.my-masonry-grid_column {
    padding-left: 24px; /* gutter size */
    background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
    /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 24px;
}

.parent {
    position: relative;
    top: 0;
    left: 0;
}

.Img1 {
    position: relative;
    top: 0;
    left: 0;
}

.Img2 {
    position: absolute;
    top: 12px;
    left: 12px;
}

.InfraFlex {
    display: flex;
    align-items: flex-start;
    margin-right: 16px;
}
.InfraBuildingData1 {
    font-weight: 600;
    font-size: 14px;
    
    line-height: 1.57;
    margin-top: 4px;
}
.InfraBuildingData1 {
    font-size: 14px;
    
    line-height: 1.71;
    color: #242424;
}
.infraImg {
    margin-right: 16px;
    padding-top: 3px;
}
.mbLabel {
    margin-bottom: 4px !important;
}

.mbEthics {
    margin-bottom: 8px;
}
/* .EthicsCardHeaderDetailinfra {
    margin-left: 24px;
    margin-top: 14px;
} */
.EthicsDataAddress {
    flex-grow: 0;
    
    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #242424;
    margin-top: 12px;
}

.EthicsDataContact {
    flex-grow: 0;
    
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}
.alignTopHeaderIS {
    margin-top: 2em;
}

@media (max-width: 600px) {
    .EthicsCardHeaderDetailinfra {
        margin-left: 0px;
        margin-top: 0px;
    }
}

@media (max-width: 960px) {
    .alignLeftInMobile {
        float: none !important;
    }
}
