.offerCampaign {
  padding: 6em 0 1em 0;
}

.addScroll {
  height: 50vh;
  overflow: auto;
}
.landingpageAlign {
  padding: 0 0.5em 0 0.5em;
  box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12) !important;
  background-color: #fff !important;
}

.mt16 {
  margin-top: 0px;
}
.paddingLR0 {
  padding: 24px 0 !important;
}
.OFalignCardsItems {
  padding: 16px 8px 8px 8px !important;
  border: solid 1px #ededed;
  min-height: 37em;
  max-height: auto;
}
.MuiBreadcrumbs-separator {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.submitbutton {
  width: 142px;
  height: 48px;
  padding: 13px 48px;
  background-color: #e31837 !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  line-height: 1.83;
  text-align: right;
  color: #fff;
}
.OCalignUploadstory {
  display: flex;
  float: right;
  margin: 0;
  box-shadow: none !important;
  padding: 16px 8px;
}

.alignBtnRight {
  float: right;
  padding: 1em 0 0.5em 0;
}
.OFtabContent {
  padding: 8px 0 8px 12px;
  text-align: left;
  background-color: #f7f6f6;
  
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
}
.offCHeaderTitle {
  
  font-size: 32px;
  font-weight: 500;
  line-height: 1.25;
  text-align: left;
  color: #000;
  margin: 0;
}
.offCAlignM {
  margin-bottom: 40px;
}
.SearchBar > .MuiInputBase-formControl {
  height: 50px !important;
}
/* .PrivateTabIndicator-colorPrimary-14 {
    background-color: #e31837  !important;
} */
.mobileheadTab {
  background-color: transparent !important;
  color: #000000 !important;
  box-shadow: none !important;
}
.bgImage {
  background-image: url(../../../Assets/Images/sectionBackground.png);
}
.OFactiveTab {
  padding: 0 12px 0 12px;
  height: 38px;
  text-align: left;
  background-color: #e31837;
  box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12);
  
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  color: #fff;
  display: flex;
  align-items: center;
}
.m-1 {
  margin-top: 0.5em;
}
.OFtabTitle {
  margin: 0 !important;
}
.hoverEffect:hover,
.hoverEffect:active {
  background-color: #e31837;
  color: #ffffff;
}
@media screen and (max-width: 812px) {
  .commanButton {
    margin-bottom: 1em;
  }
}

.submitButtonOfferCampaign {
  width: 142px;
  display: flex;
  gap: 10px;
  padding: 13px 48px;
  background-color: #db233a !important;
  box-shadow: none !important;
  font-size: 12px !important;
  font-weight: 600 !important;
}
.MuiCardActions-root {
  padding: 0 16px 0 16px !important;
}
.offer_and_campaigns_tab {
  width: 224px;
  height: 38px !important;
  flex-grow: 0;
  margin: 0 0 8px;
  padding: 8px 69px 8px 12px;
  box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12);
  background-color: #db233a !important;
}
.mobileheadTab > div > div > div > button {
  margin: 0 !important;
}
.width100 {
  width: 100%;
}
@media (max-width: 960px) {
  .offCHeaderTitle {
    font-size: 24px !important;
  }
  .OCalignUploadstory {
    display: none;
  }
  /* .width100 {
    padding: 8px 0 !important;
  } */
  .offCAlignM {
    margin-bottom: 0;
  }
  .landingpageAlign {
    background-color: transparent !important;
    box-shadow: 3px 20px 52px 0 rgb(0 0 0 / 0%) !important;
  }
}

/* css by ps */

.descriptiontext {
  margin-bottom: 0px;
  margin-top: 10px;
  min-height: 50px;
  overflow: hidden;
  max-height: 70px;
}
