.feedback {
    position: fixed !important;
    right: -38px;
    text-align: center;
    top: 40vh;
    transform: rotate(-90deg);
    z-index: 999;
    color: #fff !important;
    background-color: #db233a !important;
    border-radius: 0px !important;
    width: 118px;
    height: 40px;
    padding: 5px 3px !important;
}

.feedbackAfterClick {
    position: fixed !important;
    right: -80px;
    text-align: center;
    top: 40vh;
    transform: rotate(-90deg);
    z-index: 999;
    color: #fff !important;
    background-color: #db233a !important;
}

.cardMain {
    position: absolute;
    min-width: 423px !important;
    min-height: 314px !important;
    right: -13em !important;
    top: -30.9em;
    padding-left: 16px;
    transform: rotate(90deg) !important;
    cursor: default;
}
.fontSaira {
    font-family: 'Georama-Regular';
}
.textCapatalize {
    text-transform: capitalize !important;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
}
.inlineFeedback {
    display: flex;
    
    font-size: 16px;
    width: 172px;
    height: 40px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 1px;
    color: #fff;
    justify-content: center;
    align-items: center;
}

.imgStar {
    transform: rotate(-130deg) !important;
    margin-top: 1px ;
}

.closeIcon {
    position: absolute;
    right: 12px;
    cursor: pointer;
    font-size: 22px !important;
    color: #000;
}

.MuiDrawer-paperAnchorBottom {
    border-radius: 50px 50px 0px 0px !important;
}

.feedback-Tasks {
    width: 320px;
    text-align: left;
    color: #000000;
    font-weight: 700 !important;
    margin-top: 1.3em !important;
    text-transform: none;
    
}
.logo {
    cursor: pointer;
}
.emojiContainer {
    display: flex;
    justify-content: space-between;
    width: 66%;
    /* margin-bottom: 0.5em; */
    margin-top: 40px !important;
    text-transform: capitalize;
    font-size: 14px;
}

.emojiTitle {
    display: flex;
    justify-content: space-between;
    width: 61%;
    position: absolute;
    left: 3.1em;
    text-transform: capitalize;
}
.ExcellentDiv {
    margin-left: 3px;
}
.neutralText {
    margin-left: 0px;
}

.badtext
{margin-left: 0px;}

.emojiTitleDiv {
    color: #242424;
    line-height: 1.57;
    font-size: 14px;
    font-weight: lighter;
    
    backface-visibility: hidden !important;
    padding-top: 10px;
}

.drawerFeedback {
    height: 280px;
    width: 304px;
    padding: 24px 24px 24px 32px;
}

.shareForm {
    width: 325px !important;
    height: 130px !important;
    position: absolute !important;
    left: 33px;
    padding: 16px;
    margin-top: 16px;
    border: solid 1px #ededed !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    background-color: #fff !important;
    resize: none !important;
}
.shareForm:focus-visible {
    outline: none !important;
}
.shareFormBad:focus-visible {
    outline: none !important;
}
.shareFormBad {
    width: 325px !important;
    height: 100px !important;
    position: absolute !important;
    left: 33px;
    padding: 16px;
    margin-top: 16px;
    border: solid 1px #ededed !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
    background-color: #fff !important;
    resize: none !important;
}

.fieldEmojiBox {
    display: block;
}

.emojiLogo {
    display: flex;
    position: absolute;
    top: 243px;
    height: 14%;
    backface-visibility: hidden !important;
}
.submitFix {
    backface-visibility: hidden !important;
    font-size: 12px;
}

.dismissFeedback {
    font-size: 12px;
    color: #fff;
}

.emojiSpan {
    text-transform: capitalize !important;
    position: absolute;
    top: 3px;
    left: 30px;
    
    line-height: 1.57;
    font-size: 14px;
    color: #242424;
    backface-visibility: hidden !important;
}
.logoSize {
    width: 24px;
    height: 24px;
}
.indicator1 {
    position: absolute;
    top: 286px;
    width: 60px;
    height: 4px;
}

.indicator1Color {
    background-color: #e31837;
}
.indicator2Color {
    background-color: #ededed;
}

.indicator2 {
    position: absolute;
    top: 286px;
    width: 60px;
    height: 4px;
    left: 97px;
}

.feedbackButton {
    background-color: #e31837 !important;
    color: #fff !important;
    border-radius: 0px !important;
    width: 6em;
    height: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
}
.feedbackSubmit {
    position: absolute;
    left: 264px;
    top: 252px;
    cursor: pointer !important;
    width: 94px;
    height: 40px;
}
.feedbackDismiss {
    position: absolute;
    left: 46px;
    width: 97px;
    height: 47px;
    cursor: pointer;
}
.You {
    text-transform: lowercase !important;
}
.thanksDiv {
    position: absolute;
    left: 117px;
    top: 94px;
    text-transform: capitalize;
}
.gotItText {
    
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    color: #000;
    backface-visibility: hidden !important;
}
.shareForm .MuiOutlinedInput-root {
    border-radius: 0px !important;
}
.shareForm .MuiInputBase-formControl {
    width: 100% !important;
    height: 55% !important;
}

.thankYou {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: normal;
    color: #000;
    margin-block-start: 0px;
    margin-block-end: 0px;
    text-align: left !important;
    margin-left: 43px;
}
.thanksContained {
    color: #242424;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    margin-block-start: 8px;
    margin-block-end: 38px;
    line-height: 1.57;
}

.emojiContainerRating {
    display: flex;
    justify-content: space-between;
    width: 73%;
    margin-bottom: 0.5em;
    margin-top: 1.7em;
}



.MuiRating-label {
    font-size: 32px;
    width: 50px;
}

@media (max-width: 920px) {
    .feedback {
        top: 300px;
    }
}

@media (max-width: 600px) {
    .feedback {
        right: -59px;
        top: 45vh;
        width: 40px !important;
        height: 40px;
        transform: rotate(0deg);
        padding-left: 4px !important;
        padding-top: 10px !important;
    }
    .emojiTitle {
        left: 2.8em;
        width: 65%;
    }
    .feedbackDismiss {
        left: 2.6em;
    }
    .indicator1 {
        top: 265px;
    }
    .indicator2 {
        top: 265px;
    }
    /* span.MuiButton-label {
        width: 90% !important;
    } */

    .feedbackSubmit {
        left: 200px;
        top: 230px;
    }
    .thanksDiv {
        left: 22%;
        text-align: center;
    }
    .feedback {
        right: -12px !important;
    }
    .gotItText {
        font-size: 1.125rem;
    }
    .feedback-Tasks {
        width: 300px;
    }
    .emojiLogoBad {
        position: absolute !important;
        top: 225px !important;
    }
    .feedbackBadSubmit {
        left: 222px;
        top: 230px;
        position: absolute;
    }
    .closeIcon {
        margin-right: 9px !important;
    }
    .emojiContainer {
        width: 104%;
    }
    .feedbackAfterClick {
        right: -100px !important;
    }
    .shareForm {
        width: 262px !important;
        top: 86px;
        height: 120px !important;
    }
    .shareFormBad {
        width: 274px !important;
        left: 32px;
        top: 114px;
        height: 90px !important;
        padding-left: 4px;
    }
    .emojiLogo {
        top: 16.4em;
    }
}

@media only screen and (min-width: 361px) and (max-width: 414px) {
    .feedbackBadSubmit {
        position: absolute;
        left: 17.4em;
        top: 16.6em;
    }
    .feedbackSubmit {
        left: 16.4em;
        top: 16.6em;
    }
    .thanksDiv {
        left: 25%;
    }
    .shareFormBad {
        width: 295px !important;
    }
    .shareForm {
        width: 290px !important;
    }
}
@media (max-width: 376px) {
    .emojiTitle {
        width: 71%;
    }
    .shareFormBad {
        width: 277px !important;
    }
    .shareForm {
        width: 266px !important;
    }
}

@media (max-width: 360px) {
    .emojiTitle {
        width: 74%;
    }
    .shareFormBad {
        width: 274px !important;
    }
    .shareForm {
        width: 262px !important;
    }
}

@media (max-width: 320px) {
    .emojiContainer {
        width: 88%;
    }
    .emojiTitle {
        width: 68%;
        left: 2em;
    }
    .feedbackBadSubmit {
        left: 11em;
    top: 14.5em;
        position: absolute;
    }
    .shareForm {
        width: 237px !important;
        top: 87px;
        height: 120px !important;
        left: 21px;
    }
    .shareFormBad {
        width: 77% !important;
        left: 1.7em;
    }
    .feedbackSubmit {
        left: 10.4em;
        top: 14.5em;
    }
    .emojiContainer {
        width: 88%;
    }
    .thanksDiv {
        left: 20%;
    }
    .drawerFeedback {
        height: 319px;
        width: 280px;
        padding: 20px;
    }
}
@media only screen and (min-width: 376px) and (max-width: 393px) {
    .emojiContainer {
        width: 99%;
    }
}
@media only screen and (min-width: 361px) and (max-width: 410px) {
    .feedbackBadSubmit {
        position: absolute;
        left: 17.3em;
        top: 16.6em;
    }
    .feedbackSubmit {
        left: 16.33em;
        top: 16.6em;
    }
}
@media only screen and (min-width: 361px) and (max-width: 375px) {
    .feedbackBadSubmit {
        position: absolute;
        left: 16.2em;
        top: 16.6em;
    }
    .feedbackSubmit {
        left: 14.7em;
        top: 16.6em;
    }
}

.animationFeedbacktext {
    animation: feedback 0.8s ease-out;
}
.animationFeedbacktextdown {
    animation: feedback2imgdown 1s ease-out;
    backface-visibility: hidden !important;
}

.animationFeedbacktextindicator {
    animation-iteration-count: 1 !important;
    animation: feedback 0.8s ease-out;
    backface-visibility: hidden !important;
}
.animationFeedbacktextindicator2 {
    animation-iteration-count: 0 !important;
    animation: feedback 0.8s ease-out;
    backface-visibility: hidden !important;
}

.animationFeedbackimg1 {
    animation-name: feedbackimg1;
    animation-duration: 1.3s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
}
.animationFeedbackimg2 {
    animation-name: feedbackimg2;
    animation-duration: 1.3s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
}
.animationFeedbackimg3 {
    animation-name: feedbackimg3;
    animation-duration: 1.3s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
}
.animationFeedbackimgTextexcellent3 {
    animation-name: excellentText;
    animation-duration: 1.3s;
    animation-timing-function: ease-out;
    backface-visibility: hidden !important;
}

@keyframes excellentText {
    0% {
        transform: translateY(140px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes feedbackimg3 {
    0% {
        transform: translateY(100px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes feedbackimg2 {
    0% {
        transform: translateY(78px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes feedbackimg1 {
    0% {
        transform: translateY(50px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes feedback {
    0% {
        transform: translateY(10px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes feedback2imgdown {
    0% {
        transform: translateY(-120px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}
