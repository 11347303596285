.modalBody > div > div > div > div > div > .MuiInputLabel-shrink {
  transform: translate(10px, -4.5px) scale(0.75) !important;
  transform-origin: top !important;
  display: none !important;
}
.alignUSearch {
  position: relative;
}
.universalSerchCloseIcon {
  position: absolute;
  bottom: 0;
  right: 18px;
  top: 14px;
  cursor: pointer;
}

.universalSearchMultiselect > div {
  border: solid 1px #e4e4e4 !important;
  background-color: #fbfbfb !important;
}
.MuiAutocomplete-popupIndicatorOpen {
  color: #e31837 !important;
}
.universalSearchMultiselect > div > div > label {
  
  font-size: 16px !important;
  color: #6d6e71 !important;
}
.alignUSearch > div > div > div > div > img {
  cursor: pointer !important;
}
.universalSearchMultiselect > div > div > div {
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 46px;
}
/* .MuiInputLabel-outlined.MuiInputLabel-marginDense {
  transform: translate(14px, 16px) scale(1) !important;
} */
/* @media (max-width: 960px) {
  .MuiGrid-spacing-xs-5 > .MuiGrid-item {
    padding: 20px !important;
  }
} */

/* css by ps */

.universalSearchMultiselect .MuiAutocomplete-tagSizeSmall {
  background: none;
  margin-top: 5px;
}

@media (max-width: 960px) {
.universalSearchMultiselectParent 
{
  margin-top: 10px !important;
}
}

#react-autowhatever-1 {
  background: #fff;
  width: 100%;
}

input.react-autosuggest__input {
  background: #fff;
  width: 100%;
  height: 50px;
  padding-left: 55px;
  border-left: 0px;
    border-right: 0px;
} 

input.react-autosuggest__input:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

#react-autowhatever-1 ul 
{padding: 0px;}

#react-autowhatever-1 ul li {
  list-style: none;
  border-bottom: #dadada 1px solid;
  padding: 5px 15px;
  cursor: pointer;
}

#react-autowhatever-1 .react-autosuggest__suggestion--first
{color: #e31837;}

.alignUSearch .MuiInputAdornment-root.MuiInputAdornment-positionStart
{width: 50px;
  height: 50px;
  position: absolute;
  top: 10px;
  left: 15px;}


