.cardShadow {
  box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.1) !important;
}

.commonCardStyle {
  height: 404px;
}

.commonCardImage {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 240px;
  min-height: 240px;
  background-position: center;
}

.imgCardContaier {
  width: 100%;
}

.playImgContainer {
  width: 100%;
  position: absolute;
  height: 240px;
}

.gradient {
  background-image: linear-gradient(
    to left,
    rgba(10, 9, 9, 0.12),
    rgba(22, 22, 22, 0.54)
  );
}

.cardTitle {
  
  font-size: 18px;
  font-weight: 600;
  height: 28px;
  padding: 0 2px 8px 0px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.cardDescriptionTxt {
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.cardAction {
  padding: 1em;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}
.actionContent {
  font-size: 0.8em;
  
  color: #6d6e71;
}
.viewContent {
  font-size: 0.8em;
  
  color: #e31837;
  font-weight: 600;
  text-transform: uppercase;
}
.status {
  font-size: 0.8rem;
  
  font-weight: 500;
  line-height: 1.67;
  color: #000;
}
.statusApproved {
  color: #7ac143 !important;
}
.statusRejected {
  color: #e31837 !important;
}

.cardActionContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
}

.avatarContainer {
  display: flex;
  align-items: center;
}

.avatarDetails {
  padding: 0 8px;
}

.avatarName {
  
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  /* text-align: right; */
  color: #242424;
}

.dateContainer {
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #6d6e71;
}

.cardContentStyle {
  padding: 8px 16px !important;
}

.commonCardStyle .MuiCardActions-root {
  padding: 0 16px 16px 16px !important;
}
