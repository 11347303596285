.priority_data_card {
  padding: 1em 2em 1em;
  box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12) !important;
  height: 114px;
}
.priority_data_card:nth-child(2) {
  box-shadow: none !important;
  background-image: linear-gradient(
      to bottom,
      rgba(36, 36, 36, 0.08),
      rgba(250, 247, 246, 0)
    ),
    linear-gradient(to top, #faf7f6, #faf7f6) !important;
}
.cardsContainer {
  box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 1em;
}
.priority_data_card_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.outerCard {
  height: 25.9em;
  background-color: #f7f6f6 !important;
}
.priorityTasks {
  width: 4em;
  display: inline-block;
  line-height: 1.5;
  color: #242424;
  
  font-size: 16px;
  font-weight: 300;
}
.daysToGo {
  
  font-size: 40px;
  line-height: 1;
  text-align: left;
  color: #e31837;
  margin: 0;
}
.taskName {
  
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 1px;
  text-align: left;
  color: #242424;
  margin: 0 0 32px 0;
}
.alignTaskCard0 {
  box-shadow: none !important;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.alignTaskCard1 {
  box-shadow: none !important;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}
.alignTaskCard0:hover {
  /* width: 100%; */
  background-color: #e31837 !important;
  transition: 0.7s ease-out !important;
}
.alignTaskCard0:hover p {
  color: #fff !important;
}
.alignTaskCard0:hover:after {
  position: absolute;
  content: "";
  display: inline-block;
  top: -250px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: rgba(36, 36, 36, 0.3);
  animation-duration: 3s;
  animation-timing-function: ease-in;
  animation-delay: -2s !important;
  animation-name: shiny-btn1;
}
.alignTaskCard1:hover {
  /* width: 100%; */
  transition: 0.7s ease-out !important;
  background: #e31837 !important;
}
.alignTaskCard1:hover p {
  color: #fff !important;
}
.alignTaskCard1:hover:after {
  position: absolute;
  content: "";
  display: inline-block;
  top: -250px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: rgba(36, 36, 36, 0.3);
  animation-duration: 3s;
  animation-timing-function: ease-in;
  animation-delay: -2s !important;
  animation-name: shiny-btn1;
}

@keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(35deg);
    opacity: 0;
  }
  70% {
    -webkit-transform: scale(0) rotate(35deg);
    opacity: 0.5;
  }
  71% {
    -webkit-transform: scale(4) rotate(35deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(35deg);
    opacity: 0;
  }
}
.bgColor {
  background-image: linear-gradient(
      to bottom,
      rgba(36, 36, 36, 0.08),
      rgba(250, 247, 246, 0)
    ),
    linear-gradient(to top, #faf7f6, #faf7f6) !important;
}
.shadowColor {
  box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12) !important;
}

.todayStatus {
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e31837;
  text-transform: initial;
  margin: 0 0 26px 0 !important;
}
.notaskContainer {
  height: 346px;
  background-color: #f7f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* box-shadow: none; */
}
.noPriorityTask_head_text {
  color: #242424;
  
  font-size: 1.2rem;
  font-weight: 500;
}
.noPriorityTask_sub_Text {
  color: #6d6e71;
  
  font-size: 0.9rem;
  font-weight: 500;
}
.noTaskCard {
  box-shadow: none !important;
}
.priorityDataCradContainerBg {
  background-color: #f7f6f6 !important;
}
.dynamicHeight {
  height: 10.5em;
}
.stay_focused_Card_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 1.6em; */
}
.filterContentModel {
  display: flex;
  align-items: center;
}
.alignModalAlign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
}
.daysToGoFromModal {
  
  font-size: 48px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #e31837;
  display: inline-block;
}
.tasksFromModal {
  width: 81.3px;
  opacity: 0.64;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #e31837;
  margin-left: 11px;
  display: inline-block;
}
.priorityStatus {
  margin: 0;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #e31837;
  min-width: 90px;
}

@media screen and (max-width: 768px) {
  .alignCommonModalTitle {
    margin-left: 24px;
  }
  .tasksFromModal {
    width: 100px;
  }
}

@media screen and (max-width: 812px) {
  .taskNameMobile {
    font-size: 0.8rem;
  }
  .daysToGoMobile {
    font-size: 1.6rem;
  }
  .statusMobile {
    font-size: 0.7rem;
  }
  .daysToGoFromModal {
    margin: 0 8px 0 0;
    
    font-size: 29px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.91px;
    color: #e31837;
    /* color: var(--gradient-red-gradient); */
  }
  .tasksFromModal {
    margin: 9px 0 8.9px 8px;
    opacity: 0.88;
    
    font-size: 12.7px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.91px;
    color: #e31837;
  }
}
/* @media (max-width: 1024) {
  .taskName {
    margin: 0 !important;
    height: 54px !important;
  }
} */
