.tableRow {
  background-color: #faf9f9;
}
.tableCellNameHead {
  font-size: 16px !important;
  font-weight: 600 !important;
  line-height: 1.5 !important;
  color: #000 !important;
}
.tableCellName {
  
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #242424;
}

/* .tableMainContainer {
  margin: 32px 24px 23px 24px;
} */

.tableRow_body {
  background-color: #fff;
}

.tableCellTitle {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.53;
  color: #000;
  
}
.tableCellNote {
  margin: 0px !important;
  padding: 0px !important;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 1.83;
  font-size: 12px;
  color: #6d6e71;
  
}
.MuiSwitch-colorSecondary.Mui-checked {
  color: #e31837 !important;
}
.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #e31837 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #e31837 !important;
}
.MuiSwitch-switchBase {
  color: #ffffff !important;
}
/* .notificationsModalText { */
/* 
  margin: 0 !important;
  line-height: 1.33;
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  color: #000;
  padding: 0.3em 0 1em 0; */
/*  */
/* font-size: 18px !important; */
/* font-weight: 600 !important; */
/* font-stretch: normal; */
/* font-style: normal; */
/* line-height: 1.33 !important; */
/* letter-spacing: normal; */
/* color: #000; */
/* } */
.tableSpace {
  background: #faf9f9;
  border-collapse: separate !important;
  border-spacing: 0 24px !important;
}
.tableRow_body {
  height: 80px !important;
}
.textCenter {
  text-align: center !important;
}
table > thead > tr > th {
  padding: 0em 1em 0em 1em !important;
}
table > tbody > tr:hover {
  box-shadow: 0 4px 31px 0 rgba(0, 0, 0, 0.1) !important;
}
