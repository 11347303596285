.wallpaper .statusApproved {
    color: #7ac143 !important;
    border: 1px solid #7ac143 !important;
    background-color: rgba(122, 193, 67, 0.1) !important;
    padding: 6px 12px !important;
    
    font-size: 12px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    border-radius: 0px !important;
    width: 85px !important;
}
.wallpaper .statusPending {
    color: #ffcf01 !important;
    border: 1px solid #ffcf01 !important;
    background-color: rgba(255, 207, 1, 0.1) !important;
    padding: 6px 12px !important;
    
    font-size: 12px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    border-radius: 0px !important;
    width: 85px !important;
}
.wallpaper .statusReject {
    color: #ff0000 !important;
    border: 1px solid #ff0000 !important;
    background-color: rgba(255, 0, 0, 0.1) !important;
    padding: 6px 12px !important;
    
    font-size: 12px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    border-radius: 0px !important;
    width: 85px !important;
}

.wallpaperStatus {
    height: 900px !important;
    overflow-y: auto;
}

.pendingScroll {
    height: 650px !important;
    overflow-y: auto;
}

.approvalImage .modalBody {
    padding-top: 0px !important;
    max-height: none !important;
}

img:hover {
    cursor: pointer;
}
.path{
    
    font-size: 10px;
    font-weight: 500;
    line-height: 1.8;
    text-transform: uppercase;
    color: #a7a9ac;
}
.heading{
    
    font-size: 24px;
    font-weight: 500;
    line-height: 1.33;
    color: black;
}
.heading1{
    font-size: 16px;
    
    font-weight: 500;
    line-height: 1.5;
    color: black;
    margin-top: 24px;
}
.heading2 {
    
    font-size: 14px;
    font-weight: 600;
    /* color: black; */
    line-height: 1.57;
}
.content{
    
    font-size: 14px;
    font-weight: 400;
    line-height: 1.67;
    color: #242424;
}
.content2{
        
        font-size: 12px;
        font-weight: 500;
        /* color: black; */
        line-height: 1.67;
}
.border1{
    border: solid 1px #ededed;
}
.border2{
    border: solid 1px #ededed;
}
.border3{
    border: solid 1px #dcdcdc;
    width: inherit;
}
.tabledata{
    
    font-size: 12px;
    line-height: 1.67;
    padding-top: 16px;
    padding-bottom: 16px;
}
.pad-desk24-mob0{
    padding: 24px;
}
.pad-desk12-mob0{
    padding: 12px;
}
.lmar-desk24-mob0{
    margin-left: 24px;
}
.line{
    border: none;
}
.wallpaperDropdown .MuiFormControl-root.MuiTextField-root {
    background-color: white !important;
}
.wallpaperCheckbox .MuiSvgIcon-root ,
.wallpaperDropdown .css-qzbt6i-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-popupIndicator ,
.wallpaperDropdown .MuiIconButton-root {
    color: #e31837 !important;
}
.wallpaperTab .MuiTab-root {
    padding: 0px !important;
    margin-left: 0px !important;
    margin-bottom: 12px !important;
    max-width: none !important;
    text-align: left !important;
    background-color: white !important;
    /* color: black !important; */
}
.wallpaperTab .Mui-selected {
    background-color: #e31837 !important;
    color: white !important;
}
.wallpaperTab .MuiPaper-root {
    color: rgb(0, 0, 0) !important;
}
.wallpaperTab .MuiTab-textColorInherit {
    opacity: 1 !important;
}

/* wallpaper button */
.wallPaperBtnApproval{
    width: 205px !important;
}

@media screen and (max-width: 700px) {
    .path{
        display:none;
    }
    .pad-desk24-mob0{
        padding: 0px;
    }
    .pad-desk12-mob0{
        padding: 0px;
    }
    .border1{
        border: none;
    }
    .border3{
        border: none;
    }
    .lmar-desk24-mob0{
        margin-left: 0px;
    }
    .line{
        border: solid 1px #ededed;
        margin-left: -24px;
        margin-right: -24px;
        margin-top: 24px;
    }
    .postwallpaperBtn{
        width: 248px !important;
    }
}


@media screen and (max-width: 400px) {
    .wallPaperBtnContainerApproval{
        flex-direction: column !important;
    }
}