.policiesCreateModal {
  padding: 0 24px;
}
.flexColumnSpacePolicies {
  column-gap: 26px;
}
.policiesTabModal > div {
  padding: 29px 0 0px 0;
}
.tableContentHeight {
  height: 188px;
}
.policiesModalTable {
  margin-top: 18px !important;
  padding: 0 24px;
  box-shadow: none !important;
  padding-bottom: 23px !important;
}

.policiesTableSrNo {
  flex: 0.1;
}
.mt0 {
  margin-top: 0 !important;
}
.policiesTableName {
  flex: 0.2;
}
.policiesTableDescription {
  flex: 0.6;
}
.policiesTableLastModify {
  flex: 0.2;
}
.policiesTableDate {
  flex: 0.2;
}
.policiesTableDelete {
  flex: 0.05;
  padding-right: 5px !important;
}
.policiesTableEdit {
  flex: 0.05;
}
.policyCheckbox {
  padding-left: 16px;
}
.policiesModalTableContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  background: #fff;
  
  font-size: 12px;
  font-weight: normal;
  border: 1px solid #ededed;
  border-top: none !important;

  color: #000000;
  column-gap: 10px;
}
.setColumnGapPolicies {
  column-gap: 10px;
  border: 1px solid #ededed !important;
}
.categoryLabelGray > div > div > .MuiInputLabel-outlined.MuiInputLabel-shrink {
  background-color: #f7f6f6 !important;
}
