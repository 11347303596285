.alignTabContent > div > div > .MuiTab-root {
  margin: 0px 0px 0px 0px !important;
  padding: 13px 24px 13px 24px !important;
  min-height: 0 !important;
}
.alignTabContent > div > .MuiTabs-indicator {
  max-width: 263px !important;
}
.alignTabContent > div > div > .MuiTab-root > .MuiTab-wrapper {
  font-size: 14px !important;
}
.policiesTabAlign {
  margin-top: 40px;
}
.policiesTab {
  padding: 24px 0 32px 0 !important;
}
.policiesTab > div {
  padding: 0 !important;
}
.deviderP {
  margin: 0;
  border-color: #ededed;
  position: relative;
  bottom: 1px;
  opacity: 0.2;
}
.editPolicies {
  
  font-size: 12px;
  font-weight: 600;
  color: #e31837;
  text-transform: uppercase;
}
.editPoliciesStructure {
  display: flex;
  align-items: center;
  column-gap: 7px;
  cursor: pointer;
}
.addRedBorder > div {
  border: 2px solid #db233a;
  box-shadow: 0 20px 42px -20px rgba(0, 0, 0, 0.12);
}
.policyheadm
{display:none}
@media (max-width: 960px) {
  /* .policiesdatascroll {
    
    overflow-x: scroll;
    background: #fff;
    
  }

  .Responsivescroll {
    
    min-width:800px;
    
  } */
.policyheadm{
  
  font-size: 12px;
  font-weight: 600;
  display:block;
}
.tableheadmhide {
  display: none !important;
}
  .editPoliciesStructure {
    display: none;
  }
  .policiesTableContent {
    flex-direction: row;
    display: block !important;
    padding: 10px;
    height: auto !important;
}

.mpadd10 {
  padding: 10px !important;
  height:auto !important;
}
.policydate
{padding: 10px;}
}
