.profileContainer {
  display: flex;
  padding-left: 32px;
  align-items: center;
  background-image: linear-gradient(
      to top,
      rgba(36, 36, 36, 0.08),
      rgba(250, 247, 246, 0)
    ),
    linear-gradient(to bottom, #faf7f6, #faf7f6);
  
  height: 112px;
}
.imageContainer {
  height: 48px;
  width: 48px;
  border: 1px solid #000000;
}
.logoutSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  padding: 18px 24px 18px 24px;
  box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.1);
}
.logoutSectionAlign {
  position: absolute;
  bottom: 48px;
  width: 100%;
}
.alignResNavSubTitleText {
  padding: 0 16 18px 16px !important;
}
/* .afterDeviderTitle {
  padding: 0 16px !important;
} */
.listItemStyle > span {
  align-self: stretch;
  flex-grow: 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  
}
.alignRight {
  text-align: right;
  
}
.alignRight > span {
  
}
.flexCenter > span {
  display: flex;
  align-items: center;
  column-gap: 0.5em;
  
}
.flexCenter {
  display: flex;
  align-items: center;
  column-gap: 8px;
  
}
.imageStyle {
  height: 100%;
  width: 100%;
}
.profileText {
  margin-left: 12px;
  
}
.loginName {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin: 0;
  
  text-transform: capitalize !important;
}
.alignResNavTitleText {
  margin-top: 16px !important;
}
.loginDesignamtionTxt {
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #6d6e71;
  margin: 0;
  
}
.MuiList-padding > div > li:hover {
  background-color: #e31837;
  color: #fff;
}
.MuiList-padding > div > li:hover a {
  color: #fff;
}
.MuiList-padding > div > li:hover .listItemStyle > span {
  color: #fff;
}
.MuiList-padding > div > li:hover .hoverColorWhite {
  color: #fff;
}
.MuiList-padding > div > li:hover .subMenuIcon {
  color: #fff;
}
.subOptionAlign {
  padding: 0px 0 16px 0;
}
.drawerDeviderAlign {
  margin-top: 8px;
}
.alignNavText {
  margin-left: 0.7em;
  
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  margin-top: 32px;
  letter-spacing: normal;
  color: #000000;
  display: inline-block;
}
.subMenuText > span {
  font-weight: 100;
  
}
.flexAlignItem {
  display: flex;
  align-items: center;
  grid-column-gap: 0.5em;
  column-gap: 0.5em;
  margin-left: 0.7em;
  font-size: 1.1rem;
  
  /* margin-bottom: 60px; */
  position: fixed;
  bottom: 0;
  height: 60px;
  background: #fff;
  width: 100%;
  max-width: 338px;
  left: 0;
  margin: 0;
  padding: 0px 20px;
  box-shadow: 3px 10px 42px 0 rgb(0 0 0 / 9%);
}
.anchornavigationstyle {
  text-decoration: none;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #242424;
  display: block;
}
.subMenuIcon {
  min-width: 0px !important;
}

@media screen and (max-width: 960px) {
  .MuiDrawer-paper {
    margin-top: 91px !important;
  }
}

@media screen and (max-width: 767px) {
  .MuiDrawer-paper {
    margin-top: 48px !important;
  }
}
