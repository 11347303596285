.alignSearchTable {
  margin-top: 32px;
}
.fw600 {
  font-weight: 600 !important;
}
.PDFTablePosition > .commanButton > .commonButtonStyle > .MuiButton-label {
  font-size: 16px !important;
  font-weight: 500 !important;
}
