.commonButtonStyle {
  border-radius: 5px !important;

  height: 40px !important;
}
.pr32 {
  padding-right: 32px !important;
}
.pl32 {
  padding-left: 32px !important;
}

.commonButtonStyle > .MuiButton-label {
  flex-grow: 0;

  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: left;
}

.disabledStyle {
  background-color: #a7a9ac !important;
  height: 22px;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 1.83;
  text-transform: uppercase !important;
  color: #fff;
}
.linkedInbuttonIcon {
  width: 25px !important;
  height: 25px !important;
  background-color: white !important;
  border-radius: 50%;
  margin-top: 15px;
}
.downloadIcon {
  width: 21px !important;
  height: 21px !important;
  border: none;
  margin-top: 15px;
}
