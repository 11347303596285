.leftRightAlign {
    padding: 0 24px;
}
.gridRowSpacing {
    margin-bottom: 48px !important;
}
.uploadPoliciesPDF {
    
    font-size: 12px;
    font-weight: 600;
    color: #e31837 !important;
    position: absolute;
    bottom: 0;
    bottom: -16px;
    height: 24px;
    text-transform: uppercase;
}
.uploadPDFDiv {
    position: relative;
}
.policiesTextArea > .textareastyle {
    height: 320px !important;
    padding: 0 !important;
    margin-bottom: 5px;
    background-color: #ffffff !important;
}

.calenderIcon .MuiFormControl-root::before {
    content: "";
    height: 24px;
    width: 24px;
    top: 18px;
    left: 16px;
    position: absolute;
    background-image: url(../../../../Assets/Images/collectiveIcons/calendar-line.svg);
    background-repeat: no-repeat;
    background-position: center;
}

.calenderIcon .Mui-focused .MuiFormControl-root::before {
    background-image: url(../../../../Assets/Images/collectiveIcons/calendar-fill.svg);
}
.autoCompleteOnFocus > div > fieldset {
    border-color: rgba(0, 0, 0, 0) !important;
}

.mb16 {
    margin-bottom: 16px;
}
.appPolicyContainer {
    margin-bottom: 12px !important;
}
.appPolicyContainer .MuiOutlinedInput-root {
    padding-left: 42px !important;
    overflow: hidden;
}

.appPolicyContainer .MuiInputLabel-formControl {
    transform: translate(42px, 24px) scale(1) !important;
    margin-top: -4px;
}

.appPolicyContainer .MuiInputLabel-shrink {
    transform: translate(10px, -4.5px) scale(0.75) !important;
    transform-origin: top !important;
    background: white !important;
}

.appPolicyContainer .MuiInputBase-root.MuiOutlinedInput-root {
    border: 1px solid #d3d3d3;
    height: 56px !important;
}

.appPolicyContainer .MuiAutocomplete-tag {
    margin: 4.5px 3px !important;
}

textarea.MuiInputBase-input.MuiInput-input.MuiInputBase-inputMultiline.MuiInput-inputMultiline {
    
}
.SpT {
    font-size: 12px;
    line-height: 1.67;
    color: #6d6e71;
    margin-bottom: 2px;
}
.SpTS {
    font-size: 16px;
    line-height: 1.5;
    color: #000;
}
.mlSubEdit {
    margin-left: 342px;
}
.mlLeftTitle2 {
    margin-left: 50px;
}
.btnEditSub {
    background-color: #fff !important;
}
.editSub .MuiInput-underline:before {
    display: none !important;
}

.editSub .MuiInput-underline:after {
    display: none !important;
}

.MuiFormControl-root.MuiTextField-root.fullWidth.customTextfield.sairaFp.editSub {
    border: 1px solid #d3d3d3 !important;
}
.editSub
    label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
    margin-top: 16px;
    margin-left: 16px !important;
}

.editSub
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-multiline.MuiInput-multiline {
    padding-top: 4px;
    padding-left: 16px;
    margin-top: 30px;
}

.subCtE label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
    margin-left: 16px !important;
}

.policiesMultiIcon .MuiFormControl-root::before {
    content: "";
    height: 14px;
    width: 14px;
    top: 20px;
    left: 20px;
    position: absolute;
    background-image: url(../../../../Assets//Images/Policy/policy-fill.svg);
    background-repeat: no-repeat;
    background-position: center;
}
.policiesMultiIcon > div > div > div > .MuiInputBase-input {
    padding-left: 30px !important;
}
.MuiFormControl-root.MuiTextField-root.fullWidth.customTextfield.sairaFp.editSub > label {
    padding-left: 16px !important;
}
.policyBU .MuiFormControl-root::before {
    content: "";
    height: 16px;
    width: 16px;
    top: 20px;
    left: 20px;
    position: absolute;
    background-image: url(../../../../Assets/Images/collectiveIcons/calendar-fill.svg) !important;
    background-repeat: no-repeat;
    background-position: center;
}
.policyBU > div > div > div > .MuiInputBase-input {
    padding-left: 30px !important;
}

.policiesMultiIcon fieldset,
.policyBU fieldset {
    top: -4px !important;
}
.policiesMultiIcon label#checkboxes-tags-demo-label,
.policyBU label#checkboxes-tags-demo-label {
    margin-left: 28px !important;
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 16px !important;
}
.policiesMultiIcon .MuiFormControl-root.MuiFormControl-fullWidth,
.policyBU .MuiFormControl-root.MuiFormControl-fullWidth {
    background-color: #fff !important;
}
.policiesMultiIcon input#checkboxes-tags-demo,
.policyBU input#checkboxes-tags-demo {
    padding-left: 32px !important;
    color: rgba(0, 0, 0, 0.87) !important;
}

.policiesMultiIcon .MuiInputLabel-outlined.MuiInputLabel-shrink,
.policyBU .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(-8px, -12px) scale(0.75);
    margin-top: 0px;
}
.policyBU input.MuiInputBase-input.MuiOutlinedInput-input,
.policiesMultiIcon input.MuiInputBase-input.MuiOutlinedInput-input {
    padding-left: 36px !important;
}
.policiesMultiIcon .MuiInputLabel-outlined.MuiInputLabel-marginDense,
.policyBU .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    margin-left: 28px !important;
    margin-top: 7px;
}

.policiesMultiIcon
    .MuiChip-root.MuiAutocomplete-tag.MuiAutocomplete-tagSizeSmall.MuiChip-sizeSmall.MuiChip-deletable,
.policyBU .MuiChip-root.MuiAutocomplete-tag.MuiAutocomplete-tagSizeSmall.MuiChip-sizeSmall.MuiChip-deletable {
    width: 130px !important;
    margin-left: 16px;
    margin-top: -3px;
}

.policiesMultiIcon
    .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense,
.policyBU
    .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
    height: 56px !important;
}

.editPModal
    .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault {
    width: 130px !important;
    margin-left: 36px;
}

.editPModal {
    padding-left: 0px !important;
}
textarea.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline.MuiOutlinedInput-inputMultiline.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
    
}
.editPolicyInnerHeader {
    padding: 10px 14px !important;
    margin-left: 0px !important;
    width: 100% !important;
    background-color: #f7f6f6 !important;
    margin-bottom: 24px !important;
}

.MuiOutlinedInput-root.Mui-focused
.MuiOutlinedInput-notchedOutline {
  border-color: #e4e4e4 !important;
}

.addPolicyFeild1{
    padding-left: 2px !important;
}