.dashboard {
  padding: 0px;
  color: #000;
  position: relative;
  width: 100%;
  overflow: hidden;
}
.todayPageTutorial {
  padding-top: 16px !important;
}
.dashboardMessageContainer {
  /* padding: 0 0 48px; */
  height: 200px;
  background-image: url(./../../Assets/Images/Group784_welcom_background/Group\ 784@2x.png);
  background-position: top;
  background-size: cover;
  /* background-image: linear-gradient(
        to right,
        rgba(36, 36, 36, 0.08),
        rgba(250, 247, 246, 0)
      ),
      linear-gradient(to left, #faf7f6, #faf7f6); */
}

.messageContainer {
  /* position: absolute; */
  /* top: 88px; */
  padding: 0px 80px 15px 80px;
  /* width: 100%; */
  /* z-index: 200; */
  border-bottom: #dadada 1px solid;
  margin-bottom: 10px !important;
  display: flex;
  align-items: center;
}

.Downloadpp {
  width: 150px;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #e31837;
  cursor: pointer;
  text-transform: uppercase;
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .Downloadpp {
    margin-bottom: 42px;
  }
}

.container-background {
  z-index: 100;
}

.rectangle-clip1 {
  width: 28vw;
  height: 266px;
  flex-grow: 0;
  z-index: 100;
  opacity: 0.8;
  clip-path: polygon(30% 0%, 100% 0%, 70% 100%, 0% 100%);
  background-color: #ffffff;
}

.sectionHeaderText {
  font-size: 2rem;

  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 1em;
}
.clipsContainer {
  display: none;
  justify-content: flex-end;
  margin-top: -16px;
  margin-right: 12px;
}
.sectionBgImage {
  width: 100%;
}
.greetings-text {
  /* 
    font-size: 16px;
    padding-bottom: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #242424; */
  height: 24px;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
}

.ppcontainer {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.message-text {
  display: block;
  width: 100%;
  /* height: 32px; */
  /* margin: 8px 0 0 0; */

  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  animation: pulse1 3s infinite;
}

@keyframes pulse1 {
  0% {
    color: #e31837;
    opacity: 1;
  }
  25% {
    color: #000;
    opacity: 1;
  }
  50% {
    color: #e31837;
    opacity: 1;
  }
  75% {
    color: #000;
    opacity: 1;
  }
  100% {
    color: #e31837;
    opacity: 1;
  }
}

.message-text-mobile {
  display: none;
}

.section-container {
  padding: 0 80px;
}
.taskCenterGap {
  margin-top: 24px !important;
}
.whatsNewHeader {
  /* margin: 0px 0 16px; */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

/* .viewMoreArticle {
    width: 127px;
    height: 24px;
    flex-grow: 0;
    
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: right;
    color: #e31837;
    cursor: pointer;
  } */
.viewMoreArticle {
  display: flex;
  align-items: center;
  flex-grow: 0;

  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
  color: #e31837;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 16px;
}
/* .editIcon {
    
    height: 28px;
    font-size: 20px;
    font-weight: 600;
    color: #e31837;
    display: flex;
    text-transform: uppercase;
    margin-top: 4px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: right;
    cursor: pointer;
  } */
.editIcon {
  height: 50px;
  font-size: 12px;
  font-weight: 600;
  color: #e31837;
  display: flex;
  text-transform: uppercase;
  /* margin-top: 4px; */
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.4; */
  letter-spacing: normal;
  text-align: right;
  cursor: pointer;
  margin-left: 24px;
  align-items: center;
}

.edit-line {
  width: 24px;
  height: 22px;
  margin: 0px 4px 0px 0px;
  object-fit: contain;
}

.whatsNewHeader > .section-header-text {
  margin: 0;
}

.section-header-text {
  /* width: 176px; */
  height: 50px;

  font-size: 22px;
  /* font-weight: 700; */
  font-stretch: normal;
  font-style: normal;
  line-height: 50px;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.whats-new-container {
  height: 344px;
}

.postIt {
  padding-top: 5px;
  color: #db233a;
  display: flex;
}

.postItIcon {
  padding: 4px 4px 0 0px;
}

.postIt-txt {
  font-size: 18px;
}

.sectionMaginTop {
  margin-top: 36px !important;
}

.todaySectionBg {
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 5em;
  /* background-position: 0px 408px; */
  background-position: 0px;
  background-image: url(./../../Assets/Images/sectionBackground.png);
}

.bannerTopSection {
  display: flex;
  justify-content: space-between;
}

.hrBorder {
  border: 6px solid #faf9f9;
  margin-left: -16px;
  width: calc(100% + 20px);
}

/* css for quote of the day*/
.textAreaDiv {
  position: relative;
}
.charsLeftText {
  position: absolute;
  bottom: 1em;
  right: 1em;

  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #6d6e71;
}
.textArea {
  border: solid 1px #e4e4e4;
  background-color: #fbfbfb;
  /* height: 10em !important; */
  /* width: 60em !important; */
  resize: none;
  /* padding: 1.5em 0 0 1.5em; */

  font-size: 0.9rem;
  font-weight: normal;
  line-height: 1.57;
  color: #000;
  height: 100%;
  /* height: 400px !important; */
}
.textArea::-webkit-input-placeholder {
  font-size: 1rem;
}
.textArea::-moz-input-placeholder {
  font-size: 1rem;
}

.textArea:-moz-input-placeholder {
  font-size: 1rem;
}

.textArea:-ms-input-placeholder {
  font-size: 1rem;
}

.textarea::placeholder {
  font-weight: normal;
  line-height: 1.57;
  color: #6d6e71;
}

.containerTxt {
  display: flex;
  position: relative;
  width: 100%;
}

/** All Movile/Tab responsive code gose here 
  */
@media screen and (max-width: 1024px) {
  .messageContainer {
    width: 100%;
    padding: 0 20px 15px;
  }
  .dashboardMessageContainer {
    height: 330px;
    /* padding: 0px 0 31px; */
  }
  .section-container {
    padding: 0 40px;
  }
}
@media screen and (max-width: 960px) {
  .section-container {
    padding: 0 20px;
  }
}
@media screen and (max-width: 767px) {
  .dashboardMessageContainer {
    height: 183px;
    margin: 48px 0 0 0;
    /* padding: 0px 0 31px; */
  }
  .section-container {
    padding: 0 16px;
  }
  .todaySectionBg {
    margin-top: 3em;
  }
  .messageContainer {
    padding: 0px 16px 0 16px;
    width: 100%;
    font-size: 28px;
    font-weight: 500;
  }

  .ppcontainer {
    display: block;
    align-items: center;
    padding-bottom: 10px;
  }

  .Downloadpp {
    width: 100%;

    text-align: left;

    margin-top: 10px;
  }

  .message-text-mobile {
    display: block;
    width: 100%;
    height: 56px;
    flex-grow: 0;
    margin: 0;

    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: left;
    color: #000;
  }

  .greetings-text {
    flex-grow: 0;
    font-size: 12px;
    line-height: 2;
  }

  /* .section-header-text {
      font-size: 24px;
      line-height: 1.33;
    } */

  .clipsContainer {
    margin-right: -50vw;
    margin-top: 50px;
  }

  .rectangle-clip1 {
    width: 66vw;
    height: 190px;
  }

  .taskCenterGap {
    margin-top: 10px !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1279px) {
  .message-text {
    margin: 0 0 44px 0;
  }
}

/* css by ps */
.sitetutorialpanel {
  /* height: 28px; */
  font-size: 12px;
  font-weight: 600;
  color: #e31837;
  display: flex;
  text-transform: uppercase;
  /* margin-top: 4px; */
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.4; */
  letter-spacing: normal;
  text-align: right;
  cursor: pointer;
  margin-left: 24px;
  align-items: center;
}

.tuticon {
  width: 24px;
  height: 22px;
  margin: 0px 4px 0px 0px;
  object-fit: contain;
}
@media screen and (min-width: 1376px) {
  .section-container,
  .MuiGrid-container {
    max-width: 1280px !important;
    margin: auto;
  }
  .erPanel {
    max-width: 100% !important;
    margin: auto;
    padding-top: 20;
    padding-bottom: 20;
  }
}
.pagetuttop {
  width: 100%;
  padding: 0px 15px;
}

@media screen and (min-width: 760px) {
  .mshow {
    display: none;
  }
  .mhide {
    display: flex;
  }
}

@media screen and (max-width: 760px) {
  .mshow {
    display: block;
  }
  .mhide {
    display: none;
  }

  .message-text {
    margin: 30px 0;
  }
}
.PPleter {
  margin-right: 20px;
}
