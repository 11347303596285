.displayBlockUserDetails {
  display: block;
}

.bC {
  height: 84.3vh;
}

.dFlex {
  margin-top: 0.5% !important;
}
.MuiGrid-root.detailsDiv.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-10 {
  height: 246px;
}

.UserDetails {
  height: 96px;
  padding-right: 179px;
}

.orgDetails {
  height: 96px;
  padding-right: 179px;
}

.orgTitle {
  margin-top: -18px;
}

.detailsFontSize {
  font-size: 16px !important;
  padding: 0px;
  margin: 4px 0px 0px 0px;
}

.dFlex,
.UserDetails,
.orgDetails {
  display: flex;
  align-items: center;
}

.UserDetails,
.orgDetails {
  justify-content: space-between;
  flex-direction: row;
}
.bColor {
  background-color: #fff !important;
}

.div1ProfileTitle {
  font-size: 2.5rem;
  font-weight: 500;
  
  color: #000;
}

.imgDimension {
  width: 100%;
  height: 212.2px;
  object-fit: cover;
}

.fontFamilySaira {
  
}
.detailsDiv {
  margin-top: -40px !important;
  padding-left: 24px !important;
}
.cardProfile .MuiCardContent-root {
  padding: 1px !important;
}
.card {
  height: 286px !important;
  min-width: 192px !important;
  max-width: 100% !important;
}
.cardFlex {
  height: 288px;
  min-width: 194px !important;
  max-width: 100% !important;
}
.cardProfile {
  padding: 8px !important;
}

.cardFlex .MuiCardActionArea-root:hover .MuiCardActionArea-focusHighlight {
  opacity: 0 !important;
}

.orgTtileP {
  display: flex;
  height: 100%;
  align-items: center;
  margin-block-start: 2.2em;
  margin-block-end: 0.2em;
  font-size: 1.125rem;
  font-weight: 600;
  margin-left: 1.2em;
  /* margin-left: 5.5vw; */
}

.pMarginLeft1 {
  margin-left: 16px;
  margin-top: 15.2px;
}
.pMarginLeft2 {
  margin-left: 17px !important;
  margin-top: -4px;
}

.div1p1 {
  font-size: 1.25rem;
  color: #000;
  margin-block-end: 0px;
}
.div1p2,
.detailDivp {
  font-size: 12px;
  color: #6d6e71;
  padding: 0px;
  margin: 0px;
}

.ConatinerMBMT {
  top: 16vh;
  position: relative;
  margin-bottom: 1em;
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.cardFlex {
  display: flex;
  flex-direction: row;
  box-shadow: none !important;
}

.card {
  display: block;
}

.profileTitle {
  padding: 8px !important;
}

.cardDetails {
  flex: 1;
  box-shadow: none !important;
}

.MuiGrid-root.detailsDiv.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-10 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.orgTitle {
  height: 71px !important;
  margin-bottom: 2px;
  text-align: center;
}

@media (max-width: 960px) {
  .detailsDiv {
    margin-top: 0px !important;
    padding-left: 8px !important;
  }

  .cardProfile {
    padding: 8px !important;
  }

  .cardFlex {
    height: 100%;
    width: 100% !important;
  }

  .card {
    height: 100% !important;
    width: 100% !important;
  }

  .MuiGrid-root.detailsDiv.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-10 {
    height: auto;
  }

  .UserDetails {
    height: auto;
  }

  .orgDetails {
    height: auto;
  }

  .orgTitle {
    margin-top: auto;
  }

  .cardFlex {
    height: auto;
  }

  .cardProfile {
    max-width: 100% !important;
  }
  .imgDimension {
    width: 30%;
    height: 11.73em;
    object-fit: cover;
  }
  .card {
    display: flex;
  }
  .cardDetails .MuiCardContent-root {
    height: 100% !important;
  }
}
@media (max-width: 600px) {
  .orgTitle {
    margin-top: -15px !important;
  }
  .dFlex,
  .UserDetails,
  .orgDetails {
    display: flex;
    align-items: flex-start;
  }

  .displayBlockUserDetails {
    display: block;
  }

  .cardProfile {
    max-width: 100% !important;
  }
  .bC {
    background-color: transparent;
    height: 70em;
  }
  .imgDimension {
    width: 50%;
    height: 11.73em;
    object-fit: cover;
  }
  .card {
    display: flex;
  }

  .UserDetails,
  .orgDetails {
    justify-content: space-around;
    flex-direction: column;
  }

  .mlP {
    margin-left: 2em !important;
  }
  .orgTtileP {
    margin-left: 1.7em !important;
  }
  .ConatinerMBMT {
    top: 9.5vh;
    margin-bottom: 10em !important;
  }
}
