.menuContainer {
  display: flex;
  width: 100%;
  height: 80px;
  align-items: center;
  /* background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.32),
      rgba(0, 0, 0, 0)
    ),
    linear-gradient(to bottom, #db233a, #db233a); */
    background: #000;
  
}
.navMenuAlign {
  display: flex;
  justify-content: left;
  width: 100%;
  column-gap: 40px;
  position: relative;
  top: 2px;
  margin-left: 80px;
}
.navLogoAlign {
  display: flex;
  align-items: center;
}
.menuContainer > div > div > div {
  left: 0 !important;
  top: 4em !important;
  width: 20em;
}
.hideMenu > div > div {
  display: none !important;
}
.navMenuAlign > .responsiveCheck > div > div {
  left: 0 !important;
}
.responsiveCheck > div > div {
  top: 68px !important;
  width: 360px;
  padding: 0px 8px 0px 8px;
  border-radius: 0;
}
.responsiveCheck > div > div > ul
 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.dropDownItem > div {
  right: 100% !important;
}

.responsiveCheck .submenulist:first-child
 {
  margin-top: 8px !important;
}

.responsiveCheck .submenulist:last-child
 {
  margin-bottom: 8px !important;
}



.iconAlign {
  padding-left: 16px;
  width: auto !important;
}
.alignMenuIconCenter {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .menuContainer {
    height: 48px;
  }
}

@media screen and (max-width: 1260px) {
  .navMenuAlign > .responsiveCheck > div > div {
    left: -81% !important;
}

.responsiveCheck > div > div {
  
  width: 275px;
  
}
}
