.rollBasedTab > div > div > button {
    margin-left: 0 !important;
    margin-top: 24px !important;
  }
  .rollBasedTabPanel > div {
    padding: 16px 0 !important;
  }
  .rollBasedTitle {
    
    font-size: 24px;
    font-weight: 500;
    color: #000000;
  }
  .rollBasedPaper {
    padding: 24px;
  }
  .rollBasedBtn {
    padding: 12px 24px !important;
  }
  .rollBasedTable {
    border-top: 1px solid #ededed;
  }
  .rollBasedTableHeader {
    display: flex;
    
    font-size: 12px;
    font-weight: 600;
    padding: 24px;
    column-gap: 10px;
  }
  .rollBasedTableContent {
    display: flex;
    
    font-size: 12px;
    font-weight: normal;
    color: #242424;
    padding: 10px 24px;
    column-gap: 10px;
    align-items: center;
  }
  .rollBasedTableCol1 {
    flex: 0.7;
  }
  .rollBasedTableCol2 {
    flex: 0.6;
  }
  .rollBasedTableCol3 {
    flex: 1;
    display: inline-block;
  }
  .rollBasedTableCol4 {
    flex: 1.1;
  }
  .rollBasedTableCol5 {
    flex: 0.6;
  }
  .rollBasedTableCol6 {
    flex: 0.5;
  }
  .rollBasedTableCol7 {
    flex: 0.9;
  }
  .rollBasedTableCol8 {
    flex: 0.2;
  }
  .rollBasedTableCol9 {
    flex: 0.3;
    display: flex;
    justify-content: center;
  }
  .statusActive {
    color: #7ac143 !important;
    border: 1px solid #7ac143 !important;
    background-color: rgba(122, 193, 67, 0.1) !important;
    padding: 6px 12px !important;
    
    font-size: 12px !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    border-radius: 0px !important;
  }
  /* p > div {
    box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.1) !important;
  } */
  .rollBasedTableLink {
    color: #7ac143 !important;
  }
  .rollBasedTablePagination {
    margin-top: 24px;
    margin-bottom: 24px;
    float: right;
  }
  .rollBasedAutoComplete > div > div > div {
    height: 50px !important;
  }
  .rollBasedSearchBarButton > div > div {
    background-color: #f7f6f6 !important;
  }
  .rollBasedSearchBarButton > div > div > div > fieldset {
    box-shadow: none !important;
  }
  .rollBasedSearchBarButton > div > div > div > input#outlined-basic {
    color: #a7a9ac !important;
    font-size: 14px !important;
    font-weight: normal !important;
  }
  .rollBasedAutoComplete > div > div > div > fieldset {
    border-color: rgba(0, 0, 0, 0) !important;
  }
  .rollBasedAutoComplete > div > div {
    background-color: #f7f6f6 !important;
  }
  

  .container{
    display: flex;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    list-style: none;
  }