::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 5px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #ccc;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #9c9ea17a;
}
