.publishRejectBtn {
  display: flex;
  column-gap: 16px;
}
.m0 {
  margin: 0 !important;
}
.btnReject {
  border-radius: 0 !important;
  border: 1px solid #e31837 !important;
  color: #e31837 !important;
  
  font-size: 12px !important;
  font-weight: 600 !important;
  padding: 13px 24px !important;
}
.btnReject:hover {
  background-color: #ffffff !important;
}
.inlineContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.previewMCData {
  
  font-size: 12px;
  font-weight: 600;
  color: #e31837;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  column-gap: 24px;
}
