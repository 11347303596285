.multiSelect
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-16awh2u-MuiAutocomplete-root
  .MuiAutocomplete-inputRoot {
  height: 54px !important;
}

.multiSelect
  .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault{
  width: 130px !important;
}

.multiSelect
  .MuiAutocomplete-hasPopupIcon.css-16awh2u-MuiAutocomplete-root
  .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-16awh2u-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  height: 54px !important;
}
