.linearP1 {
    position: fixed !important;
    width: 100%;
    left: 50%;
    top: 99vh;
    height: 6px !important;
}
.linearP2 {
    position: fixed !important;
    transform: rotate(180deg) !important;
    width: 100%;
    left: -50%;
    top: 99vh;
    height: 6px !important;
}

.MuiLinearProgress-barColorSecondary {
    background-color: #e41837 !important;
    border-top: 1px solid #b61d33 !important;
}

.gifSize {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    width: 80px;
    bottom: 0;
    margin: auto;
}
.megaDiv {
    width: 100vw;
    height: 100vh;
}

.innerDiv {
    width: 100vw;
    height: 100vh;
}

.overHIdden {
    overflow: hidden;
}

.MuiLinearProgress-colorSecondary {
    background-color: #fff !important;
}

@media (max-width: 360px) {
    .linearP2 {
        top: 97.6vh;
    }
}
@media (max-width: 320px) {
    .linearP2 {
        top: 97.4vh;
    }
}
@media (min-width: 360px) and (max-width: 375px) {
    .linearP2 {
        top: 97.6vh;
    }
}
/* @media (min-width:375px) and (max-width: 413px) {
    .linearP2 {
        top: 97.89vh;
    }
}  */
