.RWAdminAlign {
  float: right;
  padding-right: 8px;
}
@media (max-width: 960px) {
  .alignResponsivePadding {
    padding: 16px 8px;
  }
  .headerContent1 {
    display: none;
  }
}
