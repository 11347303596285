.liveWireDropDownContainer {
  padding: 0px 0px 16px;
}

.zeroPad {
  padding: 0px !important;
}

.leaderSpeakCardContainer {
  padding: 0 8px 16px 8px;
}

/* .liveWireDropDownContainer .MuiGrid-item {
  padding: 8px;
} */
.posRight {
  float: right;
}
.liveWireDropDownContainer .MuiFormControl-root {
  width: 100%;
}

.liveWireDropDownContainer .MuiFormControl-root {
  box-shadow: 0 6px 35px 0 rgb(0 0 0 / 10%);
  background-color: #fff !important;
}

.liveWireDropDownContainer .MuiOutlinedInput-root {
  border-radius: 0px !important;
  height: 48px !important;
  padding-left: 30px !important;
}

.liveWireDropDownContainer input#select-typt-file {
  margin: -2px 0px 0px 0px;
  
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.businessTypeIcon .MuiFormControl-root::before {
  content: "";
  height: 24px;
  width: 24px;
  top: 13px;
  left: 6px;
  position: absolute;
  background-image: url(../../../../Assets/Images/collectiveIcons/building-line.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.businessTypeIcon .Mui-focused .MuiFormControl-root::before {
  background-image: url(../../../../Assets/Images/collectiveIcons/building-fill.svg);
}

.calenderTypeIcon .MuiFormControl-root::before {
  content: "";
  height: 24px;
  width: 24px;
  top: 12px;
  left: 6px;
  position: absolute;
  background-image: url(../../../../Assets/Images/collectiveIcons/calendar-line.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.calenderTypeIcon .Mui-focused .MuiFormControl-root::before {
  background-image: url(../../../../Assets/Images/collectiveIcons/calendar-fill.svg);
}

.shortingTypeIcon .MuiFormControl-root::before {
  content: "";
  height: 24px;
  width: 24px;
  top: 12px;
  left: 6px;
  position: absolute;
  background-image: url(../../../../Assets/Images/sort-by.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.noIcon .MuiFormControl-root::before {
  background-image: none;
}

.noIcon .Mui-focused .MuiFormControl-root::before {
  background-image: none;
}

.liveWireDropDownContainer .noIcon .MuiOutlinedInput-root {
  padding-left: 16px !important;
  overflow: hidden;
}
.mahindraCollectiveSector
  fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.liveWireDropDownContainer .noIcon .MuiInputLabel-formControl {
  transform: translate(16px, 18px) scale(1) !important;
  margin-top: -4px;
}

.liveWireDropDownContainer .MuiChip-root {
  height: 26px !important;
}

/* .liveWireDropDownContainer .MuiAutocomplete-tag {
  margin: 2px 3px 9px 3px !important;
} */

/* .liveWireDropDownContainer .MuiGrid-container {
  justify-content: space-around;
} */

/* .liveWireDropDownContainer
    .MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  } */

.liveWireDropDownContainer
  fieldset.PrivateNotchedOutline-root-3.MuiOutlinedInput-notchedOutline {
  border-color: #ffffff !important;
}

.liveWireDropDownContainer .MuiInputLabel-formControl {
  transform: translate(32px, 18px) scale(1) !important;
  margin-top: -4px;
  /* width: 62px;
    height: 18px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; */
    
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

.liveWireDropDownContainer .MuiInputLabel-shrink {
  transform: translate(10px, -4.5px) scale(0.75) !important;
  transform-origin: top !important;
  margin-top: 0px;
  /* display: none !important; */
  font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}
.MCsubmitbutton {
  width: 197px;
  height: 48px !important;
  padding: 13px 48px;
  background-color: #e31837 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 1.83;
  text-align: right;
  color: #fff;
  box-shadow: none !important;
  float: right;
}
.alignBtnRight {
  float: right;
  padding: 1em 0 0.5em 0;
}

span.MuiChip-label {
  
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #000;
}

/* .liveWireDropDownContainer .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0) !important;
} */

/* Uncomment it to shift autocomplete text for icon */
/* .liveWireDropDownContainer .MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    padding-left: 40px !important;
  } */

/* @media screen and (max-width: 812px) { */
.modalHeaderText1 {
  /* margin: 26px 0 26px 0; */
  /* height: 48px; */
  
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 7px;
  letter-spacing: normal;
  color: #e31837;
  text-align: center;
  margin: auto;
  cursor: pointer;
}
.headerContent1 {
  /* margin-right: 10px; */
  align-items: center;
  width: fit-content;
  height: 60px;
  width: "100%";
  /* background-color: #000; */
  right: float;
  display: flex;
  justify-content: end;
}
.modalTitleIcon1 {
  /* width: 16px;
      height: 16px;
      flex-grow: 0;
       margin: 5px 8px 8px 16px; 
      object-fit: contain;
      margin-top: -3px;
      color: #e31837;
      background-color: #e31837; */
  width: 24px;
  margin: 2px 8px 2px 0;
  /* object-fit: contain; */
}
/* .modalCloseIcon {
      width: 24px;
      height: 24px;
      flex-grow: 0;
      object-fit: contain;
    } */
/* } */
@media (max-width: 960px) {
  .remoteMobileResponsiveHideAdmin {
    display: none;
  }

  .liveWireDropDownContainer input#select-typt-file {
    margin-left: 30px;
  }

  .modalBody .Standardfilter .MuiGrid-grid-sm-9 {
    
    max-width: 100%;
    flex-basis: 100%;
}

  /* .Standardfilter .liveWireDropDownContainer .MuiChip-root {
    margin-left: 30px;
} */
}


