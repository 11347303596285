.fromCenter:hover,
.fromCenter:focus,
.fromCenter:active {
  font-weight: bold;
}
.fromCenter:after {
  display: block;
  content: "";
  border-bottom: solid 4px #fefdfd;
  bottom: -20.6px;
  position: relative;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.activeRoute {
  font-weight: bold !important;
}

.fromCenter:hover:after,
.fromCenter:focus:after,
.fromCenter:active:after {
  transform: scaleX(1.3);
}
.activeNavTab:after {
  transform: scaleX(1.3);
}
.activeNavTab {
  font-weight: bold !important;
}
.activeTab {
  font-weight: bold !important;
}

.dropDownItem {
  color: #242424;
  justify-content: space-between;
  font-weight: 100;
  font-size: 1em;
  white-space: normal;
  padding: 0;
}
.dropDownItem a {
  color: #242424;
  justify-content: space-between;
  font-weight: 100;
  font-size: 14px;
  white-space: normal;
  text-decoration: none;
  padding: 10px 15px;
  width: 100%;
}
.dropDownSubMenuItem {
  color: #000000 !important;
}
.dropDownSubMenuItem:hover {
  color: #000000 !important;
  background-color: rgba(219, 35, 58, 0.05) !important;
}
.dropDownSubMenuItem:hover .dSumMenuItem {
  font-weight: 600 !important;
}
.dropDownSubMenuItem {
  white-space: normal;
  font-size: 14px;
  padding: 0px;
}
.dropDownSubMenuItem a {
  white-space: normal;
}

.dropDownItem:hover {
  color: #000000 !important;
  background-color: rgba(219, 35, 58, 0.05) !important;
}
.dropDownItem:hover .DMenuItem {
  font-weight: 600 !important;
}
.dropDownContainer {
  border: none;
  background: transparent;
  color: #ffffff;
  flex-grow: 0;
  padding: 0;

  font-size: 14px;
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: normal;
  text-align: left;
}
.dropDownContainer:hover {
  cursor: pointer;
  color: #ffffff !important;
}

.dropDownItem > div {
  margin-left: 24px;
  width: 320px;
  padding: 0 8px 0 8px;
  border-radius: 0;
  margin-top: 4px;
}
/* .specificMenuScroll > div {
  top: -15.3em !important;
} */
.specificMenuScroll > div:before {
  top: 21em !important;
}
/* .ddlRoleBased > div {
  top: -20.6em !important;
} */
.ddlRoleBased > div:before {
  top: 20.4em;
}
.coSResStyle > div > ul {
  max-height: 18em;
  overflow-y: auto;
}
.dropDownItem .navigateIcon {
  margin-right: 10px;
}
.dropDownItem:hover .navigateIcon {
  color: #db233a !important;
}
.navigateIcon {
  color: #9f9f9f;
}
.hideNavigationDropDown > div > div > div {
  display: none !important;
}
@media only screen and (max-width: 1024px) {
  .dropDownContainer {
    font-size: 11px !important;
  }
  .navMenuAlign {
    column-gap: 20px !important;
  }
  .nav-icons {
    column-gap: 24px !important;
    cursor: pointer;
  }
}

.dMdivider {
  width: 328px;
  height: 1px;
  flex-grow: 0;
  margin: 23px 8px 24px;
  background-color: #ededed;
}
.dropDownItem:hover div {
  display: block !important;
}
.rightSubMenuOption div:before {
  content: "";
  display: block !important;
  height: 70px;
  position: absolute;
  left: -38.3px;
  width: 60px;
  top: -10px;
}
.rightSubMenuOption > div {
  margin-right: 18px !important;
}
.profileDropDownStyle > div > div {
  width: 215px !important;
}
@media (max-width: 1024px) {
  .fromCenter:after {
    bottom: -24.6px;
  }
  .nav-icons {
    width: 30%;
  }
}
@media (max-width: 812px) {
  .nav-icons {
    width: 30%;
  }
}

/* Css by Pankaj */

.tooltipdata {
  display: none;
}

.responsiveCheck:hover .tooltipdata {
  position: absolute;
  display: block;
}

.Subdropdownactive .tooltipdata {
  display: none !important;
}

/* .allsubhide:hover .tooltipdata {
  display: none;
} */
@media (min-width: 1076px) {
  .ddlRoleBased.moredata > div {
    /* top: -20.6em !important; */
    /* overflow-y: scroll; */
    max-height: 500px;
    top: -217px !important;
    width: 470px;
    min-height: 300px;
  }

  .ddlRoleBased.moredata > div .dropDownSubMenuItem {
    width: 50%;
    float: left;
    /* height: 40px; */
  }

  .ddlRoleBased.moredata > div .dropDownSubMenuItem a {
    white-space: normal;
    padding: 8px 15px;
    font-size: 12px;
  }
}

.ddlRoleBased.moredata > div::-webkit-scrollbar {
  width: 5px !important;
  height: 50px;
}

.ddlRoleBased.moredata > .Submenu_submenu__27o9E > ul {
  overflow: auto;
  z-index: 9999;
}

.ddlRoleBased.moredata > div::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
}

.ddlRoleBased.moredata > div::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #ccc;
}

.ddlRoleBased.moredata > div::-webkit-scrollbar-thumb:hover {
  background-color: #9c9ea17a;
}
/* .lastsubnav div {
  top: -5.6em !important;
} */

.dropDownItem.specificMenuScroll > div {
  /* display: none !important; */
  top: auto;
}

.dropDownItem.ddlRoleBased > div {
  /* display: none !important; */
  top: auto;
}
.dropDownItem.lastsubnav > div {
  top: auto;
}
