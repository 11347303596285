.leadersSpeakRootComponent {
  margin-top: 14px;
  padding: 10px 0;
}

.leadrHeader {
  width: 213px;
  height: 40px;
  
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.leaderSpeakPaper {
  padding: 0 0.5em 0 0.5em;
  box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12) !important;
  background-color: #ffffff !important;
  /* min-height: 170px; */
  margin: 38px 0 64px 0;
}

.searchGrid {
  height: 104px;
  padding: 24px 0;
}

