.statusPendingOrange {
  padding: 0px !important;
  background: none !important;
  border: 0px !important;
  color: orange !important;
}

.offerCampaign {
  padding: 6em 0 1em 0;
}
.responsiveMobFilter {
  display: flex;
  justify-content: space-between;
}
.addScroll {
  height: 50vh;
  overflow: auto;
}
.MClandingpageAlign {
  padding: 16px 10px;
  margin-top: 18px;
  box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12) !important;
  background-color: #fff !important;
}

.nobottomshadow {
  box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12) !important;
}
.mCollectiveAdmin {
  
  font-size: 20px;
  font-weight: 600;
  color: #e31837;
  text-transform: uppercase;
}
.mahindraColleAdmin {
  display: flex;
  justify-content: flex-end;
  column-gap: 8px;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 0px;
}
.MCContainer {
  padding: 0px 8px;
}
.MCFilters {
  padding: 0 24px;
}
.MCalignCardsItems {
  padding: 16px 8px !important;
  border: solid 1px #ededed;
  /* height: 138.1em; */
}

.padding16 {
  padding: 16px 12px !important;
}

.padding12 {
  padding: 8px 12px !important;
}

.McShadowBox {
  box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.1) !important;
}
.McFilterAlign {
  padding: 0 0 16px 0;
}
.floatRightAlign {
  float: right;
}
.MCalignCardsItemstable {
  padding: 16px 8px !important;
  border: solid 1px #ededed;
  height: 29.1em;
}

.mbToolbar {
  margin-bottom: 25px !important;
  margin-top: -25px;
}

.tableGutter .MuiToolbar-gutters {
  padding-left: 8px !important;
  padding-right: 0px !important;
}

.MCalignCardsItemstable1 {
  padding: 16px 8px !important;
  border: solid 1px #ededed;
  height: auto;
  
}
.MCsubmitbutton {
  width: 197px;
  height: 48px !important;
  padding: 13px 48px;
  background-color: #e31837 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 1.83;
  text-align: right;
  color: #fff;
  box-shadow: none !important;
}
.alignBtnRight {
  float: right;
  padding: 1em 0 0.5em 0;
}
.MCtabContent {
  padding: 0px 8px 0 8px;
  text-align: left;
  background-color: #f7f6f6;
  
  font-size: 0.9em;
  cursor: pointer;
  height: 38px;
  display: flex;
  align-items: center;
}
.offCHeaderTitle {
  
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.25;
  text-align: left;
  color: #000;
}
/* .PrivateTabIndicator-colorPrimary-14 {
      background-color: #e31837  !important;
  } */
.mobileheadTab {
  background-color: transparent !important;
  color: #000000 !important;
  box-shadow: none !important;
}
.bgImage {
  background-image: url(../../../Assets/Images/sectionBackground.png);
}
.MCboldTitle {
  font-weight: 600;
}
.MCactiveTab {
  padding: 0 8px 0 8px;
  text-align: left;
  background-color: #e31837;
  box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12);
  
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  height: 38px;
  display: flex;
  align-items: center;
}

.MCactiveTab .McsubDescription {
  color: #fff;
}
.m-1 {
  margin-top: 0.5em;
}
.MCtabTitle {
  margin: 0 !important;
}
.hoverEffect:hover,
.hoverEffect:active {
  background-color: #e31837;
  color: #ffffff;
}
.hoverEffect:hover .McsubDescription,
.hoverEffect:active .McsubDescription {
  background-color: #e31837;
  color: #ffffff;
}
@media screen and (max-width: 812px) {
  .commanButton {
    margin-bottom: 1em;
  }
}
.McsubDescription {
  font-size: 12px;
  font-weight: normal;
  color: #a7a9ac;
}

.exportCsv {
  background-color: #e31837 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px !important;
  position: absolute;
  right: 8px;
  margin-bottom: -22px;
  margin-top: 25px;
}

.exportCsv > a {
  color: #fff;
  font-size: 16px;
  padding: 14px;
  text-decoration: none;
  
}
.MCMobileFilter {
  display: none;
}
.MCFilter {
  width: 73px;
  height: 40px;
  flex-grow: 0;
  border: solid 1px #d3d3d3;
  background-color: #f7f6f6;
  color: #000000;
  
  font-size: 14px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  justify-content: center;
  cursor: pointer;
}
.width100 {
  width: 100%;
}
/* .responsiveTabs {
  display: none;
} */
.responsiveTabs > div > div > div > div > div > button {
  margin: 0 !important;
}
.responsiveTabs > div > div > div {
  padding-left: 0 !important;
  padding-right: 0 !important;
  /* padding-top: 18px !important; */
}
.MCMobilePagination {
  display: flex;
  justify-content: center;
  float: none !important;
}
/* .MCFilter {
  display: none;
} */
/* .responsiveMobFilter {
  display: none;
} */
@media (max-width: 960px) {
  .mahindraColleAdmin {
    display: none !important;
  }
  .MCFilter {
    display: flex;
  }
  .MClandingpageAlign {
    padding: 16px 0;
  }
  .liveWireDropDownContainer .MuiGrid-item {
    padding: 0px 10px !important;
  }

  .Standardfilter .MuiAutocomplete-root {
    margin-bottom: 25px !important;
  }

  /* .modalBody {
    max-height: 38em !important;
    min-height: 35em;
  } */

  .alignMCCOntentTabPanel > div > div {
    padding: 24px 0 0 0 !important;
  }
  .MCMobileFilter {
    display: block;
    margin-top: 16px;
  }
  .Standardfilter {
    padding: 20px 0px !important;
  }
  /* .liveWireFilters {
    display: none;
  } */
  .mFilter {
    background: none !important;
    box-shadow: none !important;
  }
  .HeaderTitleInMbl {
    margin-bottom: 16px !important;
  }
  .width100 {
    width: 100% !important;
  }
  .isMobileHideButton {
    display: none;
  }
  /* .MClandingpageAlign {
    display: none;
  } */
  .responsiveTabs {
    display: block;
  }
  .responsiveHideMC {
    display: none;
  }
  .responsiveShowMC {
    display: block;
    background-color: #ffffff;
    padding: 0 16px;
    margin-top: 16px;
  }
  .responsiveMobFilter {
    display: none;
    /* justify-content: space-between; */
  }
}
@media (min-width: 961px) {
  .mahindraCollectiveTabsDM > div > div {
    flex-direction: column;
    column-gap: 16px;
  }
  .responsiveMobFilter {
    display: none;
  }
  .mahindraCollectiveTabsDM > div > span {
    display: none !important;
  }
  .mahindraCollectiveTabsDM
    > div
    > div
    > .MuiTab-textColorInherit.Mui-selected {
    /* padding: 0 8px; */
    text-align: left;
    background-color: #e31837;
    /* box-shadow: 3px 20px 52px 0 rgb(0 0 0 / 12%); */
    
    font-size: 14px;
    color: #fff !important;
    /* height: 38px; */
    display: flex;
    align-items: center;
    margin-bottom: 8px !important;
  }
  .mahindraCollectiveTabsDM > div > div > button > span {
    align-items: baseline !important;
    font-size: 14px !important;
  }
  .responsiveTabs > div > div > div > div > div > button {
    margin: 0 !important;
    background-color: #f7f6f6;
    
    font-size: 0.9em;
    cursor: pointer;
    min-height: 38px;
    display: flex;
    align-items: center;
    margin-bottom: 8px !important;
    text-align: left;
  }
  .responsiveTabs
    > div
    > div
    > div
    > div
    > div
    > .MuiTab-textColorInherit:hover {
    background-color: #e31837;
    color: #fff !important;
    opacity: 1 !important;
  }
  .boxAlignPadMC {
    /* padding: 16px 10px !important; */
    border: 1px solid #ededed;
    width: 24% !important;
    background-color: #ffffff;
    border: 1px solid #ededed;
    /* border-bottom: 1px solid #ededed !important; */
  }
  .alignMCCOntentTabPanel {
    width: 80% !important;
    padding: 16px 12px !important;
    border: 1px solid #ededed;
    background-color: #ffffff !important;
  }
  .alignMCCOntentTabPanel > div > div {
    padding: 0 6px !important;
  }
  .responsiveTabs {
    margin-bottom: 0em;
  }
  .responsiveTabs > div {
    display: flex;
    column-gap: 16px;
  }
  .MCMobilePagination {
    display: none;
  }
}

/* css by ps */

.mycontributionblock .avatarIcon,
.mycontributionblock .avatarName,
.mycontributionblock .actionItemBtn {
  display: none;
}

.Standardfilter {
  box-shadow: none !important;
  padding: 12px 10px;
  padding-right: 18px;
  padding-bottom: 0px;
}

.standardlefttab {
  border: 0px;
  padding: 0px 10px !important;
  padding-right: 0px !important;
}

.standardrighttab {
  border: 0px;
  padding: 0px !important;
}

.standardtabcontainer {
  box-shadow: none !important;
  border: 0px;
}

.pr-0 {
  padding-right: 0px !important;
}

.standardtabcontainer .statusApproved {
  padding: 0px !important;
  background: none !important;
  border: 0px !important;
}

.standardtabcontainer .avatarIcon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  background: #dadada;
}

.standardtabcontainer .avatarIcon img {
  width: 100%;
  height: 100%;
}

.standardtabcontainer .actionItemBtn {
  margin-right: -15px;
}

@media (max-width: 560px) {
  .standardtabcontainer input#select-typt-file {
    margin-left: 25px !important;
  }
  .standardlefttab {
    padding-right: 10px !important;
  }
}
@media (max-width: 1260px) {
  .Standardfilter .liveWireDropDownContainer span.MuiAutocomplete-tag {
    display: none !important;
  }
}
.emergencyContactContainer .MuiTabs-flexContainer {
  border-bottom: 1px solid #d3d3d3;
}

.Modalcontent .css-19kzrtu {
  padding: 0px;
}

.righttextalign .charLeftField input {
  padding-right: 100px;
}

.multiselectsearch input {
  opacity: 0;
}

.multiselectsearch input:focus {
  opacity: 1;
}

/* .standardrighttab .padding12 {
  padding: 0px 12px !important;
  margin-bottom: 15px !important;
} */
