.sortDivAlignment {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8.8px;
  /* padding: 8px; */
  cursor: pointer;
  width: 184px;
  height: 48px;
  border: solid 1px #d3d3d3;
  background-color: #f7f6f6;
}

.arrowColor {
  fill: rgba(0, 0, 0, 0.4) !important;
}

.sortDivAlignment > p {
  font-size: 1rem;
  
  text-transform: capitalize;
}

.sortSpan {
  font-size: 16px;
  line-height: 1.5;
  color: #000;
}

.colorActive {
  fill: #e31837 !important;
}
.sortByText {
  flex-grow: 0;
  font-size: 16px;
  font-weight: 300 !important;
  line-height: 1.5;
  color: #000;
}
.sort_by_icon {
  width: 24px;
  height: 24px;
}
@media screen and (max-width: 812px) {
  .sortDivAlignment {
    margin-top: 8px;
  }
}
