.PoliciesCard {
  height: 209px;
  box-shadow: 0 20px 42px -20px rgba(0, 0, 0, 0.12) !important;
  padding: 24px 24px 32px;
}
.PoliciesCardContent {
  padding: 0 !important;
}
.PoliciesDate {
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  color: #6d6e71;
}
.policiesTitle {
  
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  color: #000000;
  margin-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  height: 1.5em;
  -webkit-box-orient: vertical;
}
.policiesDescription {
  height: 80px;
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: 0.25px;
  color: #242424;
  margin-top: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  height: 8em;
  -webkit-box-orient: vertical;
}
