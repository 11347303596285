.customTextEditor .ql-editor.ql-blank::before {
  color: #6d6e71 !important;
  content: attr(data-placeholder);
  font-style: normal !important;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
  
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.customTextEditor .ql-editor {
  min-height: 360px;
}

.customTextEditor .ql-editor p {
  font-size: 16px;
  
}

.customTextEditor .ql-toolbar {
  background: #e4e4e4;
  min-height: 48px;
  padding: 12px !important;
}

.customTextEditor .ql-tooltip.ql-editing {
  left: 0px !important;
  right: 0px !important;
  margin: auto !important;
  width: 35.5%;
}

/* .customTextEditor button.ql-video {
    background-image: url(../../../Assets/Images/text-editor/movie-line.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 16px !important;
}

.customTextEditor button.ql-video:hover{
    background-image: url(../../../Assets/Images/text-editor/video-fill.svg) !important;
}

.customTextEditor .ql-video svg {
    display: none;
} */
