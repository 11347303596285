.tabsPanelContainer {
  height: 50px;
  background-color: #f7f6f6 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #242424;
  box-shadow: none !important;
  position: relative;
  overflow: hidden;
}
.addMoreFevSixBox {
  height: 50px;
}
/* .showHideDragDropImage {
  visibility: hidden;
} */
/* .tabsPanelContainer:hover .showHideDragDropImage {
  visibility: visible;
  position: relative;
  left: 10px;
} */
.tabsPanelContainer:hover {
  background-color: #e31837 !important;
  color: #fff !important;
  transition: 0.3s ease-out;
}

.tabsPanelContainer:hover .iconImage {
  filter: brightness(0) invert(1);
}
.tabsPanelContainer:hover .verticalDots {
  filter: brightness(0) invert(1);
}

.tabsPanelContainer:hover .favBoxIconAlign > span {
  color: #fff !important;
}

.tabsPanelContainer:hover:after {
  position: absolute;
  content: "";
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: rgba(36, 36, 36, 0.3);
  animation-duration: 3s;
  animation-timing-function: ease-in;
  animation-delay: -2s !important;
  animation-name: shiny-btn1;
  z-index: 1;
}
.MuiTab-root {
  min-width: 0px !important;
  /* padding: 2px !important; */
  margin: 0 0 0 26px !important;
}
.alignDragIcon {
  padding: 0 4px 0 4px;
  display: flex;
  align-items: center;
}
.alignFavBoxes {
  margin-top: 20px;
  padding: 0 32px 0 32px;
}
.borderAddMore {
  border: 1px dashed;
  border-color: #a7a9ac;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.cursorMove {
  cursor: pointer;
}
.cursorDragDrop {
  cursor: move;
}

.MuiTab-wrapper {
  
  text-transform: capitalize;
}
.tabBorderDashed {
  border: dashed 1px;
  border-color: #a7a9ac;
}
.Mui-selected {
  color: #e31837 !important;
  font-weight: 600 !important;
}

.addMore {
  border: dashed 1px #a7a9ac;
}
.favBoxIconAlign {
  display: flex;
  align-items: center;
  column-gap: 0.5em;
}
.favBoxIconAlignMoreThan6 {
  height: 50px;
  background-color: #f7f6f6 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #242424;
  box-shadow: none !important;
  position: relative;
  overflow: hidden;
}
.favBoxIconAlignMoreThan6:hover {
  cursor: not-allowed !important;
}
/* .eCallP {
  padding-left: 32px;
} */
.favIconPL {
  padding-right: 12px;
  max-width: 40px;
}

.alignFavModelBoxes .favBoxIconAlign img {
  max-width: 24px;
}

.favBoxSpace {
  display: flex;
  align-items: center;
  column-gap: 0 !important;
}
.favBox {
  
  font-size: 1rem;
  line-height: 2;
  text-align: left;
  color: #242424;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.textTransform {
  text-transform: uppercase !important;
  color: #e31837 !important;
  font-weight: 500;
}
.moreThan6 {
  color: #a7a9ac !important;
  margin: 0 0 0 8px;
  
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
}
.addMore span {
  color: #e31837;
}

.favSectionMenuOptions {
  height: 16px;
  font-size: 12px;
  font-weight: 500 !important;
  line-height: 1.33;
  color: #130f26;
}

.favMenuItems {
  font-size: 12px;
  line-height: 1.33;
  color: #6d6e71;
  padding: 8px 10px 9px 16px;
  margin: 0;
  cursor: pointer;
}
.favMenuItems:hover {
  background-color: #f7f6f6;
  color: #000;
}
.favMenuItems :hover {
  cursor: pointer !important;
}

.addMoreDiv {
  cursor: pointer;
}

.verticalDots {
  padding: 16px 8px;
  z-index: 1;
}

.popperBlock {
  z-index: 2;
}

@keyframes shiny-btn1 {
  0% {
    -webkit-transform: scale(0) rotate(35deg);
    opacity: 0;
  }
  70% {
    -webkit-transform: scale(0) rotate(35deg);
    opacity: 0.5;
  }
  71% {
    -webkit-transform: scale(4) rotate(35deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(35deg);
    opacity: 0;
  }
}
@media (max-width: 1024px) {
  .taskName {
    margin: 0 !important;
    height: 54px;
  }
  .alignFavBoxes {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 0 16px 0 16px;
  }
  .favBox {
    font-size: 15px;
  }
}
@media (max-width: 812px) {
  .alignFavBoxes {
    padding: 0 16px 0 16px;
  }
  .onPriorityContainer {
    height: auto !important;
    margin-bottom: 20px;
  }
  .taskName {
    margin: 0 !important;
    height: 60px;
  }
}
