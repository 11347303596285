.policyTableCss th.MuiTableCell-root.MuiTableCell-head {
  color: #000 !important;
  font-weight: 600;
  background-color: #fff !important;
  font-weight: 600 !important;
}
.policyTableCss td.MuiTableCell-root.MuiTableCell-body {
  display: table-cell;
  padding: 16px !important;
  font-size: 12px !important;
  text-align: left !important;
}

@media (max-width: 600px) {
  td.MuiTableCell-root.MuiTableCell-body::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 8px;
    text-align: left;
    font-size: 16px;
    font-weight: bold;
  }
}

.transformText {
  text-transform: capitalize;
}

.detailDivp {
  font-size: 12px;
  color: #6d6e71;
  padding: 0px;
  margin: 0px;
}
