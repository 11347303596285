.root {
  width: 100%;
  max-height: 344px;
  min-height: 344px;

  /* overflow: auto; */
}

.whats-new {
  display: flex;
  height: 344px;
  max-height: 344px;
  min-height: 344px;
}
.list-item {
  display: block !important;
  background: transparent;
  min-height: 68px;
  max-height: 68px;
  max-width: 100%;
  border-bottom: 0 !important;
  padding: 12px 35px 16px 35px !important;
}

nav.MuiList-root.MuiList-padding {
  padding: 0px;
}

.list-item-text {
  width: 50%;
}

.custome-scrollBar {
  width: 110% !important;
  height: calc(100%) !important;
  color: white;
  /* background: black; */
}

.custome-scrollBar > div:nth-child(1) {
  overflow-x: hidden !important;
}
.custome-scrollBar > div:nth-child(2) {
  display: none !important;
}

.custome-scrollBar > div:nth-child(3) {
  left: 8px !important;
  z-index: 3;
}

.custome-scrollBar > div:nth-child(3) > div {
  /* height: 130px !important; */
  background-color: #e8ba44 !important;
  display: none !important;
}

.custome-scrollBar:hover > div:nth-child(3) > div {
  display: block !important;
}

/* Solid border */
hr.solid {
  border-top: 3px solid #bbb;
}

.image-container {
  /* width: 722px; */
  /* margin: -8px 0px; */
  /* position: absolute; */
  height: 344px;
  z-index: 1;
}

.image-container > img {
  width: 100%;
  height: 100%;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgb(0 0 0 / 0%);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.9s;
  -webkit-transition-property: transform;
  transition-property: transform;
  /* clip-path: polygon(0% 0%, 89% 0, 100% 17%, 100% 100%, 0% 100%); */
}

.image-container > img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.list-tag {
  display: flex;
  justify-content: flex-start;
}

.list-tag-text {
  height: 20px;
  flex-grow: 0;
  
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #f3901d;
}

.red-parallelogram {
  width: 8px;
  height: 7px;
  margin: 6px 8px 7px 0px;
  /* flex-grow: 0;
  clip-path: polygon(40% 0%, 92% 0%, 50% 100%, 0% 100%); */
  background: url(./../../../Assets/Images/WhatsNewIcons/Rectangle_orange.svg);
}

.listDescription {
  display: flex;
  justify-content: space-between;
  /* padding-right: 24px; */
}

.list-description-text {
  
  font-size: 14px;
  height: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
}

.arrow {
  display: none;
  width: 120px;
  height: 24px;
  flex-grow: 0;
  
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  margin: -10px 0px;
}

.MuiButtonBase-root.MuiListItem-root.list-item.MuiListItem-gutters.MuiListItem-button.Mui-selected {
  background: #faf9f9;
  box-shadow: 3px 20px 52px 0 rgb(255 255 255 / 20%);
}

.MuiButtonBase-root.MuiListItem-root.list-item.MuiListItem-gutters.MuiListItem-button.Mui-selected
  > div
  > div
  > div:first-child
  > div:first-child {
  background: url(./../../../Assets/Images/WhatsNewIcons/Rectangle_red.svg);
}

.MuiButtonBase-root.MuiListItem-root.list-item.MuiListItem-gutters.MuiListItem-button.Mui-selected
  > div
  > div
  > div:nth-child(2)
  > div:nth-child(1) {
  height: 20px;
  width: 100%;
  opacity: 0.8;
  
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
}

.MuiButtonBase-root.MuiListItem-root.list-item.MuiListItem-gutters.MuiListItem-button.Mui-selected
  > div
  > div
  > div:nth-child(2)
  > div:nth-child(2) {
  padding: 0px;
  margin: -10px 0px;
}

.MuiButtonBase-root.MuiListItem-root.list-item.MuiListItem-gutters.MuiListItem-button.Mui-selected {
  /* background: #faf9f9; */
  color: #242424;
}

.MuiButtonBase-root.MuiListItem-root.list-item.MuiListItem-gutters.MuiListItem-button.Mui-selected
  > div
  > div
  > div
  > div:nth-child(2) {
  color: #e31837;
  display: block;
}

/* .MuiButtonBase-root.MuiListItem-root.list-item.MuiListItem-gutters.MuiListItem-button.Mui-selected
  > div
  > div
  > div
  > div:nth-child(1) {
  background: #e31837;
} */

.MuiButtonBase-root.MuiListItem-root.list-item.MuiListItem-gutters.MuiListItem-button.Mui-selected
  > div
  > div
  > div:nth-child(2) {
  font-weight: 600;
  color: #242424;
}

.list-description-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.learnMore-txt {
  color: #e31837 !important;
}

.learnMoreBtn {
  
  padding: 8px 16px;
  font-size: 14px;
  color: #e31837 !important;
  display: flex;
}

.expandIcon {
  display: none;
}

.mobileViewContainer {
  display: none;
}

.desktopImgContainer {
  display: block;
  clip-path: polygon(0% 0%, 0% 0, 100% 0%, 100% 100%, 0% 100%);
}

.viewMoreContainer {
  height: 48px;
  background-color: #242424;
  position: relative;
  z-index: 1;
  width: calc(42% - 2px);
  left: 0px;
}

.viewMoreBtn {
  background-color: #e31837 !important;
  width: 174px;
  font-size: 12px !important;
  line-height: 2 !important;
  color: white !important;
  height: inherit;
  border-radius: inherit !important;
  float: right;
  
}

.testClass {
  position: relative;
}

.listNav {
  width: 48%;
  position: absolute;
  min-height: 100%;
  z-index: 1;
  /* background: #161616; */
  background: url(./../../../Assets/Images/slant.png) no-repeat #161616;
  background-position: -54.5% 100%;
  /* background-repeat: no-repeat; */
  background-attachment: fixed;
}

.compactContainer {
  width: 94.5%;
  float: right;
}

.bgimg {
  width: 100%;
  height: 100%;
}

.listContainer {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

.listNav .Mui-selected:after {
  width: 26px;
  height: 68px;
  position: absolute;
  content: "";
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAAC0CAMAAAAeqqqkAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAA9lBMVEUAAAD7+vrv7u759/ft7Oz49/fg3d36+fn19PTu7e3o5ube29v08/Ps6ur39vby8fHq6Oj49vbl4uL5+Pjp5+fh3t729fX6+Pjx8PDn5OTg3Nzu7Ozm4+Pt6+vk4eH18/P08vLj4ODz8vLw7u7p5ubf3Nzv7e3e2trx7+/w7+/r6Ojj39/h3Nzl4uH39fXd2dnz8fHq5+fk4OD39vXn5eXf29v7+fni39/r6env7Ozi3t7j4eHg29vs6enh3t329PTe29rn4+Pg3dzn5OPy8PDg3t7m5OTd2tnk4uHd2trl4+Pw7+749/bg3tzq6Of08/Lo5eX///8NSGM1AAAAAXRSTlMAQObYZgAAAAFiS0dEUZRpfCoAAAAHdElNRQflCAUGEgIfhPYvAAAAAW9yTlQBz6J3mgAAAzRJREFUaN6t2ol20zAQBVA/jJhAWjdFkJRuBFLKbsLSACml7PvS//8aeprSuLEtjaSXD7jHGudI1szLgAsZ4QfkFykMzCUKIx0KA7lMYWCuUBiYLoWRJQoDWaYwqeX5z0hBYSArFAbocZjVqxQmpTyoOvHlqTKw1ygMiusUBn0OM1ijMLA3KAz66xRGospTY2A3KAxkk8LEvPUmZhC+pTYxsFsUBnabwuAmh5EhhQktTxsj5haDAW5zGBtSHjicHoUJ+QxzMaL/97gY2BGFgd2hMFLcYTCA8qTwMXaXwsCoDmQvI527DAa4x2Gs4o6jYGD8W6qGURykGgZyn8LAPKAwyB9SGN/VTclANiiMpzxqRlYfMRj3QapngC0O47i6hTBSdBmMozxBTPvNNoxB8ZjCoHxCYWRMYWCfUhiUzyiMPKcwkBGFgexQGBTrFKa+pUYxkF0KA/uCwqCzR2EWumGxzMJnWDQzMZsMBljaozDVm20CA7tMYZBvU5hJh8LArlGYs6tbIiPFSwYDrHCY2dUtmUH+isKcHIDpjH3NYGYN2WSmM2Uwp+3GRGUwYvyLz/oGaUx/ymDm3zpJhZlfZlMKU1L24mKfwZxrNsUvaUw5w4s3DKY431SOfZgDytfWYm8xTinfMpi81hqKepj6pTNGGWYMxkwZjGm6CYUvqbGDF6yUXQZj9jMCYw4yBtPW+Q0rzOE7AiPmfUZgHCOMEGacERjpf2Aw9mNGYOynjMCIeySjVCaeobJ2SZ7eqlLxzQl0zDAjMBP/5Ey1JH9yRPOuFfMlBaMJC/mfRZXS8BdGNR73PkypUbxM5zODsV9Uim92p02quQujHtM7mf6UwQQEYBzKwDsH0jBB8Y525rT7kMiEJYNalzQOUVqTC8VXBlN+C1JaGOmFKc1MYGFaGOkrp+BuJiL71/QwEUnEBiUoztHKmODCNDFxocjakuISrLV3HaUsMiYyqbeg9OKUBUYXmvAwCUHjqmLiE54VRvFRpWFS0q/zJR1+ZzD5jwTljLGaJImXCd82mxh1eMjNxIdnq4z9majMmMTCzBj59ZvB2D/JyjEjf9OVYyY1Z3/yOzpiKP8AoVw5P4PEJRUAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjEtMDgtMDVUMDY6MTg6MDIrMDA6MDCNb5NhAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIxLTA4LTA1VDA2OjE4OjAyKzAwOjAw/DIr3QAAAABJRU5ErkJggg==);
  right: -26px;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.arrow .Mui-selected:after {
  margin: 5px -50px 0px 50px;
}

.mCampaign {
  height: 344px;
  /* z-index: 2; */
  top: 0;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.pdfStyle {
  color: #ffffff;
  height: 1.5em !important;
  width: 2em !important;
  /* padding: 0.5em; */
  /* margin-bottom: 10px; */
  z-index: 999;
  bottom: 20px;
  right: 12px;
  position: absolute;
}

.pdfStyle .MuiSvgIcon-root {
  width: 1.5em;
  height: 1.5em;
  transition: All cubic-bezier(0.165, 0.84, 0.44, 1) 0.7s;
}

.pdfStyle .MuiSvgIcon-root:hover {
  transform: scale(1.2);
  transition: All cubic-bezier(0.165, 0.84, 0.44, 1) 0.7s;
}

.liveIcon {
  left: 5%;
  top: 5%;
  transform: translate(-10%, -10%);
}

.livePrfileSection {
  right: 2%;
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
}

.personImg {
  height: 50%;
  padding-top: 10px;
}

.profileImgWrapper {
  z-index: 2;
  height: 90%;
}

.profileImg {
  z-index: 2;
  height: inherit;
}

.personName {
  background: #e31837;
  height: 90%;
  z-index: 1;
  width: 80%;
  margin: 0 5px;
  bottom: 8px;
  padding: 0px 0px 1px 17px;
  display: flex;
  align-items: flex-end;
  
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  color: #fff;
  /* clip-path: polygon(0 0, 100% 0%, 82% 100%, 0% 100%); */
  clip-path: polygon(0% 0%, 100% 0, 100% 60%, 86% 100%, 0% 100%);
}

.centerAlign {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

/* .MuiDialog-paperWidthSm {
  width: 70%;
  height: 488px;
  max-width: initial !important;
} */

/* .MuiCard-root {
  height: 100% !important;
  width: 100%;
} */

.videoPlayer {
  height: 100% !important;
  width: 100%;
}

.videoPlayerCardArea {
  height: 100% !important;
  width: 100%;
}

.fullWidth {
  width: 100%;
}
.halfWidth {
  width: 50%;
}

.posRelative {
  position: relative;
}

.posAbsolute {
  position: absolute;
}

.pointer {
  cursor: pointer;
}

.padding1 {
  padding: 1rem 0 0 2.5rem;
}

.campaignDetail {
  background: #242424;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  padding: 16px;
}

.campaignHeader {
  
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: right;
  color: #fff;
  height: 24px;
}

.campaignLink {
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.4px;
  text-align: left;
  color: #fff;
  height: 16px;
}

.campaignWrapper {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.mySevaLogoContainer {
  height: 46px;
}
.mySevaLogo {
  height: 46px;
}

.campaignLink a {
  color: #fff;
}

.borderBottom {
  width: 100%;
  height: 1px;
  flex-grow: 0;
  background-color: rgba(255, 255, 255, 0.24);
}

.playButton {
  height: 130px;
}

@media screen and (max-width: 767px) {
  .whats-new-container {
    height: 770px;
  }

  .whats-new {
    height: 772px;
    max-height: 772px;
  }

  .image-container {
    width: auto;
    position: relative;
    height: auto;
    margin: 0;
  }

  .image-container > img:hover {
    -webkit-transform: none;
    transform: none;
  }

  .list-item {
    padding: 8px 8px 23px 8px !important;
    max-height: 100%;
    border-bottom: 0 !important;
  }

  .desktopImgContainer {
    display: none;
  }

  .mobileViewContainer {
    display: block;
    position: relative;
  }

  .listNav {
    width: 90%;
    background: #161616;
    background-image: none;
  }
  .listNav .Mui-selected:after {
    display: none;
  }
  .arrow {
    width: 100%;
    text-align: left;
    margin: 5px 0px !important;
    position: relative;
    z-index: 10;
  }
  .expandIcon {
    display: none;
    padding-top: 8px;
    height: 24px;
    width: 24px;
  }

  .learnMoreBtn {
    display: none;
  }

  .expandLess {
    color: #e31837;
  }

  .viewMoreContainer {
    width: 99%;
    left: 0px;
  }

  .viewMoreBtn {
    width: 100%;
  }

  /* .list-description-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    height: 3em;
    -webkit-box-orient: vertical;
  } */
  .MuiButtonBase-root.MuiListItem-root.list-item.MuiListItem-gutters.MuiListItem-button.Mui-selected {
    background: #161616;
  }
  .MuiButtonBase-root.MuiListItem-root.list-item.MuiListItem-gutters.MuiListItem-button.Mui-selected
    > div {
    background: #fbfafa;
    padding: 0;
  }

  .custome-scrollBar {
    height: 95% !important;
  }

  .custome-scrollBar > div:nth-child(3) {
    display: none;
  }

  .Mui-selected > div > div:first-child {
    padding: 16px;
  }

  .listArea {
    padding: 16px 16px 0 8px;
  }

  .list-description-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    min-height: auto;
    width: 100%;
    height: auto;
    flex-grow: 0;
    font-size: 14px;
    line-height: 1.71;
  }

  .Mui-selected .list-description-text {
    -webkit-line-clamp: 2;
    min-height: 48px;
    height: 48px;
    width: 100% !important;
    flex-grow: 0;
    opacity: 0.8 !important;
    font-size: 14px !important;
    line-height: 1.71 !important;
  }

  .Mui-selected .learnMore-txt {
    height: 24px;
    flex-grow: 0;
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 1.71 !important;
    opacity: 1 !important;
  }

  .borderBottom {
    width: calc(100% - 1rem);
    margin: 0px 6px 0 8px;
  }

  .mCampaign {
    height: 100%;
    /* top: 89px; */
    /* right: 24px; */
    width: 100%;
    position: absolute;
  }

  .campaignWrapper {
    padding: 0px;
  }

  .pdfStyle {
    height: 1em !important;
    width: 1em !important;
  }

  .centerAlign {
    height: 90px;
  }

  .liveIcon {
    width: 50px;
  }

  .livePrfileSection {
    width: 24%;
  }
  .profileImgWrapper {
    height: 82%;
    width: 100%;
  }
  .personName {
    height: 80%;
    width: 87%;
    padding: 0px;
  }
  .personNameStyle {
    height: 26px;
    font-size: 11px;
    
    width: 80%;
    padding-left: 8px;
  }
  .personImg {
    padding: 0px;
  }
  .pdfStyle .MuiSvgIcon-root {
    width: 1em;
    height: 1em;
  }

  .mySevaLogoContainer {
    height: 30px;
  }
  .mySevaLogo {
    height: 30px;
  }
  .campaignDetail {
    height: 30px;
  }
  .campaignHeader {
    font-size: 12px;
  }
  .campaignLink {
    font-size: 10px;
  }

  .red-parallelogram {
    margin: 4px 8px 5px 0;
  }

  .list-tag-text {
    height: 16px;
    line-height: 1.6;
  }

  .editIcon {
    display: none;
  }

  .listDescription {
    display: initial;
    justify-content: space-between;
    padding-right: 11px;
  }

  .MuiCardMedia-media {
    height: initial !important;
  }
}

@media screen and (max-width: 560px) {
  .centerAlign {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 90px;
    height: 90px;
    z-index: 1;
    transform: initial;
  }
  .image-container img {
    width: 100%;
}
}

.whats-new-thumbail-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.whats-new-thumbail {
  height: 21.5rem;
  margin: auto;
  /* position: absolute; */
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
