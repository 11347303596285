.alignMCAdmin {
  margin-left: 0 !important;
  margin-top: 0 !important;
}
/* .MCAdminTabs {
  margin-top: 34px;
  padding-top: 16px;
} */
.MCAdminTabAlign {
  background-color: #faf9f9;
  padding: 0 24px 0 24px;
}
.MCalignTabContent > div > div > .MuiTab-root > .MuiTab-wrapper {
  font-size: 14px !important;
}
