.landingpageAlign2 {
  padding: 0 1.5em 1.5em 1.5em;
  box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12) !important;
  box-shadow: none !important;
}
.commentPaper {
  padding: 0 1.5em 0 1.5em;
  border: solid 1px #dcdcdc;
  background-color: #faf9f9 !important;
  padding: 24px;
  box-shadow: none !important;
}
.iconWidth {
  width: fit-content !important;
}

.contentpanel {
  overflow: auto;
  margin-bottom: 30px;
}
.attachmentMB {
  margin-bottom: 40px !important;
  margin-top: 0px;
}
.attachmentTitle {
  font-size: 14px;
  font-weight: 600;
  
}
.paperH {
  position: relative;
}
.attachmentMB span.material-icons.MuiIcon-root.pointer {
  height: auto !important;
}
.alignASocialIcon1 {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}
.alignASocialIcon2 {
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: center;
}
.repliesVerticalLine {
  height: auto;
  border-left: 3px solid #ededed;
}
.reply {
  font-weight: 600;
  color: #e31837 !important;
  font-size: 12px !important;
  line-height: 1.67;
}
.commentBox {
  height: 126px;
  padding-bottom: 32px !important;
  padding: 1em;
  box-shadow: none !important;
}

.commentTitle {
  margin-top: 40px !important;
  margin-bottom: -19px !important;
  font-size: 14px;
  font-weight: 600;
}
.articleCount {
  position: relative;
  right: 16px;
  bottom: -10px;
  color: #a7a9ac;
  font-size: 12px;
  font-weight: 600;
}
.nameDateMbMt {
  margin-block-start: 16px !important;
  margin-block-end: 12px !important;
}
  /* .commentMbMt {
    margin-block-start: 0px !important;
    margin-block-end: 18px !important;
    font-size: 14px !important;
    margin-left: 8px !important;
    word-break: break-all;
    
  } */
/* @media (min-width: 1024px) {
.commentMbMt {
  width: 660px;
}
} */
.nameSize {
  font-size: 16px;
  line-height: 1.5;
}
.dateSize {
  margin-top: 18px !important;
  font-size: 14px;
  line-height: 1.43;
  color: #6d6e71;
  margin-left: 4px !important;
}
.likeCountMbMt {
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
}
.popperInnerLinksContainer {
  display: flex;
  text-decoration: none;
  color: #000;
  align-items: center;
}
.popperInnerTextMl {
  margin-left: 5px;
}
.countSize {
  font-size: 12px !important;
  margin-top: 4px !important;
  font-weight: 600;
  color: #e31837 !important;
}
.textFieldWidth {
  padding-left: 8px !important;
  width: 70vw !important;
  height: 54px !important;
}
input#outlined-basic {
  
}
.mlP {
  margin-left: 24px;
  cursor: pointer;
  font-size: 12px;
  margin-top: 2px !important;
}
.pLZero {
  padding-left: 0.2em !important;
}
.cRed {
  color: #e31837 !important;
  /* margin-left: 8px !important; */
  margin-top: 6px !important;
}
.mlimg {
  margin-left: 0.8em !important;
}
.mrAvatar {
  margin-top: 0.6em;
}
.mt {
  margin-top: 1em !important;
}
.businessTypeIcon > div > div > div > fieldset {
  border-color: rgba(0, 0, 0, 0) !important;
}
.calenderTypeIcon > div > div > div > fieldset {
  border-color: rgba(0, 0, 0, 0) !important;
}
.shortingTypeIcon > div > div > div > fieldset {
  border-color: rgba(0, 0, 0, 0) !important;
}
.pR {
  padding-right: 0.3em !important;
  padding-top: 0px !important;
}
.flex {
  display: flex;
}
.formWH {
  height: 125px;
  width: 60vw;
}
.commentDivReply {
  padding: 1em;
  box-shadow: none !important;
  height: 126px;
}
.pzero {
  margin-block-start: 0em !important;
}
.textAreaSize {
  
  font-size: 14px;
  font-weight: normal;
  height: 80px !important;
  width: 99.5% !important;
  border-color: #fff !important;
  overflow-y: auto !important;
  resize: none !important;
}
.textAreaSize:focus-visible {
  outline: none !important;
}
.Amtmb {
  margin-bottom: 8px;
  padding-top: 10px;
}
/* .articleRightP {
  padding-right: 120px !important;
  padding-left: 30px !important;
} */
.articleAvatarName {
  
  font-size: 16px;
  font-weight: 500;
}
.alignSocialIcons {
  background: #fff;
  padding: 12px;
  cursor: pointer;
}
.articleCancleButton {
  width: 93px;
  height: 48px;
  
  font-size: 12px !important;
  font-weight: 600 !important;
  border: 1px solid #e31837 !important;
}

.articleSubmitButton {
  width: 108px;
  height: 48px;
  
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #fff !important;
  background-color: #e31837 !important;
  box-shadow: none !important;
}
/* .MuiButton-containedSecondary {
  background-color: #e31837 !important;
} */
.alignMCArticals {
  margin-top: 1.5em !important;
  margin-bottom: 48px !important;
}
.fontFamilyArticle2 {
  
  box-shadow: none !important;
}

.article2ContentTitle {
  font-size: 26px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 30px;
    margin-bottom: 20px;
}
.buttonFont {
  
  height: 32px !important;
  border: solid 1px #e31837 !important;
  background-color: rgba(227, 24, 55, 0.04) !important;
  font-size: 12px !important;
  color: #e31837 !important;
  max-width: auto !important;
}
.MuiButton-outlinedSecondary {
  color: #e31837 !important;
}
.article2ContentSubTitle {
  font-size: 1.25rem;
  color: #6d6e71;
  margin-block-start: 0em;
  margin-block-end: 16px;
}

.avatarDetailsAlignment {
  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  column-gap: 16px;
}
.avatar {
  width: 40px;
  height: 40px;
  margin-right: 8px;
  border-radius: 50%;
  background: #858585;
}
.avatarComment {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.avatarDate {
  font-size: 0.9rem;
  color: #6d6e71;
}

.avatarNameDate > p {
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.ViewURL {
  color: #e31837;
  font-size: 12px;
  text-transform: uppercase;
  float: right;
  padding-top: 10px;
  padding-right: 13px;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.imgpanelleft {
  position: relative;
  float: left;
  margin-right: 20px;
}

.imgpanelleft.oc-pic {
  float: none;
}

.imgpanelleft.oc-pic img {
  width: 100%;
}

.articlePic {
  width: 350px;
  /* object-fit: contain; */
  /* height: 380px; */
  background-size: cover;
  /* float: left;
  margin-right: 20px; */
}

.multiIcon {
  position: absolute;

  right: 75vh;
  top: 45vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iconDiv {
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5em;
}
.contentDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.contentDivLeft {
  width: 60%;
}
.imgDivRight {
  width: 30%;
  height: 20em;
}

.imgBottomWidth {
  width: 60%;
}

.bottomDivTitle {
  margin-block-start: 1em;
  margin-block-end: 0.1em;
  color: #e31837;
}
.articleImgDivContainer {
  position: relative;
}
.bottomDivSubTitle {
  margin-block-start: 0em;
  margin-block-end: 0.1em;
}
.alignArticalTopDetails {
  align-items: center;
}
.article02 {
  width: 82%;
}
.articleZoomInIconImg {
  position: absolute;
  top: 2px;
  right: 2px;
  fill: #fff !important;
  cursor: pointer;
  background-color: rgba(220, 220, 220, 0.2);
}
.articleZoomImageModal .modalBody {
  max-height: 500px !important;
}
.tagStyle {
  height: 32px;
  flex-grow: 0;
  margin: 14px 16px 0px 0px;
  padding: 1px 16px;
  border: solid 1px #e31837;
  background-color: rgba(227, 24, 55, 0.04);
}

.tagText {
  height: 20px;
  flex-grow: 0;
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.67;
  letter-spacing: normal;
  text-align: left;
  color: #e31837;
}

.alignMCArticals p {
  margin: 0px;
}

.alignMCArticals p img {
  /* width: auto; */
  max-width: 100%;
}

.mc2Profle {
  width: 50px;
  height: 50px;
  padding-bottom: 2px;
  object-fit: cover;
}
.pdfIcon {
  width: 24px;
  height: 24px;
}

.defaulticon {
  padding: 7px;
}

.mainimg {
  height: 200px;
  background-size: contain !important;
  /* background: #000; */
  margin-bottom: 20px;
}

.leftfixedbox {
  position: fixed;
  left: 30px;
  top: 30%;
  z-index: 10;
}

.shareoptpanel
{width: 226px;
  height: 96px;
  position: relative;
  left: 89px;
    top: -47px;
  box-shadow: none}

  

@media (max-width: 960px) {
  .leftfixedbox {
    flex-direction: row !important;
    position: fixed;
    top: 90px;
    width: 100% !important;
    left: 0;
    background: #fff;
    /* align-items: center; */
    justify-content: center;
}
  .alignMCArticals {
    margin-top: 0 !important;
  }
  .mblHideIcons {
    display: none !important;
  }
  .alignASocialIcon1 {
    height: 0;
  }
  .avatarDetailsAlignment {
    margin-right: 15px;
    column-gap: 5px;
}
}
@media (max-width: 960px) {
.articlePic {
  width: 100%;
  background-size: cover;
  float: none;
  margin-right: 0px;
  margin-bottom: 15px;
}
.contentpanel {
  overflow: hidden;
  
}
.alignMCArticals p {
  margin: 0px;
  word-break: break-word;
}
} 
@media (max-width: 560px) {
.commentPaper {
  padding: 3px;
  
}
.leftfixedbox {
  top: 48px;
}

.alignMCArticals {
  margin-top: 45px !important;
}
.article2ContentTitle {
  word-break: break-word;
  margin: 16px 0 0;
  
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
}
.shareoptpanel {
  left: 0px;
  top: 0px;
}
.leftfixedbox div {
  width: 20%;
  justify-content: center;
}
.articleRightP {
  padding: 0px !important;
}

.righthead {
  display: block !important;
}
}
