.GenieIcon {
  bottom: 100px;
  width: 80px;
  height: 80px;
  right: 30px;
  position: fixed !important;
  z-index: 999;
  cursor: pointer;
  box-shadow: 1px 1px 81px 2px rgb(0 0 0 / 42%);
  -webkit-box-shadow: 1px 1px 81px 2px rgb(0 0 0 / 42%);
  -moz-box-shadow: 1px 1px 81px 2px rgba(0, 0, 0, 0.42);
  border-radius: 50%;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(219, 35, 58, 0.3), 0 0 0 0 rgba(219, 35, 58, 0.3);
  }
  40% {
    box-shadow: 0 0 0 35px rgba(219, 35, 58, 0), 0 0 0 0 rgba(219, 35, 58, 0.3);
  }
  80% {
    box-shadow: 0 0 0 35px rgba(219, 35, 58, 0), 0 0 0 20px rgba(219, 35, 58, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(219, 35, 58, 0), 0 0 0 20px rgba(219, 35, 58, 0);
  }
}

.GenieIcon:before,
.GenieIcon:after {
  content: "";
  display: block;
  position: absolute;
  width: 80px;
  height: 80px;
  border: 50%;
  left: 0px;
  right: 26px;
  top: -1px;
  bottom: 44px;
  border-radius: 50%;
  animation: pulse 1.2s linear 5;
  animation-delay: 1.5s;
  backface-visibility: hidden;
}

.gPosition {
  position: relative;
}

.gLogo {
  position: absolute;
  left: 37%;
  top: 26%;
  width: 60px !important;
}

.notranslate > div {
  border: 1px solid lightgrey !important;
  height: 400px !important;
}

.genieCard {
  bottom: 105px;
  right: 70px;
  position: fixed !important;
  z-index: 998;
  height: 64px;
  width: 165px;
  opacity: 0;
  animation: cardAnimation 5.5s linear 1 !important;
  animation-delay: 2s !important;
  animation-fill-mode: forwards !important;
}

.genieCard .MuiCardContent-root {
    padding: 10px 16px !important;
    /* text-align: center; */
}

@keyframes cardAnimation {
  0% {
    opacity: 0;
    right: 70px;
  }
  15% {
    opacity: 1;
    right: 70px;
  }
  80% {
    opacity: 1;
    right: 70px;
  }
  95% {
    opacity: 0;
    right: 70px;
  }
  100% {
    opacity: 0;
    right: -100px;
  }
}

.name {
  font-size: 16px !important;
  
  font-weight: 600;
}

.intention {
  font-size: 14px;
  
  color: #242424;
}

.sImg {
  width: 16px !important;
  height: 16px !important;
}

.dropS {
  border-color: #fff !important;
}

.hoverS:hover {
  font-weight: 600;
  background-color: rgba(236, 236, 236, 0.5);
}
.contentT {
  font-size: 12px;
  color: #7c3520;
  font-weight: 500;
  margin-left: 4px;
}
.contentS,
.contentS1 {
  font-size: 14px;
  line-height: 1.57;
  font-weight: 600;
  color: #242424;
  margin-left: 20px;
  width: 372px !important;
  height: auto !important;
  word-wrap: break-word;
}
.contentS1 {
  margin-left: 4px !important;
  margin-top: -3px !important;
  word-break: break-all;
}
.btnS1 {
  margin-top: -3px !important;
  margin-left: -65px !important;
}
.sFooter,
.sFooter1 {
  padding: 10px 16px 0px 16px !important;
  text-align: right;
}
.sFooter1 {
  padding-top: 0px !important;
}
.btnS {
  color: #e31837 !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 2 !important;
  margin-left: 16px !important;
}
.footerSC {
  color: #333;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.83;
  cursor: pointer;
}
.footerSC svg.MuiSvgIcon-root {
  margin-bottom: -4px;
  width: 16px;
  height: 16px;
  color: #a7a9ac;
}
