.sitePaper .MuiPaper-outlined {
    border: 0px solid #fff !important ;
}
.siteTitle {
    border: solid 1px #dcdcdc !important;
    background-color: #faf9f9 !important;
}

.siteFF {
    
}
.cardTitleS {
    font-size: 24px;
    font-weight: 500;
}
.cardTitleSMobile {
    font-size: 16px;
    font-weight: 600;
}


.cardTitleSub1 {
    font-size: 14px;
    font-weight: 600;
    text-decoration: none !important;
    color: #000 !important;
    margin-left: -9px !important;
}
.textColorWhenNull{
    font-size: 16px;
    font-weight: normal;
}
.cardTitleSub2 {
    font-size: 16px;
    text-decoration: none !important;
    color: #000 !important;
}

.circle {
    display: list-item;
}

.siteCrump {
    color: #e31837;
    font-size: 14px;
    
    font-weight: 500 ;
    line-height: 1.57;

    align-items: center;
    display: flex;
}
.visibleB{
    display: none !important;
}
.mlCB {
    margin-left: 34px;
}

.iconsC {
    font-size: 24px !important;
    margin-left: 3px ;
    font-weight: 600 !important;
}

.mbT {
    margin-bottom: 38px !important;
}

.siteFF .MuiCardContent-root {
    padding: 18px !important;
}
.Mui-expanded .siteMapAccordianArrow{
    color: #e31837 !important;
}
.siteMapOuterContainer .MuiPaper-elevation1 {
    box-shadow: none !important;
}

@media (max-width: 600px){
    .cardTitleSub2 {
        margin-bottom: -8px;
    }
    .siteMapCardMobile{
        margin-bottom: -10px !important;
    }
}