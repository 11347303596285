.approvalPH {
    height: 66.6vh !important;
}
.mbACard {
    margin-bottom: 30px;
}
.policiesTableNameA {
    flex: 0.1143 1;
}

.policiesTableNameheader {
    flex: 0.135 1;
}
.policiesTableNameAs {
    flex: 0.11 1;
}

.textArea {
    border: solid 1px #e4e4e4;
    background-color: #fbfbfb;
    /* height: 10em !important; */
    /* width: 60em !important; */
    resize: none;
    /* padding: 1.5em 0 0 1.5em; */
    
    font-size: 0.9rem;
    font-weight: normal;
    line-height: 1.57;
    color: #000;
    height: 100%;
    /* height: 400px !important; */
}

.quotepanel .textArea {
    height: 10em !important;
    width: 60em !important;
}

/* .quotepanel .modalBody
{
padding: 15px;
} */

.steppanel
{display: flex;margin-bottom: 20px;}

.Step-1 {
    width: 70px;
   
    
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color:#000;
  }

  .Download-csv {
    
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color:#000;
  }

  .Engage {
    /* margin: 16px 0px 32px 0px; */
    /* margin-top: 15px; */
    
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #e31837;
    background: none;
    border: 0px;
    padding: 0px;
  }
  

.textAreaDiv {
    position: relative;
}

.charsLeftTexts {
    position: relative;
    top: 22vh;
    right: 5.8vh;
}

.apM {
    width: 900px !important;
    height: 400px !important;
}

.textAreaRA {
    height: 185px !important;
    width: 835px !important;
    overflow: hidden !important;
    margin-left: 24px !important;
    margin-bottom: 34px !important;
    resize: none !important;
    padding: 8px;
    background-color: #fbfbfb;
    border-color: #e4e4e4;
    font-size: 14px !important;
    
    color: #6d6e71 !important;
    line-height: 1.57;
}
.imgAppRej{
    cursor: pointer;
}