.policiesSearchWidth {
  width: 400px !important;
  float: right;
}
.totlaPoliciesCount {
  
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000000;
  margin-bottom: 16px;
}
.highlightCount {
  font-weight: 500;
}
.slidingArrow {
  /* position: absolute;
  left: -80px; */
  position: fixed;
  left: 0;
  top: 176px;
  width: 24px;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;
}
.iconArrow {
  background-color: #db233a;
  height: 56px;
  display: flex;
  align-items: center;
  width: 24px;
}
.policyCountTable {
  position: relative;
}

@media (max-width: 600px) {
  .policiesSearchWidth {
    width: -webkit-fill-available !important;
  }
}
