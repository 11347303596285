.alignedEnd {
  text-align: end;
  height: 48px;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0)
    ),
    linear-gradient(to left, #333, #333);
}

.alignedEnd .modalCloseIcon {
  margin: 8px 0px;
}

.iconStyle {
  padding: 5px 10px 0px 0px;
}

.modalHeader {
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0)
    ),
    linear-gradient(to left, #333, #333);
  color: #fff;
  
  align-items: center;
  /* margin-left: 24px; */
}
.headerContent {
  display: flex;
  font-size: 1.2rem;
  margin-left: 24px;
  align-items: center;
}
.cursorP {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}
.modalFooter {
  background-color: #faf9f9;
  position: sticky;
  bottom: 0px;
  z-index: 2;
}
.commonButtonStyle {
  background-color: #e31837 !important;
}
.alignModalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}
.showErrorinLine {
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.showErrorinLineOrg {
  display: flex;
  column-gap: 8px;
}
.errorOrgFooter {
  background-color: #f6eced !important;
}
.alertOrgTitle {
  
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #e31837;
}
.alertOrgSubTitle {
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  color: #e31837;
  margin-top: 2px;
}
.modalError {
  /* margin: 31px 16px 10px 70px; */
  
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #000000;
}
.FooterContent {
  float: right;
  display: flex;
  column-gap: 24px;
  align-items: center;
}
.errorOrgFooter .FooterContent .commanButton > button {
  width: 220px !important;
}
.modalButton {
  background-color: #e31837 !important;
  
  font-size: 12px;
  font-weight: 600 !important;
  line-height: 1.83;
  color: #fff;
  padding: 13px 24px 13px 24px !important;
  margin: 16px 24px 16px 0 !important;
}
.fevClearAll {
  
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.errorTextFevSection {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
.verticalBarFev {
  width: 1px;
  height: 24px;
  background-color: #242424;
}
.policiesBtn {
  background-color: #e31837 !important;
  
  font-size: 12px;
  font-weight: 600 !important;
  line-height: 1.83;
  color: #fff;
  padding: 13px 24px 13px 24px !important;
  margin: 24px 24px 24px 0 !important;
}
.modalBackButton {
  flex-grow: 0;
  
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #e31837 !important;
  border: 1px solid #e31837 !important;
  background-color: #ffffff !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  box-shadow: none !important;
}
.EditBannerFooter {
  width: 153px !important;
}
.postionTopUniversalSearch > div > div {
  position: absolute !important;
  top: 0 !important;
  background: transparent !important;
  margin-top: 12px;
  overflow: hidden;
  box-shadow: none !important;
}
.modalBody
  > div
  > div
  > div
  > div
  > div
  > .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 6px !important;
}

.modalBody {
  /* padding-top: 16px; */
  
 
  overflow-y: auto !important;
  background-color: #f7f6f6;
}

.modalBodyBg {
  background-color: #f7f6f6;
}

.addingTop5 {
  padding-top: 0px !important;
}

.videoPlayerStyle > div:nth-child(3) > div {
  width: 70%;
  height: 445px;
  max-width: initial !important;
}

.videoPlayerStyle > div:nth-child(3) > div > div {
  height: inherit !important;
}

.pdfViewerStyle > div:nth-child(3) > div {
  width: 70%;
  /* height: 100%; */
  max-width: initial !important;
}

.pdfViewerStyle > div:nth-child(3) > div > div {
  height: 100%;
}

.pdfViewerStyle > div:nth-child(3) > div > div > .modalBody {
  height: calc(100% - 54px);
}

.warningIconModal {
  width: 24px;
  height: 24px;
}

.videoHeight {
  height: calc(100% - 50px);
}

.pdfViewerContainer > iframe {
  width: 100%;
}

.padTop0 {
  padding-top: 0px !important;
}

@media screen and (max-width: 768px) {
  .videoPlayerStyle > div:nth-child(3) > div {
    width: 90%;
    height: 280px;
  }

  .pdfViewerStyle > div:nth-child(3) > div {
    width: 90%;
    height: 100%;
    max-width: initial !important;
  }
}
.modalTitleIcon {
  width: 24px;
  height: 24px;
  margin: 0 16px 0 0;
}
.modalCloseIcon {
  width: 24px;
  height: 24px;
  margin-right: 24px;
}
.modalHeaderText {
  margin: 0;
  
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  color: #fff;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthXl.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded {
  animation: modal 0.5s ease-out;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded {
  animation: modal 0.5s ease-out;
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthLg.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded {
  animation: modal 0.5s ease-out;
}

@keyframes modal {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
@media screen and (max-width: 812px) {
  .modalHeaderText {
    /* margin: 26px 0 26px 0; */
    /* height: 48px; */
    
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    color: #fff;
    /* text-align: center; */
  }
  .headerContent {
    margin-left: 10px;
  }
  .modalTitleIcon {
    width: 16px;
    height: 16px;
    flex-grow: 0;
    /* margin: 5px 8px 8px 16px; */
    object-fit: contain;
    margin-top: -3px;
  }
  .modalCloseIcon {
    width: 24px;
    height: 24px;
    flex-grow: 0;
    object-fit: contain;
  }
}

/* css by ps */

.MuiDialog-paperFullWidth {
  
  overflow: hidden;
}

@media screen and (min-width: 1260px) {
.modalBody {
 
  max-height: 387px !important;
  
}
}