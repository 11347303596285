.comments {
    margin-top: 20px;
}
.comment {
    margin-bottom: 0px !important;
}
.comments-title {
    font-size: 30px;
    margin-bottom: 20px;
}

.pdRemotel {
    margin-left: -50px !important;
}

.comments-container {
    margin-top: 20px;
    overflow: hidden !important;
}
.pdB {
    margin-bottom: 8px !important;
    margin-top: 8px !important;
}

.comment-form-title {
    font-size: 22px;
}
.mlBtnComment{
    margin-left: 16px !important;
}
.comment-form-textarea {
    resize: none;
    width: 70%;
    height: 56px;
    margin-bottom: 8px;
    margin-top: 8px;
    border: 1px solid rgb(107, 114, 12);
}
.deleteComment{
    position: absolute;
    top: 24px;
    right: 32px;
}
.replies .deleteComment{
    position: absolute;
    top: 24px;
    right: 60px !important;
}
.comment-form-button {
    font-size: 16px;
    padding: 8px 16px;
    background: rgb(59, 130, 246);
    border-radius: 8px;
    color: white;
}

.comment-form-button:hover:enabled {
    cursor: pointer;
    background: rgb(37, 99, 235);
}

.comment-form-button:disabled {
    opacity: 0.7;
    cursor: default;
}

.comment-form-cancel-button {
    margin-left: 10px;
}

.comment-image-container {
    margin-right: 12px;
}

.comment-image-container img {
    border-radius: 50px;
}

.comment-right-part {
    width: 100%;
}

.comment-content {
    display: flex;
}

.comment-author {
    margin-right: 8px;
    font-size: 20px;
    color: rgb(59, 130, 246);
}

.comment-text {
    font-size: 18px;
}

.comment-actions {
    display: flex;
    font-size: 12px;
    color: rgb(51, 51, 51);
    cursor: pointer;
    margin-top: 8px;
}

.comment-action {
    margin-right: 8px;
}

.comment-action:hover {
    text-decoration: underline;
}

.replies {
    margin-top: -10px !important;
    margin-left: 4px;
}

.paperH {
    height: auto;
}
/* .wR .comment {
    width: auto !important;
} */

/* .wR .widthInnerset {
    width: 150% !important;
} */
.wR .widthInnersetAfterClick {
    width: 100% !important;
}

.commentBoxBtn {
    width: 100%;
    background-color: #fff;
    height: 58px;
}

/* outerBox */
.commentBtn {
    width: 108px;
    height: 48px;
    background-color: #e31837 !important;
    color: #fff !important;
    border: none;
    outline: none;
    float: right;
    
    cursor: pointer;
    margin-right: 16px;
    font-size: 12px;
    font-weight: 600;
}
.commentBoxTextArea {
    width: 100% !important;
    height: 70px !important;
    border-color: #fff !important;
    resize: none;
    /* margin-bottom: -8px; */
    /* margin-top: 8px; */
    
    /* margin-left: 12px; */
    padding: 10px 15px;
}
.commentBoxTextArea:focus-visible {
    outline: none !important;
}
.formOuter {
    background-color: #fff;
}

/*  reply*/
.commentReplyTextArea {
    width: 100% !important;
    height: 54px !important;
    border-color: #d3d3d3 !important;
    resize: none;
    margin-top: 8px;
    
    background-color: #fbfbfb;
    padding: 10px;
}
.commentReplyTextArea:focus-visible {
    outline: #d3d3d3 !important;
}
.commentRBtn {
    width: 108px;
    height: 48px;
    background-color: #e31837 !important;
    color: #fff !important;
    border: none;
    outline: none;
    float: right;
    
    cursor: pointer;
    margin-right: 0px !important;
    font-size: 12px;
    font-weight: 600;
}
.formReply {
    background-color: #fff;
    /* width: 790px; */
}

/*  innerREply*/
.commentSubReplyTextArea {
    width: 100% !important;
    height: 54px !important;
    border-color: #d3d3d3 !important;
    resize: none;
    margin-top: 8px;
    
    background-color: #fbfbfb;
    padding: 10px;
}
.commentSubReplyTextArea:focus-visible {
    outline: #d3d3d3 !important;
}
.commentRSBtn {
    width: 108px;
    height: 48px;
    background-color: #e31837 !important;
    color: #fff !important;
    border: none;
    outline: none;
    float: right;
    
    cursor: pointer;
    margin-right: 0px !important;
    font-size: 12px;
    font-weight: 600;
}
.formInnerReply {
    background-color: #fff;
    /* width: 710px; */
}

.ffarticleSaira {
    font-family: 'Georama-Regular';   
}

@media (min-width: 760px) {
    .comment-header
    {    display: flex;}
}

.Commentfooter {
    padding-left: 55px;
}

.comment-header div {
    display: flex;
    align-items: center;
    column-gap: 10px;
    row-gap: 10px;
    grid-gap: 10px;
    margin: 0px 5px;
}

.commentMbMt {
    margin-block-start: 0px !important;
    margin-block-end: 18px !important;
    font-size: 14px !important;
    /* margin-left: 8px !important; */
    word-break: break-word;
   
}

.comment-username{
font-size: 16px;
line-height: 1.5;
}

.comment-date
{
    font-size: 14px;
    line-height: 1.43;
    color: #6d6e71;
}
@media (max-width: 760px) {
.Commentfooter {
    padding-left: 15px;
}
.deleteComment {
    
    top: 20px;
    right: 6px;
}

.commentMbMt {
    padding-left: 56px;
}
.replies .deleteComment {
    right: 0px !important;
}

form.formInnerReply.editform button.commentRSBtn.mlBtnComment {
    position: absolute;
    left: 0;
}
}
