.previewTitle {
  margin: 0 !important;
}
.thumbnailDiv {
  width: 100%;
  margin-top: 10px;
}
.thumbnailImagePreviewArticle {
  height: 100%;
  width: 100%;
}
