.cardWH {
  height: 700px;
}
.bgImageTemplate {
  background-image: url(../../../Assets/Images/sectionBackgroundRevamp.png);
  background-size: cover;
  /* padding-bottom: 2em; */
  padding-top: 100px;
  background-attachment: fixed;
}
@media (max-width: 1366px) {
  .commonTemplate {
    padding: 0 80px;
    max-width: 1192px;
  }
}
.commonTemplate {
  max-width: 1192px;
  margin: auto;
}

@media (max-width: 1260px) {
  .commonTemplate {
    padding: 0 16px;
    max-width: 85%;
  }
  .Standardfilter .liveWireDropDownContainer .MuiChip-root {
    max-width: 85px;
  }
}
