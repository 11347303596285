.publishArticleTitle {
  padding: 0 24px;
}
.publishContentInputs {
  padding: 24px;
}
.articleM24 {
  margin-top: 24px;
  margin-bottom: 90px;
}
