.rejectionHeader {
  width: 82px;
  height: 20px;
  flex-grow: 0;
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #6d6e71;
}

.rightAlign {
  right: auto;
}
.rejectionNote {
  height: 24px;
  flex-grow: 0;
  
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.uploadBtnStyle .uploadBtn {
  height: 56px;
  flex-grow: 0;
  border: solid 1px #e4e4e4;
  /* background-color: #fbfbfb; */
  cursor: pointer;
  position: relative;
}

.uploadBtn .uploadBtnLabel {
  width: 100%;
    padding: 16px;
    position: absolute;
    flex-grow: 0;
    
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #6d6e71;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    height: 57px;
    /* line-height: 15px; */
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 45px;
    height: 40px;
}

.uploadBtnLabel .colRed {
  color: #e31837;
}

.uploadBtn .uploadIconStyl {
  float: right;
  padding: 16px;
}

.compatiblityTxt {
  /* width: 115px; */
  height: 60px;
  flex-grow: 0;
  margin: 0 0 0 20px;
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #6d6e71;
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .uploadBtn .uploadBtnLabel {
    width: 80% !important;
  }
}

.linkLabel {
  
  font-weight: 600;
  font-size: 12px;
  line-height: 1.67;
  text-transform: uppercase;
}

.fileName {
  color: #6d6e71;
  
  font-size: "12px";
  padding-left: "14px";
}
