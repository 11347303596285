@font-face {
  font-family: "QuantumRise-Bold";
  src: local("QuantumRise-Bold"),
    url("./Assets/fonts/QuantumRise-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "QuantumRise-Light";
  src: local("QuantumRise-Light"),
    url("./Assets/fonts/QuantumRise-Light.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "QuantumRise-Medium";
  src: local("QuantumRise-Medium"),
    url("./Assets/fonts/QuantumRise-Medium.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "QuantumRiseGX";
  src: local("QuantumRiseGX"),
    url("./Assets/fonts/QuantumRise-Regular.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "QuantumRise-Semibold";
  src: local("QuantumRise-Semibold"),
    url("./Assets/fonts/QuantumRise-Semibold.ttf") format("truetype");
  font-weight: bold;
}

body {
  margin: 0;
  font-size: 14px;
  background: #faf7f6;
}

html,
body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
div,
button,
strong,
tr,
td,
th,
input,
textarea,
label,
select {
  font-family: "Georama-Regular" !important;
}

.section-header-text,
.taskSubTitle,
.offCHeaderTitle,
.bloodHeading {
  font-family: "QuantumRise-Semibold" !important;
}

body::-webkit-scrollbar {
  width: 15px;
  height: 10px;
}

body::-webkit-scrollbar-track {
  border-radius: 0;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
}

body::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #ccc;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #9c9ea17a;
}

/* Style for the main container */
.mainLayoutContainer {
  min-height: 85vh;
}

.linkStyleDecorationNone {
  text-decoration: none;
}
/* .noBorderRadius {
  border-radius: none !important;
} */
.MuiPaper-rounded,
.MuiOutlinedInput-root,
.noBorderRadius {
  border-radius: 0px !important;
}

.MuiOutlinedInput-root {
  border-color: #e4e4e4;
  /* background-color: #fff; */
}

input.MuiInputBase-input.MuiOutlinedInput-input {
  width: 100% !important;
}
/* .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0) !important;
} */
.maxWidthContent > .charLeftField > div > div {
  padding-right: 143px !important;
}
/* .MuiOutlinedInput-root {
  border-radius: 0px !important;
} */
.showMobileDeviceOnly {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  font-size: 16px;

  font-weight: 600;
  color: #e31837;
}
.swal2-title {
  color: #242424;
}
.swal2-html-container {
}
.swal2-styled.swal2-confirm {
  border: 0 !important;
  border-radius: 0 !important;
  background: initial;
  background-color: #e31837 !important;
  color: #fff !important;
  font-size: 14px !important;

  height: 48px !important;
  min-width: 125px;
}
.swal2-styled.swal2-cancel {
  border: 0;
  border-radius: 0 !important;
  background: initial;
  background-color: #ffffff !important;
  color: #e31837 !important;
  border: 1px solid #e31837 !important;
  font-size: 14px !important;

  height: 48px !important;
}
.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: none !important;
}
.swal2-icon.swal2-warning {
  color: #e31837 !important;
  border-color: #e31837 !important;
  opacity: 0.5;
}
.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}
.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  background-color: #e31837 !important;
}
.swal2-icon.swal2-error {
  color: #e31837 !important;
  border-color: #e31837 !important;
  opacity: 0.5;
}
.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  color: #e31837;
}
/* .disabledStyle {
  background-color: #a7a9ac !important;
  height: 22px;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 1.83;
  text-transform: uppercase !important;
  color: #fff;
} */

/* charLeftField top margin remove */
fieldset {
  top: 0 !important;
}
/* @media (max-width: 960px) {
  .MuiOutlinedInput-root {
    padding-right: 0 !important;
  }
} */
/* .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #ffffff !important;
} */
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  background-color: #f9f9f9 !important;
  border-left: 5px solid #f9f9f9;
  border-right: 5px solid #f9f9f9;
  /* height: 15px !important; */
}
.cursorPointer {
  cursor: pointer;
}
.alignResNavScrollItems {
  height: 420px;
  overflow-y: auto;
}
.MuiBottomNavigationAction-label {
}
.footerNavigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}
@media (max-width: 960px) {
  .GenieIcon,
  .genieCard {
    display: none;
  }
}

@media (min-width: 961px) {
  .footerNavigation {
    display: none;
  }
}

/* css by ps */
.customTextEditor .ql-editor {
  min-height: 360px;
  max-height: 360px;
  /* overflow-y: scroll; */
}
.hidden {
  display: none;
}

.Deletebtn {
  display: flex;
  margin-left: 45%;
}

.paginationAlignFlex li button {
  width: 40px;
  height: 40px;
  flex-grow: 0;
  margin: 0 11px 0 0;
  padding: 8px;
  transform: rotate(-360deg);
  background: #fff !important;
}

.paginationAlignFlex li button.Mui-selected {
  background: #db233a !important;
  color: #fff !important;
}

.erPanel {
  padding-top: 80px;
  /* background-color: #050505; */
}

.homeIcon {
  position: absolute;
  width: 20px;
  height: 20px;
  left: -10px;
  top: -20px;
}
.homeIcon img {
  width: 100%;
}

.erItem {
  height: 70px;
}

.etItem img {
  min-width: 40px;
}

.erPanelBox {
  padding: 1.25rem;
}

.erItemTitle {
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  text-align: center;
  color: #050505;
}

@media (max-width: 560px) {
  .sitetutorialpanel {
    /* position: absolute;
    right: 15px;
    margin-top: -28px; */
    float: right;
  }

  .orgpdfmodal .modalBody {
    padding: 0px;
    /* height: 35em; */
    /* max-height: 35em; */
  }
}
@media (max-width: 960px) {
  .whatsNewHeader {
    margin-bottom: 15px;
  }
}
@media (max-width: 1900px) {
  .policiesCssDiv {
    width: 994px;
  }
}

@media (max-width: 1200px) {
  .policiesCssDiv {
    width: 100%;
  }
}
