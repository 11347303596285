.emergencyContactContainer .my-masonry-grid_column:nth-child(1) {
  padding-left: 0px;
}

.emergencyContactContainer .MuiTab-root {
  margin: 0px !important;
}

/* .emergencyContactContainer .editIcon {
  margin: 0px;
  font-size: 20px;
} */
.emergencyContactContainer .MuiBox-root{
  padding: 24px 0px !important;
}
/* .emergencyContactContainer .edit-line {
  height: 24px;
} */
.emergencyContactContainer button.MuiButtonBase-root.MuiTab-root {
  border-bottom: 1px solid #d3d3d3;
}

@media (max-width: 600px) {
  .emergencyContactContainer .MuiTabs-flexContainer {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d3d3d3;
    width: 93%;
  }
}