.cardShadow {
  box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.1) !important;
}
.cardImage {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  height: 12em;
}
.heightOCCardAlign {
  height: 12em;
}
.cardDescription {
  
  font-size: 0.9em;
  font-weight: 600;
  color: #000;
  line-height: 1.57;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  height: 41px;
  -webkit-box-orient: vertical;
}
.cardAction {
  padding: 1em;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
}
.actionContent {
  font-size: 0.8em;
  
  color: #6d6e71;
}
.viewContent {
  font-size: 0.8em;
  
  color: #e31837;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}
.status {
  font-size: 0.8rem;
  
  font-weight: 500;
  line-height: 1.67;
  color: #000;
}
.statusApproved {
  color: #7ac143 !important;
}
.statusRejected {
  color: #e31837 !important;
}

.card-media {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0);
  height: 12em;
  overflow: hidden;
}
