.yammer .yj-yam-spittle {
    width: 26px !important;
    height: 26px !important;
    display: inline-block;
    background-image: url("../../../Assets/Images/collectiveIcons/Yammer.jpg") !important;
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: middle !important;
}
span.yj-share-copy {
color: #000 !important;
font-size: 16px !important;

font-weight: 400 !important;
}

.yammer span.yj-share-copy:hover {
    color: #000 !important;
    text-decoration: none !important;
}

.yammer .yj-default-share-button {
    background-color: #fff !important;
    height: 30px !important;
}

.teamImg .img {
    width: 26px;
    height: 26px;
}
.mlMail{
    margin-left: 6px;
}
.mlTeam{
    padding-left: 35px;
    position: absolute;
}

.teams-share-button a img {
    position: absolute;
    left: 0;
    top: 0;
}

.teams-share-button a {
    padding: 16px 100px;
    text-align: left;
    z-index: 10;
    position: relative;
    display: block;
    width: 100%;
    left: 0;
}