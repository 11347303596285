.rollBasedAddAccess {
  background-color: #faf9f9;
  margin-top: 10px;
  padding: 24px;
}
.rollBasedPaperAddNew {
  box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12) !important;
}
.rollBasedPaperInnnerContent {
  padding: 24px;
}
.rollBasedPaperInnnerContentLabel {
  
  font-size: 12px;
  font-weight: 600;
}
.rollBasedAutoCompplete > div > div {
  background-color: #ffffff !important;
}
.rollBasedGrid {
  margin-top: 16px !important;
}
.mb26 {
  margin-bottom: 26px;
}
.roleBasedCreateUserG {
  
  font-size: 12px;
  font-weight: 600;
  color: #e31837;
  float: right !important;
  margin-top: 18px;
}

.rBautoC2 .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  height: 56px !important;
}
.assignUGField .MuiFormControl-root.MuiTextField-root {
  background-color: #fff !important;
}