.policiestable {
  padding-bottom: 48px;
  box-shadow: none;
}
.policiesTableHead {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 55px;
  background: #fff;

  font-size: 12px;
  font-weight: 600;
  border-bottom: 1px solid #ededed;
  color: #000000;
  column-gap: 46px;
}
.policiesTableContent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 55px;
  background: #fff;

  font-size: 12px;
  font-weight: normal;
  border-bottom: 1px solid #ededed;
  color: #000000;
  column-gap: 26px;
}
.policyName {
  flex: 0.3;
  padding-left: 32px;
}
.policyDescriptionHead {
  flex: 0.4;
}
.policyDescription {
  flex: 0.4;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.policyLastUpdatedOn {
  flex: 0.2;
}
.policyDownload {
  flex: 0.1;
}
.PDFTablePosition {
  padding: 24px 24px 24px 0;
}
.PDFTablePos {
  justify-content: flex-end !important;
  height: auto !important;
}
