.tutorialBtn {
  width: 116px;
  height: 30px;
  display: flex;
  border: solid 1px #e31837;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  float: right;
  margin-bottom: 16px;
}

.templateTop {
  top: 104px;
}

.Button-Label {
  width: 92px;
  height: 24px;
  flex-grow: 0;
  
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #e31837;
  text-transform: uppercase;
}

@media screen and (max-width: 767px) {
  .tutorialBtn {
    right: 15px;
    top: 60px;
    width: 116px;
  }
  @media (max-width: 960px) {
    .tutorialBtn {
      display: none;
    }
  }
  .listDescription {
    padding-right: 0px;
  }
}
