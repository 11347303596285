.greetingCard {
    top: 15.5vh;
    position: fixed !important;
    z-index: 2;
    width: 436px;
    opacity: 1;
    animation: greetingCard 14.5s linear 1;
    animation-delay: 0.3s;
    animation-fill-mode: both;
}

.mtOrg1 .MuiOutlinedInput-notchedOutline {
    background-color: #fff !important;
}
/* .uploadBtn.CWb {
    background-color: #fff !important;
} */
.Ch1 .charLeftField {
    width: 328px !important;
    background-color: #fff !important;
}
.ff .charLeftField {
    background-color: #fff !important;
    margin-top: 18px;
    margin-left: -48px;
}
.mlBUFU {
    margin-left: -51px !important;
}

.bannerSearchButton {
    width: 400px;
    height: 50px;
    margin-right: 16px;
    margin-bottom: 16px;
}
.tableMessage {
    background-color: #fff;
    padding: 0px 24px 24px 16px;
}
.bannerModalEdit .MuiDataGrid-root {
    border-radius: 0px;
    border-color: #ededed;
}
.bannerTitleBtn {
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
    padding-top: 8px;
}

.innerFO .MuiFormControl-root.MuiTextField-root {
    background-color: rgba(0, 0, 0, 0.12) !important;
}
.NameUpload .pd .MuiOutlinedInput-input {
    padding: 18.5px 140px !important;
}

.bannerModalEdit
    button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.commonButtonStyle.modalButton.MuiButton-containedSecondary.MuiButton-containedSizeSmall.MuiButton-sizeSmall
    span.MuiButton-label {
    position: absolute !important;
    font-size: 12px !important;
    color: #f7f6f6 !important;
}
.bannerModalEdit
    button.MuiButtonBase-root.MuiButton-root.MuiButton-outlined.commonButtonStyle.MuiButton-outlinedSecondary.MuiButton-outlinedSizeSmall.MuiButton-sizeSmall {
    color: #e31837 !important;
    
    border: 1px solid #e31837 !important;
    font-size: 12px !important;
    background-color: #fff !important;
    font-weight: 600;
    padding-left: 33px !important;
    padding-right: 32px !important;
}
.headerModify {
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
    height: 100px;
    margin-bottom: 8px;
}
.greetingCard .MuiCardContent-root {
    padding: 0px !important;
}
.gOuterContainer {
    display: flex;
    height: auto;
    padding: 16px;
}
.NameTitle,
.NameTime {
    color: #6d6e71;
    font-size: 12px;
    margin-bottom: 4px;
}
.NameTitle,
.nameT {
    margin-right: 24px;
}
.nameT,
.time {
    font-size: 16px;
    
    margin-bottom: 60px;
}
.innerFO .MuiFormControl-root.MuiTextField-root.MuiFormControl-fullWidth {
    height: 56px !important;
}
.innerFO span.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium {
    margin-top: 0px !important;
    margin-left: 25px !important;
}
.innerFO
    .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault {
    margin-right: -20px !important;
    width: 160px !important;
    margin-left: 30px !important;
}
.VAC {
    font-weight: 600 !important;
    text-transform: uppercase !important;
    font-size: 12px !important;
}
.innerFO .MuiFormControl-root.MuiTextField-root {
    background-color: #fff !important;
    box-shadow: none !important;
}

.innerFO
    .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd {
    height: 38px !important;
    margin-top: 13px;
}

.innerFO .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none !important;
}
.innerFO .MuiInput-underline:before {
    border-bottom: none !important;
    display: none !important;
}
.innerFO .MuiInput-underline:before:focus {
    border-bottom: none !important;
}
.innerFO label#checkboxes-tags-demo-label {
    margin-left: 33px !important;
    font-size: 14px;
    
}
.innerFO label#checkboxes-tags-demo-label:active {
    margin-left: 33px !important;
    font-size: 14px;
    
    color: #242424 !important;
}

.innerFO .MuiAutocomplete-endAdornment {
    margin-right: 10px !important;
}

.innerFO .MuiInput-underline:after {
    border-bottom: none !important;
}
.innerFO input#checkboxes-tags-demo {
    padding-left: 33px !important;
}
.Mui-expanded .orgModalArrow {
    color: #e31837 !important;
}

.innerFO .MuiFormControl-root::before {
    content: "" !important;
    height: 18px !important;
    width: 22px !important;
    top: 16px !important;
    left: 6px !important;
    position: absolute !important;
    background-image: url(../../Assets/Images/building24.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}
.innerFO .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 16px !important;
}
.orgModalSectorL .MuiFormControl-root::before {
    background-image: url(../../Assets/Images/org-sector.svg) !important;
    width: 22px !important;
    height: 18px !important;
    top: 16px !important;
    left: 6px !important;
}
.orgDateTop .MuiFormControl-root.MuiTextField-root.datePickerStyle.MuiFormControl-marginNormal {
    width: 319px !important;
}

.Modalcontent .commanButton > button {
    width: 85px !important; 
}

.innerFO .MuiFormLabel-root.Mui-focused {
    color: #242424 !important;
}
.sector label#checkboxes-tags-demo-label:focus {
    color: #242424 !important;
}
.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #242424 !important;
}

.sector
    .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd {
    height: 56px !important;
}
.sector span.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium {
    margin-top: 0px !important;
}
.addTBtn
    button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.commonButtonStyle.MuiButton-containedSecondary.MuiButton-containedSizeSmall.MuiButton-sizeSmall {
    height: 44px !important;
    width: 80px !important;
}
.innerFO .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    width: 328px !important;
}

.addOrgFeilds .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.dateOrgModal > div {
    width: 328px !important;
}
.innerFO .MuiInputLabel-shrink {
    transform: translate(0, -4px) scale(0.75) !important;
    background-color: #fff !important;
    color: #242424 !important;
    border-left: none !important;
    border-right: none !important;
}

/* .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot.css-1gywuxd-MuiInputBase-root-MuiOutlinedInput-root
    > div:not(:first-child):not(:last-child) {
    display: none;
} */

.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInput-fullWidth.MuiInputBase-formControl.MuiInput-formControl
    > div:not(:first-child):not(:last-child) {
    display: none;
    background-color: red !important;
}

.imgB {
    width: 18px;
    height: 18px;
}
.editContainer {
    display: flex;
    align-items: center;
    position: absolute;
    top: 16px;
    right: 30px;
    cursor: pointer;
}
.bannerModalEdit .favTabModal {
    padding-top: 0px;
    padding-right: 0px;
    padding-left: 0px;
}
.imgZindex {
   /* z-index: 9999 !important;*/
   z-index: 9999 !important;
    position: relative;
    margin-top: -40px;
}
.OrgHeaderT {
    color: #e31837;
    margin-left: 40px;
    font-size: 12px;
    /* width: 198px; */
    font-weight: 600;
    position: absolute;
    right: 0px;
}
.editSubCPHeader {
    margin-left: 24px;
    margin-right: 24px;
    background-color: rgb(247, 246, 246);
    padding: 10px;
}
.orgmodalEdit .modalBodyBg {
    min-height: 400px !important;
}
.editContainerGot {
    font-size: 12px;
    color: #e31837;
    font-weight: 600;
    
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 10px;
    right: 30px;
    cursor: pointer;
}
.innerFO span.material-icons.MuiIcon-root {
    color: #e31837 !important;
}
/* .bannerTitleBtnOrg .orgAdminCalender fieldset.PrivateNotchedOutline-root-11.MuiOutlinedInput-notchedOutline {
    border-color: #e4e4e4 !important;
} */
.bannerTitleBtnOrg .MuiOutlinedInput-root.Mui-focused fieldset {
    border: none !important;
}

.bannerTitleBtnOrg .orgAdminCalender .MuiFormControl-root {
    box-shadow: none !important;
    background-color: #fbfbfb !important;
}
.bannerTitleBtnOrg .MuiFormControl-root.MuiTextField-root.autoCompleteOnFocus.MuiFormControl-fullWidth {
    box-shadow: none !important;
    background-color: #fbfbfb !important;
}
.adminMonthOrg {
    margin-top: 14px !important;
    margin-left: 16px !important;
}
/*
.bannerTitleBtnOrg .calenderTypeIcon > div > div > div > fieldset {
  border-color: #e4e4e4 !important;
} */
.monthOrg .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon {
    border: 1px solid #e4e4e4;
    height: 50px !important;
}

.monthOrg
    .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    padding-left: 10px !important;
    overflow: visible !important;
}
/* .orgAdminCalender {
    margin-top: 14px !important;
} */
.monthOrg .MuiAutocomplete-endAdornment {
    display: block !important;
}

.monthOrg
    .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd
    > div:not(:first-child):not(:last-child) {
    display: none;
}

.orgAdminCalender .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
    border: 1px solid #e4e4e4;
}

.customMessage {
    padding-right: 24px;
}
.NameUpload {
    display: flex;
}
.mbBtn {
    margin-bottom: 16px !important;
    margin-top: 4px !important;
}
.mtOrgg {
    margin-top: 0px !important;
}
.mtOrgg .MuiButton-containedSizeSmall {
    padding: 5px 20px !important;
    height: 55px !important;
}
.dd .MuiFormControl-root::before {
    content: "";
    height: 24px;
    width: 24px;
    top: 16px;
    left: 6px;
    position: absolute;
    background-image: url(../../Assets//Images/building.svg);
    background-repeat: no-repeat;
    background-position: center;
}
.mtReplacee {
    margin-left: 4px !important;
}
.mlOrg {
    margin-left: 40px !important;
}
.mbReplace {
    width: 95%;
    margin-left: 16px;
    display: none;
}
/* .announcementName > div {
    max-width: 95%;
    margin-left: 15px !important;
} */
.keymTop {
    margin-top: 8px !important;
}
.keymTopOrgModal {
    margin-top: 12px !important;
}
.keyUploadTop {
    margin-top: 8px !important;
}
.announcementUpload > div {
    margin-bottom: 24px;
    max-width: 97.6%;
}
.headerModifyOrg {
    display: flex;
    justify-content: start;
    background-color: #fff;
    height: 76px;
    margin-bottom: 8px;
    padding-left: 16px;
}
.bannerTitleBtnOrg {
    display: flex;
    justify-content: flex-start;
    /* padding-top: 8px; */
    /* padding-left: 16px; */
    position: relative;
    margin-bottom: 20px;
}
.orgModalAccordianSummary{
    position: relative;
}
.orgModalAccordianSummary .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.Mui-expanded.MuiIconButton-edgeEnd {
    position: absolute;
    top: 28px;
    right: 24px;
}

.AccordianMessage .MuiCollapse-wrapperInner {
    margin-top: -30px !important;
}
.AccordianMessage .MuiListItem-root {
    justify-content: end !important;
}
.AccordianMessage {
    /* margin-left: 16px;
    margin-right: 16px; */
    max-height: auto;
    height: auto !important;
}
.backgroundColorCollapse {
    background-color: #fff;
    margin-top: 16px;
}
/* .AccordianMessage svg.MuiSvgIcon-root {
    margin-left: 78px !important;
} */
.bannerSearchButtonOrg {
    width: 400px;
    height: 50px;
    margin-right: 16px;
    margin-left: 16px;
}
.bannerModalEdit .modalBody {
    padding-top: 0px;
}

.textFieldSize {
    width: 300px !important;
}
.viewRight {
    position: absolute;
    right: 4px;
    bottom: -14px;
}
.textInputFieldSize {
    width: 473px !important;
}
.mtOrg {
    margin-top: 16px !important;
}
.paddingRightOrgg {
    display: flex;
    align-items: center !important;
}
.paddingRightOrgg .charLeftField {
    width: 77.4% !important;
}

.mtOrg1 {
    margin-top: 0px !important;
}

.dFlexOrg {
    display: flex;
    align-items: center;
}
.setscrollOrg .MuiAccordionDetails-root {
    display: block !important;
}
.hField {
    height: 56px !important;
}
.modifiedDate {
    padding-right: 24px;
    padding-top: 16px;
}
.modifiedName {
    padding-top: 16px;
}
.editBtn {
    color: #e31837;
    
    font-size: 20px;
    font-weight: 600;
}
.greetingCover {
    width: 15.2px;
    height: 14.5px;
    margin-top: 2px;
    margin-left: 2px;
}

.greetingContainer {
    margin-left: 8.2px;
    
    word-break: break-all;
}
.bannerModalEdit .SearchBar > div > fieldset {
    box-shadow: none !important;
}
.bannerModalEdit .bannerTitleBtnOrg .commanButton .MuiFormControl-root.MuiTextField-root {
    background-color: #fff !important;
}
.AccordianMessage
    .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters.css-o4b71y-MuiAccordionSummary-content {
    display: block !important;
}
.summaryContainer {
    display: block;
}
.dateOrgModal .MuiFormControl-root.MuiTextField-root.datePickerStyle.MuiFormControl-marginNormal {
    background-color: #fff !important;
}
.selectedSize {
    font-size: 12px;
    color: #6d6e71;
}
.replaceText {
    height: 20px;
    font-size: 12px;
    font-weight: 600 !important;
    line-height: 1.67;
    color: #e31837;
    cursor: pointer;
}
.AccordianMessage
    input.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled.Mui-disabled.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
    color: #000 !important;
}
.ViewOrg {
    color: #e31837;
    font-size: 12px;
    text-transform: uppercase;
    float: right;
    padding-top: 10px;
    padding-right: 13px;
    display: flex;
    align-items: center;
    font-weight: 600;
}
.summaryDetail1 {
    
    font-size: 18px;
    font-weight: 600;
    color: #242424;
}
.summaryDetail2 {
    
    font-size: 12px;
    color: #6d6e71;
}
.gAnniversary {
    color: #7c3520;
    font-size: 12px;
    font-weight: 500;
}

.greetingName {
    margin-top: 10px;
    font-weight: 700;
}

.greetingName,
.gCongratulation {
    color: #242424;
    font-size: 14px;
}
.accordianCharField {
    margin-left: 40px !important;
    margin-top: 0px;
}
.accordianViewAnnDiv {
    width: 200px;
    padding-top: 26px;
}
.mrText {
    margin-right: 40px !important;
}
.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
    overflow: hidden;
    font-weight: 500;
    white-space: nowrap;
    font-weight: 600 !important;
    
    text-overflow: ellipsis;
    font-size: 12px !important;
}
.Ch1 {
    margin-bottom: 10px !important;
    /* background-color: #fff !important; */
}
/* .Ch1 fieldset.MuiOutlinedInput-notchedOutline {
    background-color: #fff !important;
}
.ff fieldset.MuiOutlinedInput-notchedOutline {
    background-color: #fff;
} */

.ff {
    margin-top: -4px !important;
}
/* sf css */
/* .ff::after {
    content: "Write upto 3";
    color: #6d6e71 !important;
    height: 25px;
    width: 100px;
    top: 35%;
    right: 18%;
    position: absolute;
} */

.MuiDataGrid-root .MuiDataGrid-cell {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    border-bottom: 1px solid rgba(224, 224, 224, 1);
    text-overflow: ellipsis;
    font-size: 12px;
    
}
.uploadI1 {
    width: 328px !important;
}
.mtOrg1 .uploadBtnStyle .uploadBtn {
    background-color: #fff !important;
    margin-top: 14px;
}
.GDropC {
    margin-top: 32px !important;
}
/* .innerC .MuiOutlinedInput-root fieldset {
    background-color: #fff !important;
}
.CWb {
    background-color: #fff !important;
} */

/* .GDropC .businessTypeIcon > div > div > div > fieldset {
  border-color: rgba(0, 0, 0, 0.12) !important;
} */

@media (max-width: 375px) {
    .bannerSearchButtonOrg {
        width: 260px !important;
    }
}

@media (max-width: 600px) {
    .announcementName > div {
        max-width: 97.6%;
        margin-left: 15px !important;
        margin-bottom: 12px;
    }
    .announcementUpload > div {
        margin-bottom: 24px;
        max-width: 97.6%;
        margin-left: 15px !important;
    }
    .adminManage {
        justify-content: start;
    }
    .headerModifyOrg {
        justify-content: space-between !important;
    }
    .OrgHeaderT {
        color: #e31837;
        font-size: 12px;
        width: 135px;
        font-weight: 600;
        margin: 0 auto;
        margin-top: 12px;
    }

    .mtOrg1 {
        margin-top: 16px !important;
    }
    .bannerSearchButtonOrg {
        width: 300px;
        height: 50px;
        margin-right: 16px;
        margin-left: 0px;
        margin-top: 12px;
    }
    .accordianViewAnnDiv {
        width: 300px;
        padding-top: 26px;
    }
    .accordianCharField {
        margin-left: 0px !important;
        margin-top: 12px !important;
    }
    .bannerTitleBtn {
        display: block;
        justify-content: center;
    }
    .bannerSearchButton {
        width: 250px;
    }
    .bannerModalEdit
        .MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-flex-start.MuiGrid-justify-content-xs-space-evenly {
        padding-left: 8px;
        padding-right: 8px;
        display: block;
    }
    .bannerTitleBtn {
        padding-left: 8px;
    }
    .greetingCard {
        width: 320px;
        transform: translateX(85px);
    }
    .dFlexOrg {
        display: block;
    }
    .textFieldSize {
        width: auto !important;
    }
    .textInputFieldSize {
        width: auto !important;
    }
    .hField {
        height: auto !important;
    }
    .mrText {
        margin-right: 0px !important;
    }
}

@media (max-width: 375px) {
    .bannerSearchButtonOrg {
        width: 273px !important;
    }
    .orgAdminCalender {
        width: 273px !important;
    }
    .viewRight {
        position: absolute;
        right: -8px;
        bottom: -26px;
    }
}

@media (max-width: 320px) {
    .bannerSearchButtonOrg {
        width: 230px !important;
    }
    .orgAdminCalender {
        width: 230px !important;
    }
    .viewRight {
        position: absolute;
        right: 16px;
        bottom: -28px;
    }
}

@keyframes greetingCard {
    0% {
        opacity: 0;
        right: 85px;
        height: 156px;
    }
    15% {
        opacity: 1;
        right: 85px;
        height: 156px;
    }
    50% {
        opacity: 1;
        right: 85px;
        height: 156px;
    }
    81% {
        opacity: 1;
        right: 85px;
        height: 156px;
    }
    91% {
        opacity: 0;
        right: 85px;
        height: 156px;
    }
    100% {
        opacity: 0;
        right: 305px;
        height: 0px;
    }
}
