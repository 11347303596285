.Econtent .TableContent {
  text-align: left;
}

.fontboldnew {
  font-weight: 700;
  font-size: 16px;
}

span.emailidfield {
  color: #1592e6;
}

.notevalue {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
}

a.pdfButton {
  text-decoration: none;
  width: 197px;
  height: 48px !important;
  padding: 13px 48px;
  background-color: #e31837 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 1.83;
  text-align: right;
  color: #fff !important;
  box-shadow: none !important;
  float: right;
}
a.pdfButton:hover {
  background-color: "green";
  color: "#fff" !important;
  text-decoration: none;
  display: block;
}
