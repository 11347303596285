.modifiedContainer {
  display: flex;
  margin-top: 0.9em;
}
.modifiedText {
  
  font-size: 0.8rem;
  font-weight: normal;
  line-height: 1.67;
  color: #6d6e71;
  padding: 0;
  margin: 0;
}
.modifiedSubText {
  
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #000;
  padding: 0;
  margin: 0;
}
.firstSectionModifield {
  margin-right: 4em;
}
