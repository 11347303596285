.liveWireDropDownContainer {
  padding: 0px 0px 0px !important;
}

.zeroPad {
  padding: 0px !important;
}

.leaderSpeakCardContainer {
  padding: 0 8px 16px 8px;
}

/* .liveWireDropDownContainer .MuiGrid-item {
  padding: 8px;
} */
.posRight {
  float: right;
}
.liveWireDropDownContainer .MuiFormControl-root {
  width: 100%;
}

.liveWireDropDownContainer .MuiFormControl-root {
  box-shadow: 0 6px 35px 0 rgb(0 0 0 / 10%);
  background-color: #fff !important;
}

.liveWireDropDownContainer .MuiOutlinedInput-root {
  border-radius: 0px !important;
  height: 48px !important;
  padding-left: 30px !important;
}

.monthMahindraCollective .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
  overflow: visible !important;
}

.liveWireDropDownContainer input#select-typt-file {
  margin: -2px 0px 0px 0px;
  
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.businessTypeIcon .MuiFormControl-root::before {
  content: "";
  height: 24px;
  width: 24px;
  top: 13px;
  left: 6px;
  position: absolute;
  background-image: url(../../../../Assets/Images/collectiveIcons/building-line.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.businessTypeIcon .Mui-focused .MuiFormControl-root::before {
  background-image: url(../../../../Assets/Images/collectiveIcons/building-fill.svg);
}

.calenderTypeIcon .MuiFormControl-root::before {
  content: "";
  height: 24px;
  width: 24px;
  top: 12px;
  left: 6px;
  position: absolute;
  background-image: url(../../../../Assets/Images/collectiveIcons/calendar-line.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.calenderTypeIcon .Mui-focused .MuiFormControl-root::before {
  background-image: url(../../../../Assets/Images/collectiveIcons/calendar-fill.svg);
}

.shortingTypeIcon .MuiFormControl-root::before {
  content: "";
  height: 24px;
  width: 24px;
  top: 12px;
  left: 6px;
  position: absolute;
  background-image: url(../../../../Assets/Images/sort-by.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.noIcon .MuiFormControl-root::before {
  background-image: none;
}

.noIcon .Mui-focused .MuiFormControl-root::before {
  background-image: none;
}

.liveWireDropDownContainer .noIcon .MuiOutlinedInput-root {
  padding-left: 16px !important;
  overflow: hidden;
}
.mahindraCollectiveSector
  fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.liveWireDropDownContainer .noIcon .MuiInputLabel-formControl {
  transform: translate(16px, 18px) scale(1) !important;
  margin-top: -4px;
}

.liveWireDropDownContainer .MuiChip-root {
  height: 26px !important;
}

/* .liveWireDropDownContainer .MuiAutocomplete-tag {
  margin: 2px 3px 9px 3px !important;
} */

/* .liveWireDropDownContainer .MuiGrid-container {
  justify-content: space-around;
} */

/* .liveWireDropDownContainer
    .MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  } */

.liveWireDropDownContainer
  fieldset.PrivateNotchedOutline-root-3.MuiOutlinedInput-notchedOutline {
  border-color: #ffffff !important;
}

.liveWireDropDownContainer .MuiInputLabel-formControl {
  transform: translate(32px, 18px) scale(1) !important;
  /* margin-top: -4px; */
  /* width: 62px;
    height: 18px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; */
}

.liveWireDropDownContainer .MuiInputLabel-shrink {
  transform: translate(10px, -4.5px) scale(0.75) !important;
  transform-origin: top !important;
  /* display: none !important; */
}

span.MuiChip-label {
  
}

/* .liveWireDropDownContainer .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0) !important;
} */

/* Uncomment it to shift autocomplete text for icon */
/* .liveWireDropDownContainer .MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
    padding-left: 40px !important;
  } */

/* mahindra MultiSelectCss */

.mahindraCBU fieldset {
  top: -5px !important;
}

.mahindraCBU
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  height: 48px !important;
  overflow: visible !important;
}
.mahindraCBU label#checkboxes-tags-demo-label {
  margin-top: -7px !important;
}
.mahindraCBU .MuiAutocomplete-endAdornment {
  display: block !important;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd
  > div:not(:first-child):not(:last-child) {
  display: none;
}

.mahindraCollectiveSector
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  height: 48px !important;
}
.mahindraCollectiveSector fieldset.MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.mahindraCollectiveSector .MuiOutlinedInput-root.Mui-focused fieldset {
  border: none !important;
}
/* mahindra MultiSelectCss */
