.editPoliciesRow {
  
  font-size: 12px;
  font-weight: 600;
  color: #e31837;
  text-transform: uppercase;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
}
.EditRowModal {
  padding: 0px 24px 0 24px;
}
.editRowAlign {
  margin-top: 40px;
}
.rotateImage {
  transform: translate(50%, 50%) rotate(180deg);
  transform-origin: top;
  position: relative;
  top: 13px;
  right: 16px;
}
