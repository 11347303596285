.schedule .MuiTableCell-root,
.schedule .MuiButton-root,
.schedule .MuiGrid-root,
.schedule .MuiGrid-item,
.schedule .MuiGrid-grid-xs-10  {
    
}

.schedule .content {
    
    font-size: 14px;
    font-weight: 400;
    color: #6d6e71;
    line-height: 1.67;
}
.schedule .title {
    
    font-size: 16px;
    font-weight: 400;
    color: black;
    line-height: 1.5;
}
.schedule .heading {
    
    font-size: 16px;
    font-weight: 600;
    color: black;
    line-height: 1.57;
}
.schedule .link {
    
    font-size: 14px;
    font-weight: 600;
    color: #e31837;
    line-height: 1.67;
}

.schedule .link:hover {
    cursor: pointer;
}

.schedule table.MuiTable-root {
    min-width: auto !important;
}
div {
    
}