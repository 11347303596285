.onPriorityContainer {
  margin-bottom: 20px;
  box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.1) !important;
  height: 390px !important;
}
.taskSubTitle {
  font-size: 16px !important;
  
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  
}
.firstSection {
  display: flex;
    justify-content: space-between;
    align-items: center;
    /* margin: 12px 0 16px 0; */
    padding: 12px 24px;

    /* height: 24px; */
    box-shadow: 3px 20px 52px 0 rgb(0 0 0 / 12%) !important;
}
.customAlignArrow {
  position: relative;
  left: 5px;
}
.alignTabsMB {
  margin-bottom: 6px !important;
}
.paginationArrow {
  color: #a7a9ac;
  background-color: #f7f6f6;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  display: flex;
}
.alignArrow {
  display: flex;
  column-gap: 0.5em;
}
