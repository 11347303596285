.nameStylePriorityModal {
  display: inline-block;
  
  font-size: 16px;
  line-height: 1.38;
  color: #242424;
}
.nameStylePriorityModalMobile {
  /*  */
  font-size: 12px;
  font-weight: 600 !important;
  line-height: 2;
  color: var(--m-main-m-black-1-000000);
}
.completeTaskText {
  height: 22px;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 1.38 !important;
  text-align: right;
  color: #e31837;
  display: flex;
  align-items: center;
}
.alignTopCard {
  margin-top: 1em;
}
thead > tr > th {
  font-weight: 600 !important;
}
.tableSpace {
  background: #faf9f9;
  border-collapse: separate !important;
  border-spacing: 0 15px !important;
  padding-left: 24px;
  padding-right: 24px;
}
/* .fixHeightTable {
  height: 15em;
} */
table > tbody > tr:hover {
  box-shadow: 0 4px 31px 0 rgba(0, 0, 0, 0.1) !important;
}
.nameContainer {
  display: flex;
  align-items: center;
}
.profileContainerPriorityTask {
  height: 2em;
  width: 2em;
  border-radius: 50%;
  margin-right: 24px;
  padding: 0px;
}

.profileStyle {
  height: 32px;
  width: 32px;
  border-radius: 100%;
}
.displaySpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobileViewFontTableHead {
  
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: var(--m-main-m-black-1-000000);
  padding: 0px;
}
.mobileViewFontTableCell {
  font-size: 0.7rem;
  
  font-weight: normal;
  line-height: 2;
  color: #242424;
}
.leftRightPadding {
  padding: 0 0.5em 0 0.5em;
}
.MobileViewDetailsPriorityTasks {
  width: 100%;
  margin-bottom: 0.6em;
  box-shadow: 0 4px 31px 0 rgba(0, 0, 0, 0.1) !important;
}
.vScroll {
  height: 20em;
  overflow-y: auto;
}

@media screen and (max-width: 768px) {
  .profileContainerPriorityTask {
    /* height: 1.5em;
    width: 1.5em; */
    border-radius: 50%;
    /* width: 128px; */
    height: 24px;
    flex-grow: 0;
    object-fit: contain;

    /* margin-right: 0.5em; */
    padding: 0px;
  }
  /* .nameStyle {
    font-size: 0.7rem;
    
    font-weight: normal;
    line-height: 2;
    color: #242424;
  } */
}
/* .viewCompleteTask {
  padding: 1em 0.5em 1em 0.5em;
} */
.viewDetails {
  height: 22px;
  flex-grow: 0;
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 1.38 !important;
  color: #242424;
  text-decoration: none;
}
