.submitOfferCampaignContainer {
  margin-top: 1em;
  /* padding-bottom: 48px; */
  /* padding: 0 107px; */
  padding: 0 107px 48px 107px;
}

.selectType .MuiOutlinedInput-notchedOutline {
  border-color: #d3d3d3 !important;
}
.alignInputTextTitle > div > div > div > div > span {
  position: static !important;
}
.paperContainerOfferCampaign {
  /* padding: 1em; */
  box-shadow: 0 20px 42px -20px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* height: 800px; */
  /* height: auto; */
}
.rdw-editor-toolbar {
  border-bottom: none !important;
  background: #fbfbfb !important;
  margin-bottom: 0 !important;
}
.notranslate > div {
  border: 1px solid #f1f1f1 !important;
  padding: 0 16px 0 16px !important;
}
.public-DraftEditorPlaceholder-root {
  padding: 16px 0 0 16px;
}
.submitOfferCampaignText {
  height: 32px;
  font-size: 24px;
  font-weight: 500 !important;
  line-height: 1.33;
  color: #000;
  display: inline-block;
  margin-bottom: 16px;
}
.MuiFormControl-root.MuiTextField-root {
  /* margin: 0 !important; */
  border: none !important;
}
.width100 {
  width: 100%;
}
input[type="text"] + label {
}
/* .divContainerOfferCampaign {
  border: solid 1px #ededed;
  padding: 1em; 
  height: 264px;
  padding: 24px 16px 24px 16px;
} */

.textAreaCharLeft {
  position: absolute;
  bottom: 1em;
  right: 1em;
  height: 24px;
  flex-grow: 0;
  font-size: 16px;
  line-height: 1.5 !important;
  color: #6d6e71;
}
.additionalDetailsText {
  height: 32px;
  font-size: 24px;
  font-weight: 500 !important;
  line-height: 1.33;
  color: #000;
  display: inline-block;
  border: none;
  margin: 40px 0px 16px 0px;
}
.MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
  width: 100% !important;
  /* margin: 0 !important; */
}
/* .additionalDetailsContainer {
  border: solid 1px #ededed;
  padding: 24px 16px 24px 16px;
} */
.authorRadioButton {
  border: solid 1px #e4e4e4;
  background-color: #fbfbfb;
  height: 56px !important;
  display: flex;
}
.radioButtonForm {
  margin-left: 0.4em !important;
}
.formControlLabelStyle {
  height: 22px;
  font-size: 14px;
  line-height: 1.57 !important;
  color: #000;
}
.supportPart {
  margin-top: 1em;
}
.supportText {
  height: 22px;
  font-size: 14px;
  line-height: 1.57;
  color: #000;
  display: inline-block;
  display: flex;
  align-items: center;
  margin: 37px 0px;
}

.SubmitForApprovalButtonDiv {
  margin-bottom: 24px;
  margin-top: 24px;
}
.SubmitForApprovalButton {
  background-color: #a7a9ac !important;
  height: 22px;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 1.83;
  text-transform: uppercase !important;
  color: #fff !important;
}
.enabledButton {
  background-color: #e31837 !important;
  height: 22px;
  font-size: 12px !important;
  font-weight: 600 !important;
  line-height: 1.83;
  text-transform: uppercase !important;
  color: #fff;
}
.errorWarningIcon {
  margin-right: 0.1em;
}
.image_pdf_name {
  height: 21.8px;
  font-size: 14px;
  line-height: 1.57;
  text-align: left;
  color: #000;
}
.image_pdf_size {
  height: 20px;
  font-size: 12px;
  line-height: 1.67;
  color: rgba(0, 0, 0, 0.5);
  margin: 0px 24px 0px 8px;
}
.replaceText {
  height: 20px;
  font-size: 12px;
  font-weight: 600 !important;
  line-height: 1.67;
  color: #e31837;
  cursor: pointer;
}
.offerCampaignSubmitted {
  font-size: 16px;
  line-height: 1.5;
  color: #242424;
  padding-bottom: 60px;
  text-align: justify;
  /* padding: 0px 24px 60px !important; */
}
.innerContainersubmitOfferCampaign {
  margin: 0px 24px;
  padding-top: 24px;
}
.radioBackground {
  background-color: #f6eced;
}
.queriesEmail {
  color: #e31837;
}
.offerCampLeftRightM {
  margin: 0 24px 59px;
}
.DatePicker {
  width: 100%;
}

.DatePicker
  input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
  height: 43px !important;
  padding: 10px !important;
}
.DatePicker
  > .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl.MuiInputBase-adornedEnd {
  border: solid 1px #e4e4e4 !important;
  background-color: #fbfbfb !important;
}

.DatePicker .MuiInput-underline:after {
  border-bottom: none !important;
}

.DatePicker .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: none !important;
}

.DatePicker .MuiInput-underline:before {
  border-bottom: none !important;
}
.SupportTextBlock {
  display: none;
}
@media (max-width: 960px) {
  .submitOfferCampaignContainer {
    padding: 0;
    margin-top: 0;
  }
  .divContainerOfferCampaign {
    border: none;
    padding: 0;
    margin-top: 24px;
  }
  .additionalDetailsText {
    margin: 16px 0px 16px 0px;
  }
  .additionalDetailsContainer {
    border: none;
    padding: 0;
  }
  .supportText {
    display: none;
  }
  /* .commanButton > button {
    width: 100% !important;
  } */
  .SubmitForApprovalButtonDiv {
    width: 100% !important;
  }
  .SupportTextBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 22px;
    font-size: 14px;
    line-height: 1.57;
    color: #000;
    margin: 37px 0px;
  }
  /* .MuiGrid-spacing-xs-5 > .MuiGrid-item {
    padding: 8px 20px !important;
  } */
  .fRadio {
    margin-top: 14px;
  }
  .sRadio {
    margin-bottom: 14px;
  }
  .charsLeftText {
    display: none;
  }
}
@media (min-width: 960px) {
  .rightlimitField input {
    padding-right: 145px;
  }
}
@media (max-width: 760px) {
  .SubmitForApprovalButton {
    width: 100%;
  }
  .enabledButton {
    width: 100%;
  }

  .urlnamechange {
    margin-top: 15px !important;
    left: -10px;
    position: relative;
  }
}

.compatiblityTxtImg {
  /* width: 115px; */
  height: 60px;
  flex-grow: 0;
  margin: 0 0 0 20px;

  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #6d6e71;
  display: flex;
  align-items: center;
}
