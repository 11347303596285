/* .EthicsCardMobile{

} */
.EthicsImgContainer {
    margin-top: 14px;
}
.EthicsTitleMobile {
    font-size: 18px;
    font-weight: 600;
    margin-top: 16px;
    
}
.paddEthicsModal{
    padding: 16px !important;
}

.remotePSize{
    height: 381px !important;
}