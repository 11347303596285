.tabsPanelContainer {
  height: 50px !important;
  padding: 0 8px 0px 32px;
  background-color: #f7f6f6 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #242424;
  box-shadow: none !important;
}
.fevClearAll {
  cursor: pointer;
}
.addMoreFev {
  height: 72px;
  background-color: #f7f6f6 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #242424;
  box-shadow: none !important;
}
.favtab {
  padding-left: 0 !important;
}
.PrivateTabIndicator-colorSecondary-5 {
  background-color: #e31837 !important;
}
/* .heightFevTabScroll {
  height: 22vh;
  overflow-y: auto;
} */
.addLeft0 {
  height: 72px;
  padding: 0 16px 0px 0px;
  background-color: #f7f6f6 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #242424;
  box-shadow: none !important;
}
.alignFavModalBlocks {
  height: 80px;
  padding: 0 16px 0px 24px;
  background-color: #fff !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #242424;
  box-shadow: none !important;
}

.borderAddMore {
  border: 1px dashed;
  border-color: #a7a9ac;
}
.cursorMove {
  cursor: pointer;
}
.MuiTab-wrapper {
  
  text-transform: capitalize;
}
.tabBorderDashed {
  border: dashed 1px;
  border-color: #a7a9ac;
}
.Mui-selected {
  color: #e31837 !important;
  font-weight: 600 !important;
}

.addMore {
  border: dashed 1px #a7a9ac;
}
.MuiTab-wrapper {
  font-size: 1rem !important;
}
.favBoxIconAlign {
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-weight: normal;
}
.favBox {
  
  font-size: 1rem;
  line-height: 2;
  text-align: left;
  color: #242424;
  position: relative;
  z-index: 2;
}
.textTransform {
  text-transform: uppercase !important;
  color: #e31837 !important;
  font-weight: 500;
}
.addMore span {
  color: #e31837;
}
.alignFavModelBoxes {
  padding: 20.6px 0 16px 0;
}
