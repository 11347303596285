.offerCampaign {
  padding: 6em 0 1em 0;
}
.checkPad .MuiIconButton-root {
  padding-left: 0px !important;
}
.checkPad1 .MuiIconButton-root {
  padding-left: 0px !important;
  padding-top: 0px !important;
}
.calendarMaxWidth {
  max-width: 320px !important;
  /* margin-bottom: 16px !important; */
  padding: 0px !important;
}
.orgLeftContainer {
  max-width: 256px !important;
  min-width: auto !important;
}
.orgFilterTitleSpace {
  margin-bottom: 24px;
}
.selectTagPosition {
  position: absolute;
  top: 45%;
  left: 37%;
  font-size: 16px;
}
.adminManage {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 8px;
  margin-top: 8px;
  align-items: center;
  cursor: pointer;
  column-gap: 8px;
}
.mobileOrgAnnFilterMargin {
  margin: 24px 0;
}
.imgWheel {
  width: 24px;
  height: 24px;
}
.positionOrg1 {
  position: relative;
  overflow: scroll;
  /* min-height: 793px; */
  /* max-height: 100000px; */
  /* max-height: 793px; */
}
.calenderAlign {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.positionOrg2 .pdfViewerContainer .mobile__pdf__container footer {
  height: 3rem !important;
  position: absolute !important;
  visibility: hidden !important;
}
.alignOrgAnnouncementContentText {
  padding: 5px 12px;
  /* padding-top: 16px; */
}
.addScroll {
  height: auto;
  overflow: auto;
}
.orgPage {
  background-color: #ffffff !important;
}
.landingpageAlign {
  padding: 0 0.5em 0 0.5em;
  box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12);
  background-color: #fff;
  margin-bottom: 2em;
  margin-top: 2em;
}
.mbOrg {
  margin-top: 16px !important;
}
.orgHeaderF {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.sector label#checkboxes-tags-demo-label {
  margin-left: 20px;
  font-size: 14px;

  font-weight: 500;
  color: black;
}
.sector label#checkboxes-tags-demo-label:active {
  color: #242424 !important;
}
.css-1kty9di-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #242424 !important;
}

.orgDateP .labelInsideField {
  color: black !important;
}

.sector
  .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault {
  margin-left: 20px;
  width: 150px !important;
}
/* .sector .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -12px) scale(0.75) !important;
} */
.sector
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd {
  height: 56px !important;
}
.sector span.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium {
  margin-top: 0px !important;
}

.MuiAutocomplete-endAdornment {
  display: block !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense
  > div:not(:first-child):not(:last-child) {
  display: none;
}

.alignCardsItems {
  position: relative;
  /* padding: 5px 15px; */
  border: solid 1px #ededed;
}
.alignCardsItems:hover {
  cursor: pointer;
  background-color: #f6eced;
}
.sector .labelInsideField {
  font-size: 14px !important;
  line-height: 0.27 !important;
}
.sector label#select-typt-file-label {
  font-weight: 500 !important;
}
.mtDrop .MuiFormControl-root::before {
  content: "";
  height: 24px;
  width: 24px;
  top: 14px;
  left: 6px;
  position: absolute;
  background-image: url(../../../Assets/Images/building.svg);
  background-repeat: no-repeat;
  background-position: center;
}

/* /* .orgDateP
    label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
    margin-left: 26px !important;
} */
.orgDateP
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined:active {
  margin-left: 0px !important;
}
.sector .labelInsideField {
  /* margin-left: 20px !important; */
  margin-top: -10px !important;
}
.sector
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input:first-child {
  padding-left: 5px !important;
  padding-bottom: 8px !important;
}
.me-auto {
  margin-bottom: 16px;
}
.orgDateP .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0) !important;
}

/* .orgDateP img {
    position: absolute;
    left: -263px;
}
.sector label#select-typt-file-label {
    position: absolute !important;
    top: -4px !important;
} */
.orgDateP
  input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
  padding-left: 40px !important;
}

.orgDateP .datePickerStyle {
  background-color: #fff !important;
  box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.1) !important;
}

.sector .MuiFormControl-root.MuiTextField-root {
  background-color: #fff;
  box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.1);
}

.alignCardsItems2 {
  padding: 1em 0px;
  border: solid 1px #ededed;
}

.ml {
  margin-left: 0.8em;
}

.marginBlockNull {
  margin-block-start: 4px !important;
}

.mlEyeNum {
  margin-left: 8px;
}
.hollowImgDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5em;
  height: 3em;
  background-color: #fbfbfb;
  cursor: pointer;
}

.alignBtnRight input#select-typt-file {
  width: 221px !important;
}
.alignBtnRight input#checkboxes-tags-demo {
  width: 179px !important;
}

.positionOrg2 > footer {
  visibility: hidden !important;
}

.eye-line {
  width: 24px;
  height: 24px;
  flex-grow: 0;

  object-fit: contain;
}
.mR {
  margin-right: 10px;
}

.positionOrg2 iframe {
  position: relative;
  height: 780px;
  width: calc(60vw - 4px);
  box-sizing: border-box;
}
.positionOrg2 div#viewerContainer {
  height: 51vh !important;
}

.positionOrg2 .pdfViewer .page {
  border: none !important;
}

pdf-viewer#viewer > viewer-toolbar#toolbar {
  display: none !important;
}
.submitbutton {
  width: 142px;
  height: 48px;
  padding: 13px 48px;
  background-color: #e31837 !important;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  line-height: 1.83;
  text-align: right;
  color: #fff;
}
.alignBtnRight {
  display: flex;
  padding: 1em 0 0.5em 0;
  padding-right: 8px;
}
.tabContent {
  padding: 8px 0 8px 0;
  text-align: left;
  background-color: #f7f6f6;

  font-size: 0.9em;
  cursor: pointer;
}

.offCHeaderTitle {
  padding-bottom: 2px;

  font-size: 24px;

  line-height: 1.25;
  text-align: left;
  color: #000;
}
/* .PrivateTabIndicator-colorPrimary-14 {
      background-color: #e31837  !important;
  } */
.mobileheadTab {
  background-color: transparent !important;
  color: #000000 !important;
  box-shadow: none !important;
}
.bgImage {
  background-image: url(../../../Assets/Images/sectionBackground.png);
}
.activeTab {
  padding: 8px 0 8px 0;
  text-align: left;
  background-color: #e31837;
  box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12);

  font-size: 0.9em;
  color: #ffffff;
}
.m-1 {
  margin-top: 0.5em;
}
.tabTitle {
  margin: 0 0 0 0.6em !important;
}
.hoverEffect:hover,
.hoverEffect:active {
  background-color: #e31837;
  color: #ffffff;
}

.fontFamily-Saira {
  font-family: "Georama-Regular";
}
.orgCheckbox > span {
  margin-top: 0.2em;
}

.orgLeftDivTitle {
  margin-block-start: 0em;
  margin-block-end: 0em;
  font-size: 1.25rem;
  font-weight: 500;
  padding-left: 11px;
}
.leftDivContentTitle {
  color: #242424;
  font-size: 0.875rem;
  margin-block-end: 6px !important;
}
.positionOrg2 .canvasWrapper canvas {
  width: 66vw !important;
  border: 9px solid transparent;
  margin-left: -80px !important;
}
.leftDivContentDate {
  color: #6d6e71;
  font-size: 0.75rem;
}
.orgDateP
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
  margin-left: 23px !important;
}
.leftDivContentViews {
  font-size: 12px;
  font-weight: 600;
  color: #a7a9ac;
}
.orgImg1 {
  margin-right: 37px !important;
}
.orgimgDiv {
  display: flex;
  width: 134px;
}

/* org DropDown new css */
.sector
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  height: 48px !important;
}

/* .sector .MuiInputLabel-outlined.MuiInputLabel-marginDense {
  color: #000000;
  height: 24px;
  font-size: 14px;
  font-weight: 500;
  
  margin-left: 18px;
  margin-top: 7px;
} */

/* .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiOutlinedInput-adornedStart.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  padding-left: 32px !important;
  padding-top: 12px !important;
} */
/* .sector
  .MuiChip-root.MuiAutocomplete-tag.MuiAutocomplete-tagSizeSmall.MuiChip-sizeSmall.MuiChip-deletable {
  width: auto;
  max-width: 130px;
} */

.orgHeaderF
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-endAdornment {
  right: 16px;
}

.orgHeaderF .calenderOrg {
  padding: 8px !important;
}

.orgHeaderF .calenderOrg .MuiInputLabel-formControl {
  transform: translate(31px, 12px) scale(1.08) !important;
  margin-top: 2px !important;
}
.orgHeaderF .calenderOrg input#select-typt-file {
  padding-left: 4px !important;
}
.calenderOrg fieldset {
  border: none !important;
}
.orgHeaderF .monthCalender label#checkboxes-tags-demo-label {
  margin-top: 0px !important;
  margin-left: -4px !important;
}
.calenderOrg
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
  height: 48px !important;
}
.calenderOrg .MuiFormControl-root::before {
  background-image: url(../../../Assets/Images/collectiveIcons/calendar-line.svg) !important;
  top: 12px !important;
}
.calenderOrg .Mui-focused .MuiFormControl-root::before {
  background-image: url(../../../Assets/Images/collectiveIcons/calendar-fill.svg) !important;
}

.monthChangeDiv .MuiFormControl-root::before {
  background-image: url(../../../Assets/Images/collectiveIcons/calendar-line.svg) !important;
  top: 14px !important;
}
/* .monthChangeDiv .MuiInputLabel-formControl {
  margin-left: -4px !important;
} */
.monthChangeDiv input#checkboxes-tags-demo {
  margin-left: 16px !important;
}
/* .monthChangeDiv .MuiChip-root.MuiAutocomplete-tag.MuiChip-deletable {
  margin-left: 24px !important;
  margin-top: 4px !important;
} */
/* org DropDown new css */

@media screen and (max-width: 812px) {
  .commanButton {
    margin-bottom: 1em;
  }
}

@media screen and (max-width: 960px) {
  .positionOrg2 .canvasWrapper canvas {
    width: 58vw !important;
    border: 9px solid transparent;
    margin-left: -29px !important;
  }
  .orgHeaderF .calenderOrg input#select-typt-file {
    margin-left: 25px !important;
  }
  .orgHeaderF {
    margin: -8px !important;
  }
  .positionOrg1 {
    /* overflow-y: auto; */
    padding: 0;
  }
  .responsiveVerScroll {
    overflow-y: hidden !important;
  }
  .selectTagPosition {
    position: inherit;
  }
  .alignCardsItems2 {
    min-width: 200px;
    max-width: auto !important;
  }
  .offCHeaderTitle {
    margin-bottom: 16px !important;
  }
  .orgHeaderF {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .landingpageAlign {
    margin-top: 8px !important;
    /* background-color: transparent !important;
    box-shadow: none !important;
    padding: 0 0 0 0 !important; */
  }
  .positionOrg2 .pdfViewer .page {
    border: none !important;
    width: 199px;
    height: 152px;
  }
  .positionPdfContainer {
    width: 100%;
    padding-top: 8px;
  }
  .alignBtnRight {
    display: block;
    min-width: 280px;
  }
  .orgImg1 {
    margin-right: 16px !important;
    flex-basis: 10% !important;
  }
  .positionOrg2 iframe {
    position: relative;
    height: 480px;
    width: 100%;
    box-sizing: border-box;
  }
  .positionOrg1 {
    position: relative;
    /* height: 480px; */
    display: flex;
  }
  .orgimgDiv {
    width: auto;
  }
  .orgLeftContainer {
    max-width: 100% !important;
    min-width: auto !important;
  }
  .adminManage {
    justify-content: start;
  }
}
@media (max-width: 414px) {
  .alignBtnRight {
    padding-right: 35px;
  }

  .bgImageTemplate {
    padding-top: 70px;
  }

  .pagetutorialp {
    padding-top: 100px;
  }
}

@media (max-width: 411px) {
  .alignBtnRight {
    padding-right: 31px;
  }
}
@media (max-width: 375px) {
  .alignBtnRight {
    padding-right: 0px;
  }
}
@media (max-width: 360px) {
  .alignBtnRight {
    padding-right: 0px;
  }
}

/* sf css */
.orgDateP .MuiFormControl-root::before {
  content: "";
  height: 16px;
  width: 16px;
  top: 21px;
  left: 12px;
  position: absolute;
  background-image: url(../../../Assets/Images/orgC.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.orgHeaderF span.material-icons.MuiIcon-root {
  color: rgba(0, 0, 0, 0.37) !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]
  .MuiAutocomplete-input {
  /* padding: 4.5px 4px; */
  /* background: #f00; */
  position: absolute;
  width: 30% !important;
  display: inline-block;
  right: 30px;
}

.activeOrgContent {
  cursor: pointer;
  background-color: #f6eced;
}
.showMobileOrgAnn {
  display: none;
}
@media (min-width: 960px) {
  .shoWInDesktopOrgAnn {
    min-height: 760px;
    max-height: 760px;
    overflow-y: scroll;
    padding-bottom: 10px;
  }
}
@media (max-width: 960px) {
  .positionPdfContainer {
    display: none;
  }
  .showMobileOrgAnn {
    display: block;
  }
  .shoWInDesktopOrgAnn {
    display: none;
  }
}

/* css by ps org modal */

.Modalcontent {
  padding: 10px 15px;
}

.addorgpanel {
  overflow: auto;
  padding: 15px;
  background: #fff;
  margin-bottom: 15px;
}

.Modalcontent .MuiChip-deletable {
  max-width: 130px;
  margin-left: 30px;
}

.Modalcontent .MuiInputLabel-outlined.MuiInputLabel-marginDense {
  margin-left: 20px;
  margin-top: 4px;
}

.sector .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -7px) scale(0.75) !important;
  margin-left: 0px !important;
  margin-top: 0px !important;
}

.lastmodifiedpanel {
  padding: 10px 15px;
  /* margin-top: 8px; */
  background: #fff;
  margin-bottom: 10px;
}

.lastmodifiedpanel .detailsFontSize {
  margin: 0px;
}

.MuiDialog-paperFullWidth {
  width: calc(100% - 64px);
  overflow: hidden;
}

.Modalcontent .sector .MuiFormControl-root.MuiTextField-root {
  background-color: #fff;
  box-shadow: none !important;
}

.mahindraCollectiveSector
  fieldset.MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.Standardfilter .MuiAutocomplete-root {
  margin-bottom: 12px;
}

.Standardfilter .liveWireDropDownContainer .MuiChip-root {
  height: 26px !important;
  max-width: 130px;
  background: none;
}

.Standardfilter .liveWireDropDownContainer .MuiAutocomplete-tag {
  margin: 0px;
  font-size: 13px;
}

.Standardfilter .MuiInputLabel-outlined.MuiInputLabel-shrink {
  background: none !important;

  font-size: 14px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  /* color: #000000; */
  color: #6d6e71;
  display: none;
}

.Standardfilter .MuiInputBase-root {
  padding-left: 25px !important;
  padding-top: 6px !important;
}

.Standardfilter .MuiChip-deleteIcon {
  display: none;
}
