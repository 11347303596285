.justiFyCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.commonFontNormalStyle {
  
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.loginlogo img {
  width: 198px;
}
