.charLeftField {
  /* border: solid 1px #e4e4e4 !important; */
  /* background-color: #fbfbfb !important; */
  width: 100% !important;
}
.charsLeftText {
  
  font-size: 16px !important;
  line-height: 1.5 !important;
  color: #6d6e71 !important;
}

.inputTypeHeight
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
  height: 50px;
}

.inputTypeHeight
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
  margin-top: -5px;
}

.inputTypeHeight
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink {
  margin-top: -1px;
}

