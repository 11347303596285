.datePickerStyle {
    width: 100%;
    border: solid 1px #e4e4e4;
    /* background-color: #fbfbfb !important; */
    margin: 0px !important;
}
.MuiPickersDay-daySelected {
    background-color: #e31837 !important;
}
.MuiDialogActions-root > .MuiButton-label {
    color: #e31837 !important;
}
.MuiPickersToolbar-toolbar {
    background-color: #e31837 !important;
}
/* 

input[type="date"]::-webkit-datetime-edit-text {
  -webkit-appearance: none;
  display: none;
}
input[type="date"]::-webkit-datetime-edit-month-field {
  -webkit-appearance: none;
  display: none;
}
input[type="date"]::-webkit-datetime-edit-day-field {
  -webkit-appearance: none;
  display: none;
}
input[type="date"]::-webkit-datetime-edit-year-field {
  -webkit-appearance: none;
  display: none;
} */
