.pdfViewerContainer {
  height: inherit;
}

.pdfIframe {
  height: 100%;
  width: 100%;
}

.react-pdf__Page {
  margin-top: 10px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-height: 500px;
  overflow: auto;
}

.pdfViewerContainer .mobile__pdf__container #viewerContainer {
  bottom: 3rem !important;
  height: 78% !important;
}

.pdfViewerContainer .mobile__pdf__container #viewerContainer .pdfViewer {
  overflow: hidden !important;
}

.pdfViewerContainer .mobile__pdf__container footer {
  height: 3rem !important;
  position: absolute !important;
}

.pdfViewerContainer .mobile__pdf__container button#previous {
  background-size: 18px !important;
}

.pdfViewerContainer .mobile__pdf__container button#next {
  background-size: 18px !important;
}

.pdfViewerContainer .mobile__pdf__container button#zoomIn {
  background-size: 18px !important;
}

.pdfViewerContainer .mobile__pdf__container button#zoomOut {
  background-size: 18px !important;
}

.mobile__pdf__container #pageNumber{
  font-size: 22px !important;
}

/* .mobile__pdf__container #viewerContainer {
  overflow: hidden !important;
} */
