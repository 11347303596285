.policiesSearchTemplate {
  margin-bottom: 48px;
  box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12);
}
.policySearchName {
  
  font-size: 18px;
  font-weight: 600;
  padding-left: 40px;
  width: 30%;
}
.searchPoliciesName {
  
  font-size: 24px;
  font-weight: 600;
  margin-top: 32px !important;
}
.policySearchDescription {
  
  font-size: 14px;
  font-weight: normal;
  padding-right: 40px;
  width: 70%;
}
.policiesSearchHead {
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ededed;
  color: #000000;
}
li {
  line-height: 1.57;
  
  color: #242424;
}
ul {
  padding-left: 18px;
  margin: 0;
}
.downloadPDF {
  border-radius: 0px;
  width: 126px;
  height: 20px;
  flex-grow: 0;
  
  font-size: 16px;
  width: 222px;
  height: 48px;
  font-weight: 500;
  padding-right: 40px;
}
.PDFPosition {
  padding-right: 40px;
}
.PDFPosition > .commanButton > .commonButtonStyle > .MuiButton-label {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.alignPDFButton {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.slidingArrowSearchTemp {
  position: fixed;
  left: 0;
  top: 0;
  width: 24px;
  bottom: 176px;
  right: 0;
  display: flex;
  align-items: flex-end;
}
@media (max-width: 960px) {
  .policiesSearchHead {
    flex-direction: column;
  }
  .PDFPosition {
    width: 100%;
    padding: 0 16px !important;
  }
  .policySearchDescription {
    padding-left: 40px;
    width: 100%;
  }
  .policySearchName{
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .slidingArrowSearchTemp {
    display: none;
  }
}
