.universalSearchMultiselect > div > div > div > fieldset {
    border-color: rgba(0, 0, 0, 0) !important;
}
.searchFilterMultiSelect > div > div > div > fieldset {
    border-color: rgba(0, 0, 0, 0) !important;
}
input:-internal-autofill-selected {
    background-color: #ffffff !important;
}

/* .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #e4e4e4 !important;
    border-width: 1px !important;
} */
.MuiInputLabel-outlined.MuiInputLabel-shrink {
    background-color: #fff !important;
    border-left: none !important;
    border-right: none !important;
}
.MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.6) !important;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
    height: 48px !important;
}