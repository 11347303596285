.whatsNewAdminListContainer {
  padding: 12px 24px;
  background: #f7f6f6;
}

.whatsNewAdminTopSectionContainer {
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;
  padding: 0 30px 0 24px;
  margin-top: -16px;
}

.whatsNewAdminFilterContainer {
  padding: 24px 30px 0px 24px;
  background: #f7f6f6;
}

.historyDetail {
  display: flex;
  justify-content: space-between;
}

.modifierName {
  height: 24px;
  margin: 0px 92px 0 0;
  
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.modifiedBytxt {
  height: 20px;
  margin: 0 80px 0 0;
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #6d6e71;
}

.accordionContainer {
  /* min-height: 80px; */
  flex-grow: 0;
  margin-bottom: 10px !important;
  box-shadow: 0 4px 31px 0 rgb(0 0 0 / 10%) !important;
  background-color: #ffffff;
}
.addServicesBg {
  background-color: #ffffff !important;
}
.accordionStyle {
  /* height: 26px;
  margin: 12px 0 0; */
  
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  text-transform: capitalize;
}

.actionTxt {
  height: 20px;
  
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #e31837;
  cursor: pointer;
  text-transform: uppercase;
}
.alignCommonLabelWhatsNewAdmin
  > div
  > div
  > .MuiInputLabel-outlined.MuiInputLabel-shrink {
  padding: 0 8px !important;
}
.alignCommonLabelWhatsNewAdmin > div > div {
  background-color: #ffffff !important;
}
.datePickerStyle > .MuiInputLabel-outlined.MuiInputLabel-shrink {
  padding: 0 8px !important;
}
.fullWidth > .MuiInputLabel-outlined.MuiInputLabel-shrink {
  padding: 0 8px !important;
}
/* .setscroll {
  padding-right: 11px;
  overflow-y: scroll;
  max-height: 270px;
} */

.paddingRight40 {
  padding-right: 16px;
}
.mb15 {
  margin-bottom: 15px;
}

.lableClass {
  /* height: 20px;
  margin: 4px 0 0; */
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #6d6e71;
}

/*.Mui-expanded > .mainAccContainer > .lableClass {
  display: none;
}*/

.whatsNewAdminContainer .MuiAccordionSummary-expandIcon.Mui-expanded {
  color: #e31837;
}


/* Css by Ps */

.MuiAccordionSummary-root {
  padding: 16px;
  min-height: initial;
}

.MuiAccordionSummary-content {
   margin: 0;
}

.MuiAccordion-root:before {
  background: none !important;
}

.savebtn
{margin-left:50px;}