.mt24 {
  margin-top: 24px;
}
.rollBasedPaperInnnerContent > div > div > div > div > div > button {
  margin: 0 !important;
}
.pl0 {
  padding-left: 0 !important;
  padding-top: 0 !important;
  padding-right: 0 !important;
}
.rollMatrixTabContent > div > div > div > .MuiTab-root {
  margin: 0 !important;
}
.rollMatrixTabContent > div > .MuiBox-root {
  padding: 0 !important;
}
.rollBasedPaperInnnerContent > div > div > div > p > div {
  box-shadow: none !important;
}
.rollMatrixColumnGap {
  column-gap: 8px;
  margin-top: 15px !important;
}
.alignDeleteIcon {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  height: 80px;
}
.pt0 {
  padding-top: 0 !important;
}
.saveAddNewUserGroup {
  margin: 24px 0 !important;
}
.borderHR {
  width: calc(50.7em + 93px);
  position: relative;
  left: -24px;
  border: 1px solid #ededed;
  margin: 24px 0;
}
.PadBotomNon > div {
  padding-bottom: 0 !important;
}
.rollBasedAlignHeadTitle {
  margin-bottom: 16px;
  font-weight: 700;
  /* background: #e31837; */
  border-bottom: 2px solid #e31837;
  text-align: center;
  color: #e31837;
  padding: 10px;
  
}
.addRightBorder {
  border-right: 1px solid #6f7881;
}
.pt8 {
  padding-top: 8px;
}
.rollBasedAddNewUserGrp {
  margin: 16px 0 !important;
  padding: 24px 16px !important;
  box-shadow: 0 4px 31px 0 rgba(0, 0, 0, 0.1) !important;
}
.rollBasedSubmitPanel {
  margin: 24px 0 0 0px !important;
}

.rollMatrixBulkUploadModal .MuiGrid-root.pt8.MuiGrid-container.MuiGrid-spacing-xs-3.MuiGrid-align-items-xs-center{
  width: 100% !important;
  padding: 24px !important;
}
.rollMatrixBulkUploadModal .modalBody {
  height: 300px !important;
}
.rollMatrixcsvBtn{
  padding-top: 8px ;
}

.rollDownloadUserGtn{
  width: 23px !important;
}
.csvRollUserG{
  text-decoration: none;
  color: #fff;
  background-color: #e31837;
  width: 100px;
  height: 48px;
  text-align: center;
  padding: 12px;
  
}

.csvRollUserG:hover {
  color: #fff !important;
}