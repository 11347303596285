.Econtent .TableContent {
    text-align: left;
}

.fontboldnew
{font-weight: 700;font-size:16px}

span.emailidfield {
    color: #1592e6;
}

.notevalue {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.mr-15
{margin-right: 15px;}

.scrollpanel {
    min-height: 400px;
    max-height: 650px;
    overflow-y: scroll;
    padding-right: 15px;
    margin-bottom: 25px;
    padding-bottom: 15px;
}

 /* .Econtent ol { counter-reset: item }  */
 .Econtent ol li{ line-height: 22px; margin:10px 0px; } 
 /* .Econtent ol.listtitleinside li:before { content: counters(item, ".") " "; counter-increment: item } */
.scrollpanel table {
   margin: 10px 0px;
   width: 100%;
}

.scrollpanel td {
    border: #dadada 1px solid;
    padding: 10px;
}

.Policybtn
{background: #f00; border: 0px; color: #fff; font-size: 14px;}