.addFavTabTitle {
  margin: 0;
}
.modalCommonHeadText {
  font-size: 14px;
  font-weight: 600 !important;
  line-height: 1.33;
  color: #000;
}
.favTabModal {
  padding-top: 15px;
    padding-right: 24px;
    padding-left: 24px;
}
.AddFevSearchButton {
  padding-top: 31.9px;
  padding-bottom: 20.6px;
}
.alignFavModelTab {
  position: relative !important;
  right: 23px !important;
}
.SearchBar > div > fieldset {
  border-color: rgba(0, 0, 0, 0) !important;
  /* box-shadow: 0 4px 31px 0 rgb(0 0 0 / 10%) !important; */
}
