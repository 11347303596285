.tablescroll {
  max-height: 300px;
  overflow-y: scroll;
  border: #dadada 1px solid;
}

.rollBasedTitle {
  font-size: 16px;
}

.notepanel ul {
  padding: 15px;
  margin: 0px;
}

.notepanel li {
  list-style: none;
  font-size: 12px;
}

.Forexdatepicker .MuiInputBase-input {
  height: 0.69em;
}

.weekdata {
  background: #f1f0f0;
  font-weight: 600;
  text-align: left !important;
}

.tableheader .TableContent {
  font-weight: 600;
}

.tablescroll {
  height: 400px !important;
  overflow-y: auto;
}

.tablescroll .MuiGrid-item:first-child .TableContent {
  text-align: left;
}

.norecordfound .TableContent {
  text-align: left;
}

.tableheader .MuiGrid-item:first-child .TableContent {
  text-align: left;
}

/* .tableheader .MuiGrid-item .TableContent{
    text-align: left;
} */

.TableContent {
  display: block;

  font-size: 12px;
  font-weight: normal;
  color: #242424;
  padding: 10px 24px;
  text-align: right;
}

.csvdownload {
  display: flex;
  justify-content: flex-start;
  margin-left: 24px;
}
.csvdownloadbutton {
  width: 110px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #e31837;
  padding: 4px 10px;
  height: 40px;
  font-size: 12px;
  color: #fff;
}
