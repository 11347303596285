.mainContainer {
  /* margin-bottom: 4em; */
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  height: 88vh;
  overflow-y: auto;
}
.notificationsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}
.scrollHide > div {
  width: 488px;
  overflow-y: hidden !important;
  margin-top: 80px !important;
}
.doNotDistrubContainer > div > label {
  padding-left: 24px !important;
}
.doNotDistrubContainer > span {
  padding-right: 24px !important;
}
.notificationTitle {
  /* width: 180px; */
  height: 28px;
  flex-grow: 0;
  
  font-size: 24px !important;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17 !important;
  letter-spacing: normal !important;
  text-align: left;
  color: #000;
}
.notContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.importantNotificationContainer {
  display: flex;
  justify-content: space-between;
  background-color: #f7f6f6;
  align-items: center;
  height: 64px;
}
.contentPadd {
  padding: 0px 16px;
}
.alignNotificationText {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
}

.errorOutlineIcon {
  width: 24px;
  height: 24px;
  color: #e31837;
}

.impNotificationRed {
  /* width: 161px; */
  height: 22px;
  flex-grow: 0;
  
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: right;
  color: #e31837;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

img.arrowDownIcon {
  height: 16px;
  width: 16px;
  padding-left: 8px;
}

.dayStyle {
  /* width: 40px; */
  height: 22px;
  flex-grow: 0;
  
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #a7a9ac;
  margin-top: 12px;
}

.subTaskAndDateContainer {
  height: 19px;
  flex-grow: 0;
  
  font-size: 12px;
  padding: 12px 0 0 0;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7c3520;
  display: flex;
  justify-content: space-between;
}

.dateTimeColor {
  /* width: 48px; */
  height: 20px;
  flex-grow: 0;
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #6d6e71;
}

.notificationMessage {
  width: 265px;
  max-height: 44px;
  flex-grow: 0;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  padding: 8px 0px 0px 24px;
}

.linkTextContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;
}

.linkButtonText {
  text-transform: capitalize;
  /* width: 145px; */
  height: 22px;
  flex-grow: 0;
  
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  /* text-align: left; */
  color: #e31837;
  margin-left: 5px;
}

.doNotDistrubContainer {
  height: 32px;
  flex-grow: 0;
  
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.29;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
  background-color: #f7f6f6;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.notificationCloseIcon {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.cursorP {
  cursor: pointer;
}
.notificationsModalText {
  display: inline-block;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 1.33 !important;
  color: #000 !important;
  margin: 0px 0px 16px 24px;
}

.textBold {
  font-weight: bold;
}

.MuiFormControlLabel-labelPlacementStart {
  margin-left: 0 !important;
}
.notificationsTabs {
  margin: 0 0 0px 0px;
}

.autoScroll {
  overflow: auto;
}

.notificationTxt {
  width: 152px;
  height: 36px;
  flex-grow: 0;
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  padding: 0px 0px 0 16px;
}

.errorWarningIcon {
  height: 24px;
  width: 24px;
}

.rowSaparator {
  background-color: #ececec !important;
  margin-top: 18px !important;
}

.listSaparator {
  width: 480px;
  border: none;
  height: 1px;
  margin-top: 11px;
  margin-bottom: 0px;
  flex-grow: 0;
  margin-left: -18px;
  background-color: #ececec;
}

span.MuiSwitch-track {
  background-color: #d3d3d3;
}

.Engage {
  width: 222px;
  height: 38px;
  flex-grow: 0;
  
  padding-left: 6px;
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: normal;
  text-align: left;
  color: #6d6e71;
}
.doNotDistrubContainer
  > .MuiSwitch-colorSecondary.Mui-checked
  + .MuiSwitch-track {
  background-color: #f1ccd0;
}

.doNotDistrubContainer > .MuiSwitch-colorSecondary.Mui-checked.MuiSwitch-thumb {
  color: #e32e36 !important;
}

.padRight {
  padding-right: 24px;
}
.notificationsMsg {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}


@media (max-width: 1024px) {
  .scrollHide > div {
    width: 100%;
  }
  .notificationTitle {
    font-size: 20px !important;
  }
  
  .notificationsHeader {
    padding: 0px 0 !important;
    width: 100%;
    margin-top: 15px;
}
  .notificationImpText {
    padding: 0 8px;
  }
  .responsiveAlign {
    padding: 0 16px !important;
  }
  .importantNotificationContainer {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .notificationTxt {
    width: 100%;
  }
  .contentPadd {
    padding: 0;
    padding-bottom: 25px;
  }
  .alignNotificationText {
    padding: 0 0 0 9.3px;
    align-items: flex-start;
    margin-top: 12px;
  }
  .notificationMessage {
    padding: 4px 0px 0px 24px;
  }
  .linkTextContainer {
    margin-top: 8px;
  }
  .linkTextContainer {
    justify-content: flex-start;
    margin-top: 14px;
    padding-left: 25px;
  }
  /* .dayStyle {
    padding-top: 16px;
  } */
  .notificationMessage {
    height: auto;
  }
  .notificationTxt {
    padding: 0px 0px 0 8px;
  }
  .notificationTxt {
    height: 0;
  }
  .errorWarningIcon {
    height: 16px;
    width: 16px;
  }
  .listI {
    padding: 0 16px;
  }
  .autoScroll {
    overflow-x: hidden;
  }
  .impNotificationRed {
    padding: 0 0 0 34.3px;
    align-items: flex-start;
    padding-top: 4px;
    align-items: center;
  }
  .doNotDistrubContainer {
    position: fixed;
    width: 100%;
    bottom: 0;
  }
}

@media (max-width: 960px) {
  .scrollHide > div {
    margin-top: 80px !important;
  }
}

@media (max-width: 560px) {
  .scrollHide > div {
    margin-top: 48px !important;
  }
}
