.textareastyle {
  width: 100%;
  height: 400px !important;
  resize: none;
  padding: 1em 0 0 16px;
  overflow-y: auto !important;
  background-color: #fbfbfb !important;
  border: solid 1px #ededed !important;
  font-size: 16px;
}
.textareastyle::placeholder {
  height: 24px;
  font-size: 16px;
  line-height: 1.5 !important;
  text-align: left;
  color: #6d6e71;
}
