.accLabletxt {
  height: auto;
  font-size: 14px;
}

.accTitletxt {
  height: 24px;
  margin: 12px 0px 8px 0;
}

.pr8 {
  padding-left: 8px !important;
}

.pl8 {
  padding-right: 8px !important;
}

/* .setscrollItAdmin {
  padding-right: 11px;
  overflow-y: scroll;
  max-height: 360px;
} */

.ItSupportAreaContainer {
  padding: 24px 13px 24px 24px;
  background: #ededed;
}

.mb16 {
  margin-bottom: 16px !important;
}

.ItSupportAreaContainer .MuiButtonBase-root.MuiIconButton-root {
  color: #e31837;
}

.contentPadding {
  padding: 8px;
}

/* .ItSupportAreaContainer .MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
} */

.Complete-Tax-Declara {
  width: 668px;
  height: 24px;
  flex-grow: 0;
  
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
}

.contactsField {
  width: 96px;
  height: 24px;
  flex-grow: 0;
  margin: 0 16px 0 0;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
}

.contactContainer {
  display: flex;
}

.add {
  width: 25px;
  height: 22px;
  flex-grow: 0;
  margin: 1px 0 1px 4px;
  
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: left;
  color: #db233a;
}

.ItSupportAreaContainer .accordionContainer .MuiAccordionDetails-root {
  padding: 8px 8px 16px !important;
  margin-top: -70px;
}

.ItSupportAreaContainer .MuiAccordionSummary-content.Mui-expanded {
  visibility: hidden;
}

.centerIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Delete-Service {
  width: 96px;
  height: 22px;
  flex-grow: 0;
  
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: left;
  color: #db233a;
  float: right;
  cursor: pointer;
}

.ItSupportAreaContainer .MCsubmitbutton {
  width: 180px;
}

.customSelectBox {
  position: absolute;
  width: 578px;
  margin-top: 8px;
  z-index: 2;
  max-height: 368px;
  min-height: 186px;
  box-shadow: 0 10px 40px 0 rgb(0 0 0 / 12%);
  background-color: #ffffff;
  display: block;
}

.upperCharLeft > div > div > div::after {
  height: 20px;
  width: 20px;
  content: "";
  background-image: url("../../../../../Assets/Images/WhatsNewIcons/Chevron\ _\ Up.svg");
  cursor: pointer;
}

.uploadImg {
  display: flex;
  padding-top: 16px;
  justify-content: space-around;
  align-items: center;
  flex-grow: 0;
  margin: 0 54px 2px;
  
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: normal;
  text-align: left;
  color: #db233a;
}

.recommendatinTxt {
  height: 20px;
  flex-grow: 0;
  margin: 8px 0 0;
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #6d6e71;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mt25 {
  margin-top: 25px;
}

.addScrollCls {
  max-height: 230px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.optRoot {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 0 10px 0;
}

.Tasks-that-need-your {
  height: 22px;
  flex-grow: 0;
  margin: 1px 0 1px 16px;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
}

.addLocBtn {
  color: #db233a;
  height: 22px;
  margin: 0 0 0 60px;
  
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;
}

.setscrollItAdmin .MuiGrid-container {
  align-items: center;
}

.iTMulti
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  height: 56px !important;
}

.imageUrlFont {
  font-size: 12px !important;
}
.existingImage {
  height: 40px;
  width: 40px;
}
