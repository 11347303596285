.adminCardAlign {
  width: 100%;
  padding: 14px 32px 17px 32px;
  box-shadow: none !important;
  margin-bottom: 15px;
}
.adminCardTitle {
  
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  height: 24px;
  margin-bottom: 8px;
}
.profileWidth {
  width: 24px;
  height: 24px;
}
.AdminCardDaysTogo {
  
  font-size: 12px;
  color: #6d6e71;
  height: 24px;
  margin-bottom: 8px;
}
.adminCardSubTitle {
  
  font-size: 14px;
  font-weight: normal;
  color: #000000;
  height: 22px;
  margin-bottom: 16px;
}
.adminCardProfileName {
  
  font-size: 12px;
  color: #242424;
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.adminCardView {
  
  font-size: 12px;
  font-weight: 600;
  color: #e31837;
  display: flex;
  align-items: center;
  column-gap: 8px;
  text-transform: uppercase;
  cursor: pointer;
}

span.MuiCheckbox-root.MuiCheckbox-colorPrimary {
  color: #f50057 !important;
}
