.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root.css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root {
    background-color: #fff !important;
    box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12) !important;
    border-radius: 0px !important;
}
.MuiSnackbarContent-message.css-1exqwzz-MuiSnackbarContent-message {
    
    color: #000;
}

.sB {
    width: auto !important;
    height: auto !important;
    box-shadow: 1px 1px 81px 2px rgb(0 0 0 / 42%) ;
    -webkit-box-shadow: 1px 1px 81px 2px rgb(0 0 0 / 42%) ;
    -moz-box-shadow: 1px 1px 81px 2px rgba(0,0,0,0.42) ;
    border-radius: 50%;
}

.sB .MuiPaper-root.MuiPaper-elevation {
    padding: 0px !important;
    width: auto !important;
    display: block !important;
}

.sHeader,.sHeader1{
    padding: 16px !important;
}

.sHeader1{
    display: flex;
}

.bloodBank{

    bottom: 30vh !important;

    left: 16vh !important;

}