.universalDataSearchTable {
  margin-top: 40px;
  margin-bottom: 16px;
  padding: 16px;

  box-shadow: 3px 20px 52px 0 rgba(0, 0, 0, 0.12) !important;
}
.UniSearchCard {
  height: 710px;
  border: 1px solid #ededed;
  overflow-y: auto !important;
  box-shadow: none !important;
  padding-top: 8px !important;
}
.searchCat {
  
  font-size: 20px;
  font-weight: 500;
  padding: 0 16px 8px 16px;
  margin: 0 !important;
  border-bottom: 1px solid #ededed;
}
.searchCatFilter {
  
  font-size: 16px;
  color: #6d6e71;
  font-weight: normal;
  padding: 0 16px 8px 16px;
  margin: 0 !important;
  border-bottom: 1px solid #ededed;
}
.filterSearchCheckbox {
  padding: 8px 16px 8px 16px;
  border-bottom: 1px solid #ededed;
}
.filterSearchData {
  padding: 16px;
  border-bottom: 1px solid #ededed;
}
.USearchFilterTitle {
  
  font-size: 20px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 8px;
}
.USearchFilterDesc {
  
  font-size: 16px;
  font-weight: normal;
}
.width100 {
  width: 100%;
}
.USearchTab {
  
  font-size: 12px !important;
  font-weight: normal !important;
  padding: 6px 16px !important;
  border: solid 1px #e31837 !important;
  color: #e31837 !important;
  background-color: rgba(227, 24, 55, 0.04) !important;
  min-width: 70px !important;
  text-transform: none !important;
  border-radius: 0 !important;
}
.USearchPagination {
  padding: 16px;
  padding-left: 0 !important;
  padding-bottom: 0 !important;
}
.HV110 {
  height: 110px;
  width: 110px;
}
.paginationAlignFlex {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.arrowPos {
  position: relative;
  top: 6px;
}
.paginationNumbers {
  background-color: #ffffff;
  box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.1);
  color: #242424;
  height: 40px;
  width: 40px;
  
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
.showPagCount {
  
  font-size: 14px;
  font-weight: normal;
  line-height: 1.29;
  color: #6d6e71;
}
.searchFilterMultiSelect > div {
  border: solid 1px #e4e4e4 !important;
  background-color: #ffffff !important;
}
.searchFilterMultiSelect > div > div > label {
  
  font-size: 16px !important;
  color: #6d6e71 !important;
}
.searchFilterMultiSelect > div > div > div {
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  height: 46px;
}
.alignTopUSearch {
  margin-top: 24px !important;
}
.searchImg {
  width: 100%;
  height: 50%;
}


.USearchFilterDescInner {
  
  font-size: 12px;
  font-weight: normal;
}
