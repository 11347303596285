.mt4 {
    margin-top: 32px;
}

.mt4 .editIcon {
    flex-grow: 0;
    
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    text-align: right;
    color: #e31837;
    height: 38px;
    align-items: center;
}


.supportContainer {
    margin: 16px 0;
}

.supportMainContainer {
    flex-grow: 0;
    padding: 12px 24px;
    box-shadow: 3px 20px 52px 0 rgb(0 0 0 / 12%);
    background-color: #ffffff;
}

.serviceCardHeader {
    height: 24px;
    flex-grow: 0;
    margin: 0px;
    
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #6d6e71;
}

.serviceCardData {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    margin: 8px 0px 16px;
    padding: 12px;
    background-color: #f7f6f6;
    word-break: break-all;
}

.dataName {
    height: 24px;
    flex-grow: 0;
    margin: 0 16px;
    
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    word-break: break-word;
}

.dataDesc {
    height: 72px;
    flex-grow: 0;
    margin: 0 49px 4px 16px;
    
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: left;
    color: #242424;
}

.dataContactDetail {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* gap: 4px; */
    padding: 0;
}

.serviceNameContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    align-content: center;
}

.linkStyl {
    height: 24px;
    flex-grow: 0;
    
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #e31837;
    text-transform: uppercase;
    cursor: pointer;
}

.contactData {
    flex-grow: 0;
    
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}

.supportSectionContainer .editIcon {
    justify-content: flex-end !important;
}
.supportServicesIcon {
    height: 24px;
    width: 24px;
    border-radius: 100%;
}

/* .buSectorITsupportPage > div > div > div > fieldset {
  background-color: rgb(251, 251, 251);
} */


/* drop-down csss */
.supportServiceSector .MuiFormControl-root::before {
    content: "";
    height: 24px;
    width: 24px;
    top: 12px;
    left: 6px;
    position: absolute;
    background-image: url(../../../../Assets/Images/building.svg);
    background-repeat: no-repeat;
    background-position: center;
}
.supportServiceSector .MuiInputLabel-outlined.MuiInputLabel-marginDense {
    margin-left: 16px !important;
    margin-top: 4px !important;
}
.supportServiceSector .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(-4px, -9px) scale(0.75) !important;
}
.supportServiceSector input.MuiInputBase-input.MuiOutlinedInput-input {
  margin-left: 20px !important;
  margin-top: 4px !important;
}
.supportServiceSector .MuiChip-root.MuiAutocomplete-tag.MuiAutocomplete-tagSizeSmall.MuiChip-sizeSmall.MuiChip-deletable {
  width: 130px !important;
}
/* drop-down csss */
.pertext
{
    
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #242424;
  margin-right: 10px;
}

.email{
    display: block;
}
@media (max-width: 960px) {
    .dataDesc {
        margin: 8px 0 0 0;
    }
    .supportServiceDropCss .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
        width: 105% !important;
    }
    .searchSupportServiceBanner .commanButton {
        margin-bottom: 0px !important;
    }
}

@media (max-width:600px) {
    .ssMB{
        margin-bottom: 16px !important;
    }
}