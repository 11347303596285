.cardShadow {
  box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.1) !important;
}
.McCardImage {
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  height: 12em;
}
.tipsBtn {
  border: solid 1px #e31837 !important;
  border-radius: 0px !important;
  background-color: rgba(227, 24, 55, 0.04) !important;
  font-size: 12px !important;

  color: #e31837 !important;
  text-transform: capitalize;
}
.McCardDescription {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  line-height: 1.57;
  /* text-align: justify; */
  margin: 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  height: 1.5em;
  -webkit-box-orient: vertical;
}
.McCardDescriptionmldl {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  line-height: 1.57;
  /* text-align: justify; */
  margin: 0 !important;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  height: 1.5em;
  -webkit-box-orient: vertical;
  margin-bottom: 20px !important;
}
.cardAction {
  padding: 1em;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1em;
  align-items: center;
}
.actionContent {
  font-size: 0.8em;

  color: #6d6e71;
}
.McActionContent {
  color: #a7a9ac;
  display: flex;
  column-gap: 1em;
}
.MCiconWidth {
  width: 24px !important;
  height: 24px !important;
}
.status {
  font-size: 0.8rem;

  font-weight: 500;
  line-height: 1.67;
  color: #000;
  display: flex;
  align-items: center;
  column-gap: 8px;
}

.McCardDesc {
  font-size: 14px;
  line-height: 1.56;
  /* text-align: justify; */
  color: #242424;
  margin: 0.5em 0 0 0 !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  height: 3em;
  -webkit-box-orient: vertical;
}
.McActionName {
  font-size: 12px;
  font-weight: 500;
  color: #242424;
  margin: 0 !important;
}
.mcActionDate {
  font-size: 12px;
  line-height: 1.67;
  color: #6d6e71;
  margin: 0 !important;
}
.actionItemAlign {
  display: flex;
  column-gap: 0.6em;
  align-items: center;
}
.McAvatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.shareIcon {
  width: 24px;
  cursor: pointer;
}

.shareMenuItems {
  width: 210px;
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 8px;
  cursor: pointer;
}
.papperClass {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* margin: 9px 0px 0 75px; */
  padding: 12px 8px;
  object-fit: contain;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
}

.shareMenuItems {
  width: 210px;
  height: 40px;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  padding: 8px;
}
.papperClass {
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  /* margin: 9px 0px 0 75px; */
  padding: 12px 8px;
  object-fit: contain;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
}
.actionItemBtn button.MuiButtonBase-root.MuiIconButton-root {
  padding: 4px !important;
}

.remoteCardtextHeader {
  font-size: 18px;
  font-weight: 600;

  margin-bottom: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.remoteCardsubtextHeader {
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.card-media {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(0, 0, 0);
  height: 12em;
  overflow: hidden;
}
