/* .favTabs > span {
  
  font-size: 1rem !important;
  font-weight: 600 !important;
  line-height: 1.5;
  color: #6d6e71 !important;
} */
.tabsPanelContainer {
  height: 72px;
  padding: 0 16px 0px 32px;
  background-color: #f7f6f6 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #242424;
  box-shadow: none !important;
}
.addMoreFev {
  height: 72px;
  background-color: #f7f6f6 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #242424;
  box-shadow: none !important;
}
.favtab {
  padding-left: 0 !important;
}
.PrivateTabIndicator-colorSecondary-5 {
  background-color: #e31837 !important;
}
/* .heightFevTabScroll {
  height: 45vh;
  overflow-y: auto;
} */
.addLeft0 {
  height: 72px;
  padding: 0 16px 0px 0px;
  background-color: #f7f6f6 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #242424;
  box-shadow: none !important;
}
.alignFavModalBlocks {
  height: 56px;
  padding: 0 16px 0px 24px;
  background-color: #fff !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #242424;
  box-shadow: none !important;
}
.alignFavModalBlocksSelected {
  height: 56px;
  padding: 0 16px 0px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: none !important;
  background-color: #f6eced !important;
  color: #242424 !important;
}
/* .alignFavModalBlocksSelected .iconImage {
  filter: brightness(0) invert(1);
} */
.borderAddMore {
  border: 1px dashed;
  border-color: #a7a9ac;
}
.cursorMove {
  cursor: pointer;
}
.MuiTab-wrapper {
  
  text-transform: none;
}
.tabBorderDashed {
  border: dashed 1px;
  border-color: #a7a9ac;
}
.Mui-selected {
  color: #e31837 !important;
  font-weight: 600 !important;
  
}

.addMore {
  border: dashed 1px #a7a9ac;
}
.MuiTab-wrapper {
  font-size: 1rem !important;
}
.favBoxIconAlign {
  display: flex;
  align-items: center;
  column-gap: 12px;
  font-weight: normal;
}
.favBox1 {
  
  font-size: 1rem;
  line-height: 2;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}
.textTransform {
  text-transform: uppercase !important;
  color: #e31837 !important;
  font-weight: 500;
}
.addMore span {
  color: #e31837;
}
.alignFavModelBoxes {
  padding: 20.6px 0 16px 0;
}
