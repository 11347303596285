.rejectionHeader {
  width: 82px;
  height: 20px;
  flex-grow: 0;
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #6d6e71;
}

.rejectionNote {
  height: 24px;
  flex-grow: 0;
  
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.uploadBtnStyle .uploadBtn {
  height: 56px;
  flex-grow: 0;
  border: solid 1px #e4e4e4;
  /* background-color: #fbfbfb; */
  cursor: pointer;
}

.uploadBtn .uploadBtnLabel {
  width: 250px;
  padding: 19px 16px;
  position: absolute;
  flex-grow: 0;
  
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: left;
  color: #6d6e71;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  height: 0em;
}

.uploadBtnLabel .colRed {
  color: #e31837;
}

.uploadBtn .uploadIconStyl {
  float: right;
  padding: 16px;
}

.compatiblityTxt {
  /* width: 115px; */
  height: 60px;
  flex-grow: 0;
  margin: 0 0 0 20px;
  
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #6d6e71;
  display: flex;
  align-items: center;
}
.inputWidthTitle
  > div
  > div
  > div
  > input.MuiInputBase-input.MuiOutlinedInput-input {
  width: 80% !important;
}
.uploadSector fieldset {
  top: 0px !important;
}

.uploadSector
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd.MuiInputBase-marginDense.MuiOutlinedInput-marginDense {
  height: 56px !important;
}
.uploadSector .MuiFormLabel-root {
  margin-top: 8px !important;
}
.uploadSector .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75);
  margin-top: 0px !important;
}

.uploadSector .MuiChip-root.MuiAutocomplete-tag.MuiAutocomplete-tagSizeSmall.MuiChip-sizeSmall.MuiChip-deletable {
  width: 115px !important;
}

@media (max-width: 1024px) {
  .uploadBtn .uploadBtnLabel {
    width: 105px !important;
  }
}

.boxwithlimit input {
  width: 100%;
  padding-right: 18px;
}
