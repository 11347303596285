.labelInsideField {
  
  font-size: 14px !important;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  /* color: #000000; */
  color: #6d6e71;
}
.mandatory {
  color: #e31837 !important;
  padding-left: 4px;
  display: inline-block;
}

