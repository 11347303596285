.droplet {
  background-image: url("../../../../Assets/Images/BloodBank/white-drop.svg");
  width: 60px;
  height: 75px;
  text-align: center;
  margin-right: 32px;
  margin-top: 8px;
}
.bloodgroup {
  padding-top: 35px;
  padding-bottom: 15px;
  
  font-weight: 400;
  font-size: 14px;
  color: #242424;
}

.bloodbank .MuiInputLabel-outlined.MuiInputLabel-marginDense {
  color: #6d6e71 !important;
  /* height: 24px; */
  font-size: 14px;
  font-weight: 500;
  
  margin-left: 0px !important;
  margin-top: 7px;
}
.bloodbank .MuiInputBase-root {
  color: #6d6e71 !important;
}
.bloodbank .MuiAutocomplete-root.MuiAutocomplete-hasPopupIcon {
  height: 100% !important;
}
.bloodbank label#checkboxes-tags-demo-label {
  margin-left: 20px;
  font-size: 14px;
  
  font-weight: 500;
  color: black;
}
.bloodbank label#checkboxes-tags-demo-label:active {
  color: #242424 !important;
}

.bloodbank
  .MuiButtonBase-root.MuiChip-root.MuiChip-filled.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-filledDefault {
  margin-left: 20px;
  width: 150px !important;
}
.bloodbank .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -12px) scale(0.75) !important;
}
.bloodbank
  .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd {
  height: 56px !important;
}
.bloodbank span.MuiAutocomplete-tag.MuiAutocomplete-tagSizeMedium {
  margin-top: 0px !important;
}
.bloodbank .labelInsideField {
  font-size: 14px !important;
  line-height: 0.27 !important;
}
.bloodbank label#select-typt-file-label {
  font-weight: 500 !important;
}
.bloodbank .labelInsideField {
  /* margin-left: 20px !important; */
  margin-top: -10px !important;
}
.bloodbank
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input:first-child {
  padding-left: 5px !important;
  padding-bottom: 8px !important;
}
.bloodbank .MuiFormControl-root.MuiTextField-root {
  background-color: #fff;
  box-shadow: none;
}
.bloodbank .MuiIconButton-root {
  margin-top: 5px !important;
}
.bloodbank
  .MuiInputBase-root.MuiOutlinedInput-root.MuiAutocomplete-inputRoot.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd {
  height: 48px !important;
}

.bloodbank .MuiInputLabel-outlined.MuiInputLabel-marginDense {
  color: #000000;
  height: 24px;
  font-size: 14px;
  font-weight: 500;
  
  margin-left: 18px;
  margin-top: 7px;
}
.bloodbank
  .MuiChip-root.MuiAutocomplete-tag.MuiAutocomplete-tagSizeSmall.MuiChip-sizeSmall.MuiChip-deletable {
  width: 130px !important;
}

.bloodHeading {
  
  font-size: 24px;
 
  line-height: 1.2;
  color: black;
}
.requestBloodTxt {
  height: 24px;
  float: right;
  flex-grow: 0;
  
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: left;
  color: #e31837;
  cursor: pointer;
  text-transform: uppercase;
}

.bbContainer {
  flex-grow: 0;
  padding: 24px;
  margin: 0;
  box-shadow: 0 6px 35px 0 rgb(0 0 0 / 10%);
  background-color: #fefdfd;
}

.bbHeaderTxt {
  height: 32px;
  flex-grow: 0;
  margin: 0 0 24px 0;
  
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.bbCheckboxContainer .MuiFormControlLabel-root {
  margin-left: -6px;
  margin-top: 0px;
}

.Line-15 {
  height: 1px;
  flex-grow: 0;
  background-color: #ededed;
}

.btnAlignment {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 34px;
}

.emergencyContactContainer .MuiCheckbox-root {
  color: #e31837 !important;
}
.emergencyContactContainer .MuiCheckbox-colorPrimary.Mui-checked {
  color: #e31837 !important;
}

.P1BB span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  margin-left: -8px;
}

/*page2*/
.bbHeaderP2Txt,
.bbHeaderTxtP3 {
  flex-grow: 0;
  margin: 0 0 8px 0;
  
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.bbP2HeaderContainer1 {
  padding: 24px;
  flex-grow: 0;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.1);
}
.bbP2HeaderContainer2 {
  padding: 24px;
  flex-grow: 0;
  box-shadow: 0 6px 35px 0 rgba(0, 0, 0, 0.1);
}

.bbHeaderP2SubTxt,
.bbHeaderP2SelectGroup {
  color: #6d6e71;
  font-size: 14px;
  
  line-height: 1.71;
}

.imgContainer {
  margin: 24px 0px;
  display: flex;
  flex-wrap: wrap;
}

.imgMr {
  margin-right: 32px;
  margin-top: 8px;
}

.emergencyContactContainer .MuiFormControl-root.MuiFormControl-fullWidth {
  background-color: #fff !important;
}

.emergencyContactContainer
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd {
  background-color: #fff;
}

/*page3*/
.bbHeaderP2SelectGroup {
  margin-top: 16px;
}

.paperLineB {
  border-bottom: 1px solid #ededed;
}

.bbContainerP3 {
  flex-grow: 0;
  padding: 24px;
  margin: 0;
}
.bbHeaderTxtP3 {
  font-size: 16px !important;
}
.p3OuterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.p3OuterContainer
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  margin-right: -16px;
}

.bbContainer .commanButton > button,
.bbP2HeaderContainer2 .commanButton > button,
.p3OuterContainer .commanButton > button {
  width: 94px !important;
}

/* HIDE RADIO */
.bbP2HeaderContainer2 [type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}
.bbP2HeaderContainer1 .commanButton > button {
  width: 180px !important;
}

/* IMAGE STYLES */
.bbP2HeaderContainer2 [type="radio"] + div {
  cursor: pointer;
}

/* CHECKED STYLES */
.bbP2HeaderContainer2 [type="radio"]:checked + div {
  /* outline: 2px solid #e31837; */
  background-image: url("../../../../Assets/Images/BloodBank/red-drop.svg");
  width: 60px;
  height: 75px;
  text-align: center;
  margin-right: 32px;
  margin-top: 8px;
}
.bbP2HeaderContainer2 [type="radio"]:checked + div .bloodgroup {
  color: white !important;
}

/*.bbP2HeaderContainer2
  input.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd.MuiOutlinedInput-inputAdornedEnd {
   padding-right: 130px !important; 
}*/

@media (max-width: 600px) {
  .bloodHeading {
    
    font-size: 32px;
    font-weight: 500;
    line-height: 1.5;
    color: black;
  }
  .bbP2HeaderContainer1 {
    display: block;
  }
  .bbP2HeaderContainer1 .commanButton > button {
    width: 70% !important;
    margin-top: 24px;
  }

  .p3OuterContainer {
    display: block;
  }

  .p3OuterContainer .commanButton {
    margin-bottom: 1em;
    display: flex;
    justify-content: end;
  }
  .p3OuterContainer label.MuiFormControlLabel-root {
    align-items: flex-start !important;
    margin-right: 0px !important;
  }
  .p3OuterContainer
    span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    padding-left: 10px !important;
    padding-top: 6px !important;
    margin-right: 0px;
  }
  .requestBloodTxt {
    margin-top: -10px;
  }
  .bbSpan1P1
    span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    margin-right: -16px;
  }
  .bbSpan1P2
    span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    margin-right: 16px;
    margin-top: 16px;
  }
  .bbHeaderTxt {
    height: auto;
  }
  .P1BB span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    margin-left: 0px;
  }
}

@media (max-width: 281px) {
  .bbP2HeaderContainer1 .commanButton > button {
    width: 100% !important;
  }
}

.card-media-1 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fafafa;
  height: 12em;
  overflow: hidden;
}

.checkBg {
  background-color: white;
  border-radius: 50%;
  margin: 1rem;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
}
