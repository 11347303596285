.swal2-container.swal2-center.swal2-backdrop-show {
  z-index: 9999 !important;
}

.adminpModal .MuiTableCell-alignRight,
.MuiTableCell-head {
  text-align: left !important;
  
  font-size: 12px !important;
  color: #242424 !important;
  max-width: 350px !important;
  min-width: auto !important;
}
.MuiInputLabel-outlined {
  
}
.policiesCreateModal > div > div > div > div > button {
  margin: 0 !important;
}
.policiesCssDiv
  .MuiPaper-root.MuiTableContainer-root.MuiPaper-elevation1.MuiPaper-rounded {
  margin-right: 24px;
  margin-left: 24px;
  width: auto;
}
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  
}
.policiesCssDiv .policiesCreateModal {
  padding: 0 16px !important;
}

.policiesCssDiv .policyCategoryManageCss {
  height: 84px !important;
  background-color: #f7f6f6 !important;
  margin: 16px 24px;
  align-items: center;
}

.policiesCreateModal
  .MuiGrid-root.MuiGrid-container.MuiGrid-align-items-xs-center.MuiGrid-justify-content-xs-space-between {
  padding-left: 8px;
  padding-right: 8px;
}

.policiesCssDiv input:disabled {
  border-color: #f7f6f6 !important;
  background-color: #f7f6f6 !important;
  color: #242424 !important;
  border-width: 0px !important;
}
.policiesCssDiv .categoryModalInput {
  margin-left: 16px;
}
/* .adminpModal {
  width: 1245px !important;
} */
/* .adminpModal .modalBody {
  min-height: 700px !important;
} */
/* .editAddPolicyModal .modalBody {
  min-height: 400px !important;
} */
td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignRight:last-child {
  padding-right: 0px !important;
}
.policiesCssDiv tr.MuiTableRow-root.MuiTableRow-head > th:last-child {
  text-align: left !important;
  padding-left: 110px !important;
}

.policiesCssDiv .MuiPaper-elevation1 {
  box-shadow: none !important;
}
.policiesCssDiv table.MuiTable-root {
  border: 1px solid #ededed;
}

tr.MuiTableRow-root.MuiTableRow-head {
  height: 56px !important;
}

.policiesCssDiv input.categoryModalInput {
  background-color: #f7f6f6 !important;
  
  width: 250px;
  padding: 4px;
  border: 2px solid #d3d3d3;
  border-radius: 0px;
  outline: none !important;
}

.policiesCssDiv input[type="text"].categoryModalInput:focus {
  border: 2px solid #d3d3d3;
  border-radius: 0px;
  outline: none !important;
}

.policyCategoryManageCss
  .css-cio0x1-MuiInputBase-root-MuiFilledInput-root:after {
  display: none !important;
}

.policyCategoryManageCss
  .css-cio0x1-MuiInputBase-root-MuiFilledInput-root.Mui-disabled:before {
  display: none !important;
}

.policyCategoryManageCss
  .css-cio0x1-MuiInputBase-root-MuiFilledInput-root:before {
  display: none !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: #d3d3d3 !important;
}

.policyCategoryManageCss
  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover
  .MuiOutlinedInput-notchedOutline {
  border-color: #d3d3d3 !important;
}

/*edit sub category */
.editSubCategory
  .MuiPaper-root.MuiTableContainer-root.MuiPaper-elevation1.MuiPaper-rounded {
  width: auto !important;
  margin-left: 24px;
  margin-right: 24px;
}

.editSubCategory .MuiTableCell-alignRight {
  text-align: left !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}

.editSubCategory
  .MuiOutlinedInput-root.Mui-focused.MuiOutlinedInput-notchedOutline {
  border-color: #d3d3d3 !important;
}

.editSubCategory .MuiOutlinedInput-notchedOutline {
  border-color: #d3d3d3 !important;
}

.editSubCategory .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(24px, 3px) scale(0.75) !important;
}

.editSubCategory .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.6) !important;
}

.editSubCategory .lastTcellSub {
  padding-left: 60px !important;
}

.policyCategoryManageCss
  .css-au3a9q-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.6) !important;
}

.editSubCategory .MuiCheckbox-root {
  color: #e31837 !important;
}

.AddSubC .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #d3d3d3 !important;
}

.AddSubC .MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(24px, 2px) scale(0.75) !important;
}

.AddSubC .MuiFormLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.6) !important;
}

.newCategoryBtn {
  margin-top: 5px !important;
  height: 49px !important;
  width: 80px !important;
  margin-left: 16px !important;
}

th.MuiTableCell-root.MuiTableCell-head {
  padding-left: 16px !important;
}

@media (max-width: 560px) {
.comment-header
{    display: flex;}
}

.editSubCategory th.MuiTableCell-root.MuiTableCell-head:last-child {
  padding-left: 81px !important;
}

.adminpModal .modalBody {
  background: #fff;
}
